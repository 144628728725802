import React from 'react';
import "../style/Overlay.css";

function ModalMessage({ message, onClose, onConfirm }) {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <p>{message}</p>
        <button onClick={onConfirm}>Confirmar</button>
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
}

export default ModalMessage;
