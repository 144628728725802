import React, {useState, useRef, useEffect} from 'react'
import '../style/Dropdow.css'

function Dropdown() {

    const [isOpen, setIsOpen] = useState(false);
    const dropDownRef = useRef(null);


    useEffect(()=> {
        const handleClickOutside = (event) =>{
            if(dropDownRef.current && !dropDownRef.current.contains(event.target)){
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, []);

    const toogleDropdown = () => setIsOpen(!isOpen)

    

  return (
    <>
        <div ref={dropDownRef} style={{ position: 'relative', zIndex: 2 }} className='whole-drop'>
            <button onClick={toogleDropdown} id='drop-menu'className={isOpen ? 'clicked' : ''}>
                <a className='seeall' href="/stock">VER TODOS</a>
                </button>
            
        </div>
    </>
  )
}

export default Dropdown