import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { DownloadOutlined } from '@ant-design/icons';
import axios from "axios";
import { useAuth } from "../context/AuthContext";

const AbonoNotification = ({ noti }) => {
  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const { authToken } = useAuth();

  const showDocuments = (docUrl) => {
    setSelectedDoc(docUrl);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedDoc(null);
  };

  const handleDownload = () => {
    if (selectedDoc) {
      const link = document.createElement('a');
      link.href = selectedDoc;
      link.download = selectedDoc.split('/').pop();
      link.click();
    }
  };
  
  const abonar = async () => {
    const data = {
      identifierVenta: noti.ventaID,
      abono: noti.abono,
      comprobantes: noti.payload,
      notiID: noti._id
    };
    try {
      await axios.post("http://localhost:3500/api/ventas/abono", data, { headers: { Authorization: "bearer " + authToken } });
      message.success('Bono aceptado', 3);
    } catch (error) {
      message.error('Error al aceptar el bono', 3);
    }
    const res = await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/abono",data, { headers: { Authorization: "bearer " + authToken } })
  }

  return (
    <div className="column-content-n">
      <p className="from">De: {noti.Mensajero.nombre}</p>
      <p className="to">Para: ADMINISTRACION</p>
      <p className="notification-content">{noti.body}</p>
      <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
        {noti.payload.map((doc, index) => (
          <Button
            key={index}
            onClick={() => showDocuments(doc.url)}
            style={{
              margin: '10px 5px',
              backgroundColor: 'transparent',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src="/icons/pdf.png" alt="PDF" style={{ maxWidth: '24px', height: '24px', marginRight: '5px' }} />
            <span style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {doc.name}
            </span>
          </Button>
        ))}
      </div>
      <div className="buttons-notification">
        <button className="button-n" onClick={abonar}>Aceptar bono</button>
      </div>
      <Modal
        title={
          <div>
            Documentos
            <Button onClick={handleDownload} style={{ float: 'right', marginRight: '3%', top: '0' }}>
              Descargar <DownloadOutlined />
            </Button>
          </div>
        }
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        {selectedDoc && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <div
              style={{
                height: '750px',
              }}
            >
              <Viewer fileUrl={selectedDoc} />
            </div>
          </Worker>
        )}
      </Modal>
    </div>
  );
};

export default AbonoNotification;
