import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import Navbar from "../Navbar";
import axios from "axios";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import UploadWidget from "../UploadWidget";
import "react-quill/dist/quill.snow.css";
import "../../style/Banner.css";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";

function EditContent() {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [username, setUsername] = useState("");
  const [Dia, setDia] = useState("");
  const [formatDia, setFormatDia] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [images, setImages] = useState([]);
  const [FechaLimiteBoolean, setFechaLimiteBoolean] = useState(false);
  const [storedImages, setStoredImages] = useState([]);
  const [showPreview, setShowPreview] = useState(false); // State for preview modal
  const [previewImage, setPreviewImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const { authToken, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getuser = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
      { headers: { Authorization: "bearer " + authToken } }
    );
    setUsername(response.data.nombre);
  };

  useEffect(() => {
    const inicio = async () => {
      if (!authToken) {
        navigate("/");
      } else {
        await getuser();
      }
    };
    inicio();
    fetchContent();
  }, [authToken, navigate]);

  const [mainImage, setMainImage] = useState(null);

  const handleUploadSuccess = (image) => {
    setImages([image]);
    setMainImage(image);
  };

  const handleDateChange = (event) => {
    const Fecha = new Date(event.target.value);
    const isoString = Fecha.toISOString();
    setFormatDia(isoString);
    setDia(event.target.value);
  };

  const handleTittleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setFechaLimiteBoolean(event.target.checked);
    if (!event.target.value) {
      setFormatDia(null);
    }
  };

  const deleteImage = async (imageId) => {
    try {
      await axios.delete(process.env.REACT_APP_BACKENDIP + `anuncios/delete/${imageId}`, {headers: { Authorization: `bearer ${authToken}` },});
      setStoredImages(storedImages.filter((image) => image._id !== imageId));
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const fetchContent = async (type) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "anuncios/Admin",
        { headers: { Authorization: `bearer ${authToken}` } }
      );
      setStoredImages(response.data);
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  const saveContent = async () => {
    try {
      const data = {
        title: title,
        Imagen: images[0],
        FechaLimite: formatDia,
      };
      const res = await axios.post(
        process.env.REACT_APP_BACKENDIP + "anuncios/crearAnuncio",
        data,
        { headers: { Authorization: `bearer ${authToken}` } }
      );
      console.log(res);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      fetchContent();

      setTitle("");
      setDia("");
      setFechaLimiteBoolean(false);
      setFormatDia("");
      setImages([]);
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const handlePreview = (image) => {
    setPreviewImage(image);
    setShowPreview(true);
  };

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-a">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/banner-white.png"
                  alt=""
                  className="element-img"
                />
                <a href="/banner" className="element-text">
                  Banner
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/campana.png"
                  alt=""
                  className="element-img"
                />
                <a href="/notificationsadmin" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-c">
            <div
              ref={dropDownRef}
              className="whole-drop-a"
            >
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img-d" />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/sum.png" alt="" className="element-img-d" />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/notification.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/notificationsadmin" className="element-text-d">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Agregar Baner</p>
            <div className="baner-container">
              <div className="baner-info">
                <input
                  type="text"
                  className="title-input-b"
                  placeholder="Nombre del baner"
                  id="tittle"
                  value={title}
                  onChange={handleTittleChange}
                />
                <div className="widget-ban">
                  <div className="widget-ba">
                    <UploadWidget onUploadSuccess={handleUploadSuccess} />
                  </div>
                  {images.length > 0 && (
                    <div className="preview-container">
                      {images.map((url, index) => (
                        <div key={index} className="preview-image">
                          <img
                            src={url}
                            className="img-uploaded"
                            alt={`Uploaded ${index}`}
                          />
                          <button
                            className="preview-btn"
                            onClick={() => handlePreview(url)}
                          >
                            Vista Preliminar
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <p>Tamaño recomendado: 1600 px x 800 px</p>
              <div className="baner-esp">
                <label className="baner-lbl">
                  Fecha Limite:
                  <input
                    type="date"
                    name="Dia"
                    id="Dia"
                    value={Dia}
                    onChange={handleDateChange}
                  />
                </label>
                <label className="baner-lbl">
                  No hay fecha limite
                  <input
                    type="checkbox"
                    checked={FechaLimiteBoolean}
                    onChange={handleCheckboxChange}
                  />
                </label>
              </div>
              <button className="save-btn-b" onClick={saveContent}>
                GUARDAR BANER
              </button>
              {showSuccessMessage && (
                <div className="success-message">
                  Baner añadido exitosamente
                </div>
              )}
              <h3>Banners Guardados</h3>
              <div className="stored-images-container">
                {storedImages.map((image) => (
                  <div key={image._id} className="stored-image">
                    <img
                      src={image.Imagen}
                      alt="stored banner"
                      className="stored-image-img"
                    />
                    <p className="image-ttl">{image.title}</p>
                    <div className="date-delete">
                      <p>
                        {image.FechaLimite
                          ? `Caduca el: ${new Date(
                              image.FechaLimite
                            ).toLocaleDateString()}`
                          : "Sin fecha de caducidad"}
                      </p>
                      <button
                        className="del-btn"
                        onClick={() => deleteImage(image._id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPreview && (
        <div className="preview-modal">
          <div className="preview-content">
            <button
              className="close-preview"
              onClick={() => setShowPreview(false)}
            >
              Cerrar
            </button>
            <div className="ads-logos">
              <div className="container">
                <div className="ads-container-1">
                  <div className="ad-1">
                    <img src={previewImage} alt="Preview" className="img-preview" />
                  </div>
                  <div className="ad-2">
                    <img src={previewImage} alt="Preview" className="img-preview" />
                  </div>
                </div>
                <div className="logos-container">
                  <div className="logo">
                    <div className="logo-row">
                      <a href="/stock?marca=Toyota">
                        <img
                          src="images/toyota.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Nissan">
                        <img
                          src="images/nissan.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Jeep">
                        <img
                          src="images/jeep.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Mitsubishi">
                        <img
                          src="images/mitsubishi.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                    </div>
                    <div className="logo-row">
                      <a href="/stock?marca=Dodge">
                        <img
                          src="images/dodge.png"
                          alt=""
                          className="large-logo"
                        />
                      </a>
                      <a href="/stock?marca=Volkswagen">
                        <img
                          src="images/volkswagen.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Bmw">
                        <img
                          src="images/bmw.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                    </div>
                    <div className="logo-row">
                      <a href="/stock?marca=Mazda">
                        <img
                          src="images/mazda.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Mercedes">
                        <img
                          src="images/mercedes.png"
                          alt=""
                          className="large-logo"
                        />
                      </a>
                      <a href="/stock?marca=Kia">
                        <img
                          src="images/kia.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                    </div>
                    <div className="logo-row">
                      <a href="/stock?marca=Acura">
                        <img
                          src="images/acura.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Mercury">
                        <img
                          src="images/mercury.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Hyundai">
                        <img
                          src="images/hyundai.png"
                          alt=""
                          className="large-logo"
                        />
                      </a>
                    </div>
                    <div className="logo-row">
                      <a href="/stock?marca=Gms">
                        <img
                          src="images/gmc.png"
                          alt=""
                          className="large-logo"
                          id="gmc"
                        />
                      </a>
                      <a href="/stock?marca=Audi" id="audi-a">
                        <img
                          src="images/audi.png"
                          alt=""
                          className="large-logo"
                          id="audi"
                        />
                      </a>
                    </div>
                    <div className="logo-row">
                      <a href="/stock?marca=Lexus">
                        <img
                          src="images/lexus.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Ford">
                        <img
                          src="images/ford.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Chevrolet">
                        <img
                          src="images/chevrolet.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                      <a href="/stock?marca=Honda">
                        <img
                          src="images/honda.png"
                          alt=""
                          className="square-logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="ads-container">
                  <div className="ad-1"><img src={previewImage} alt="Preview" className="img-preview" /></div>
                  <div className="ad-2"><img src={previewImage} alt="Preview" className="img-preview" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="footer">
      <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default EditContent;
