import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1vh;
`;

const Indicators = styled.div`
  position: relative;
  bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;


const Indicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#007bff" : "#ddd")};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007bff;
  }
`;

const CarouselImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 1vh;
`;

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (images.length === 0) return;

    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 15000); // 15 seconds interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [images]);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  if (images.length === 0) {
    return <p>No images to display.</p>;
  }

  return (
    <CarouselContainer>
      <CarouselImage src={images[currentIndex]} alt={`Carousel image ${currentIndex}`} />
      <Indicators>
        {images.map((_, index) => (
          <Indicator
            key={index}
            active={index === currentIndex}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </Indicators>
    </CarouselContainer>
  );
};

export default ImageCarousel;
