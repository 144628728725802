// App.js
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Contact from './components/pages/Contact';
import Stock from './components/pages/Stock';
import Credito from './components/pages/Credito';
import InfoCar from './components/pages/InfoCar';
import Politics from './components/pages/Politics';
import Sell from './components/pages/Sell';
import AllCars from './components/pages/AllCars';
import Notifications from './components/pages/Notifications';
import MyCars from './components/pages/MyCars';
import AllUsers from './components/pages/AllUsers';
import Boletin from './components/pages/Boletin';
import AllCarsAdmin from './components/pages/AllCarsAdmin';
import EditContent from './components/pages/EditContent';
import AddCar from './components/pages/AddCar';
import EditCar from './components/pages/EditCar';
import AboutUs from './components/pages/AboutUs';
import Avisos from './components/pages/Avisos';
import Banner from './components/pages/Banners';
import NotificationsAdmin from './components/pages/NotificationsAdmin';
import LoadingScreen from './components/LoadingScreen';

import { StockProvider } from './context/StockContext';

import './App.css';

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    // Simulando una carga para demostración
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location]);

  const callBackStock = (toggleFilter) => {
    console.log(toggleFilter);
  } 

  return (
    <>
      {loading && <LoadingScreen />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/stock' element={<Stock />} />
        <Route path='/credit' element={<Credito />} />
        <Route path='/info/:id' element={<InfoCar />} />
        <Route path='/politicas' element={<Politics />} />
        <Route path='/sell' element={<Sell />} />
        <Route path="/allcars" element={<AllCars />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/mycars" element={<MyCars />} />
        <Route path="/allusers" element={<AllUsers />} />
        <Route path="/boletin" element={<Boletin />} />
        <Route path="/allcarsa" element={<AllCarsAdmin />} />
        <Route path="/editcontent" element={<EditContent />} />
        <Route path="/addcar" element={<AddCar />} />
        <Route path="/editcar" element={<EditCar />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/avisos" element={<Avisos />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/notificationsadmin" element={<NotificationsAdmin />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <StockProvider>
      <Router>
        <AppContent />
      </Router>
    </StockProvider>
  );
}

export default App;
