import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import AbonoUpload from "./AbonoUpload";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background: #0056b3;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const DeleteButton = styled.button`
  background: #ff0000;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background: #cc0000;
  }
`;

const Modal = ({ show, onClose, onConfirm, ventaId, carro }) => {
  const [nombres, setnombres] = useState([]);
  const [input, setInput] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fulluser, setfulluser] = useState([]);
  const [userId, setUserid] = useState("");
  const [images, setImages] = useState([]);
  const { authToken } = useAuth();

  const backgroundurl = carro.imagenes[0];

  const findallusernames = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "usuario/getbyToken", {headers: {'Authorization': 'bearer ' + authToken}});
    const data = res.data;
    setfulluser(data);
    setUserid(data._id)
    setnombres(data.nombre);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
  };

  const handleUploadSuccess = (file) => {
    setImages((prevImages) => [...prevImages, file]);
  };

  const handleDeleteImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const comprar = async () => {
    const data = {
      comprobantes: images,
      monto: input,
    };
    const res = await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/solicitudAbono/" + ventaId ,data,{ headers: { Authorization: "bearer " + authToken } });
    onConfirm();
  };

  useEffect(() => {
    if (show) {
      findallusernames();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>X</CloseButton>
        <div className="car-models-mod">
          <div className="head">
            <p className="car-title-mod">
              {carro.marca} {carro.modelo} {carro.anio}
            </p>
          </div>
        </div>
        <h2>Añadir solicitud de abono</h2>
        <div
          className={`search-bar-mod ${
            suggestions.length > 0 ? "show-suggestions" : ""
          }`}
        >
          <input
            type="text"
            id="Search"
            value={input}
            onChange={handleChange}
            placeholder="Monto del abono"
          />
        </div>
        <div className="widget-pdf">
          <AbonoUpload onUploadSuccess={handleUploadSuccess} />
        </div>
        {images.map((file, index) => (
          <div key={index} className="pdf-container-cloudinary">
            <a
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-show"
            >
              <img src="icons/pdf.png" alt="" className="pdf-img" />
              {file.name}
            </a>
            <DeleteButton onClick={() => handleDeleteImage(index)}>
              Eliminar
            </DeleteButton>
          </div>
        ))}
        <ConfirmButton onClick={comprar} disabled={!input || images.length === 0}>
          Confirmar
        </ConfirmButton>
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;