import React from "react";
import "../style/CarOverview.css";
import { useNavigate } from 'react-router-dom';

function CarOverview(carro) {
  let backgroundurl = carro.carro.imagenes[0];
  backgroundurl = backgroundurl.replace("http://", "https://")
  const navigate = useNavigate();

  const clickoverview = () => {
    navigate("/info/" + carro.carro._id)
  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  return (
    <>
      <div onClick={clickoverview} style={{ "--background-image": `url(${backgroundurl})` }}
        className="car-updated">
          <div className="new-car-container">
            <img src="images/new.png" alt="" className="new-car" />
          </div>
            <div className="info-part-h">
              <h1 className="price-home">L.{formatNumberWithCommas(carro.carro.precio)}</h1>
              <h2 className="car-model-h">
                {carro.carro.marca} {carro.carro.modelo} {carro.carro.anio}
              </h2>
              {carro.carro.lote !== '0' && (<p className="lote" id="car-lote-h">Lote: {carro.carro.lote}</p>)}
          </div>
      </div>
    </>
  );
}

export default CarOverview;
