import React, {useEffect} from 'react'
import "../style/CarOverviewNotSpecial.css"
import { useNavigate } from 'react-router-dom';


function CarOverview(carro) {
    let backgroundurl = carro.carro.imagenes[0];
    backgroundurl = backgroundurl.replace("http://", "https://")
    const navigate = useNavigate();


    const clickoverview = () => {
        navigate("/info/" + carro.carro._id)
    }

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

   
    
  return (
    <>
        <div onClick={clickoverview} style={{ '--background-image': `url(${backgroundurl})` }} className="car-updated-1-stock">
            <div className="info-car-1-stock">
                <div className="info-part-stock">
                    {carro.carro.lote !== '0' && (<p className="lote" id="car-lote-h">Lote: {carro.carro.lote}</p>)}
                    <p className="price-stock">L.{formatNumberWithCommas(carro.carro.precio)}</p>
                    <p className="car-model-stock">{carro.carro.marca} {carro.carro.modelo} {carro.carro.anio}</p>
                    
                </div>
            </div>
        </div>
    </>
  )
}

export default CarOverview