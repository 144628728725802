// LoadingScreen.js
import React from 'react';
import { Spin } from 'antd';
import '../style/LoadingScreen.css'; // Asegúrate de tener los estilos necesarios

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <Spin size="large" />
    </div>
  );
};

export default LoadingScreen;
