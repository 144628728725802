import React, { useState, useEffect, useRef } from "react";
import Notification from "../Notification.jsx";
import Navbar from "../Navbar.jsx";
import Foooter from "../Foooter.jsx";
import FooterMovil from "../FooterMovil.jsx";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import "../../style/Notifications.css";
import axios from 'axios';
import { Spin, Modal } from 'antd';
import CompraNotification from '../CompraNotifications.jsx';
import AbonoNotification from '../AbonoNotifications.jsx';
import CreditoNotification from '../CreditoNotifications.jsx';
import CreditoNotificationOverview from '../CreditoNotificationOverview.jsx';

function Notifications() {
  const { authToken, logout } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);
  const [notis, setNotis] = useState([]);
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [selectednoti, setSelectedNoti] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [notificationType, setNotificationType] = useState('Compra'); // Default type

  const navigate = useNavigate();

  const getuser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      setUsername(response.data.nombre);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
    }
  };

  const fetchNotifications = async (type) => {
    setLoading(true);
    setNotificationType(type);

    let url = '';
    switch (type) {
      case 'Compra':
        url = process.env.REACT_APP_BACKENDIP + "notificaciones/compras";
        break;
      case 'Abono':
        url = process.env.REACT_APP_BACKENDIP + "notificaciones/abonos";
        break;
      case 'Credito':
        url = process.env.REACT_APP_BACKENDIP + "solicitudes/Admin/credito";
        break;
      default:
        url = process.env.REACT_APP_BACKENDIP + "notificaciones/compras";
    }

    try {
      const res = await axios.get(url, { headers: { Authorization: "bearer " + authToken } });
      const notidata = res.data;
      setNotis(notidata);
      if (notidata.length !== 0) {
        setNewSelectedNoti(notidata[0]);
      } else {
        setSelectedNoti(null);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const deleteNotification = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_BACKENDIP + "notificaciones/borrar/" + id, {
        headers: { Authorization: "bearer " + authToken }
      });
      setNotis(notis.filter(noti => noti._id !== id));
      if (selectednoti && selectednoti._id === id && notis.length > 0) {
        setSelectedNoti(notis[0]);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  useEffect(() => {
    const fetchAllCarros = async () => {
      setLoading(true);
      if (authToken !== null) {
        await getuser();
        await fetchNotifications('Compra');
      } else {
        navigate("/");
      }
      setLoading(false);
    };
    fetchAllCarros();
  }, [authToken, navigate]);

  const setNewSelectedNoti = (noti) => {
    setSelectedNoti(noti);
    setDocuments(noti.payload || noti.documentos || []);
  };

  const toogleDropdown = () => setIsOpen(!isOpen);

  const showDocuments = () => {
    Modal.info({
      title: 'Documentos',
      content: (
        <div>
          {documents.map((doc, index) => (
            <a key={index} href={doc.url || doc} target="_blank" rel="noopener noreferrer" style={{ display: 'block', marginBottom: '10px' }}>
              {doc.name || `Documento ${index + 1}`}
            </a>
          ))}
        </div>
      ),
      onOk() {},
    });
  };

  const renderNotificationContent = () => {
    if (!selectednoti) {
      return <div className="column-content">Seleccione una notificación para ver los detalles</div>;
    }

    const tipoNotificacion = selectednoti.Tipo || selectednoti.tipo;

    switch (tipoNotificacion) {
      case 'Compra':
        return <CompraNotification noti={selectednoti} />;
      case 'Abono':
        return <AbonoNotification noti={selectednoti} showDocuments={showDocuments} />;
      case 'Credito':
        return <CreditoNotification noti={selectednoti} showDocuments={showDocuments} />;
      default:
        return <div className="column-content">Tipo de notificación no soportado</div>;
    }
  };

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-a">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">Autos</a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">Agregar autos</a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">Compradores</a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">Boletines</a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">Contenido</a>
              </div>
              <div className="element">
                <img src="icons/banner-white.png" alt="" className="element-img" />
                <a href="/banner" className="element-text">Banner</a>
              </div>
              <div className="element">
                <img src="icons/campana.png" alt="" className="element-img" />
                <a href="/notificationsadmin" className="element-text">Notificaciones</a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-all">
            <div ref={dropDownRef} className="whole-drop-a">
              <button onClick={toogleDropdown} id="drop-movil" className={isOpen ? "click" : ""}>
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img src="/icons/arrow-up-dark.png" id="arrows" alt="arrow-up" />
                ) : (
                  <img src="/icons/arrow-down-dark.png" id="arrows" alt="arrow-down" />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img-d" />
                      <a href="/allcarsa" className="element-text-d">Autos</a>
                    </div>
                    <div className="element-d">
                      <img src="icons/sum.png" alt="" className="element-img-d" />
                      <a href="/addcar" className="element-text-d">Agregar autos</a>
                    </div>
                    <div className="element-d">
                      <img src="icons/user.png" alt="" className="element-img-d" />
                      <a href="/allusers" className="element-text-d">Compradores</a>
                    </div>
                    <div className="element-d">
                      <img src="icons/email.png" alt="" className="element-img-d" />
                      <a href="/boletin" className="element-text-d">Boletines</a>
                    </div>
                    <div className="element-d">
                      <img src="icons/content.png" alt="" className="element-img-d" />
                      <a href="/editcontent" className="element-text-d">Contenido</a>
                    </div>
                    <div className="element-d">
                      <img src="icons/banner-white.png" alt="" className="element-img-d" />
                      <a href="/banner" className="element-text-d">Banner</a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img src="icons/salir.png" alt="" className="element-img-d" />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Notificaciones</p>
            <div className="buttons-notifications">
              <button className={`button-notifications ${notificationType === "Compra" ? "active" : ""}`} onClick={() => fetchNotifications('Compra')}>Solicitud de compra</button>
              <button className={`button-notifications ${notificationType === "Abono" ? "active" : ""}`} onClick={() => fetchNotifications('Abono')}>Solicitud de abono</button>
              <button className={`button-notifications ${notificationType === "Credito" ? "active" : ""}`} onClick={() => fetchNotifications('Credito')}>Solicitud de crédito</button>
            </div>
            {loading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : notis.length === 0 ? (
              <div className="no-notifications">No existen notificaciones de {notificationType.toLowerCase()}</div>
            ) : (
              <div className="notifications-grid">
                <div className="column-overview">
                  <div className="overview-container" style={{ overflow: "auto", maxHeight: "450px", alignItems: "flex-start" }}>
                    {notis.map((noti) => (
                      <div className={`overview-item-n ${selectednoti && selectednoti._id === noti._id ? 'selected' : ''}`} key={noti._id} onClick={() => setNewSelectedNoti(noti)}>
                        {noti.tipo === "Credito" ? (
                          <CreditoNotificationOverview noti={noti} onDelete={deleteNotification} isSelected={selectednoti && selectednoti._id === noti._id} onSelect={() => setNewSelectedNoti(noti)} />
                        ) : (
                          <Notification noti={noti} onDelete={deleteNotification} isSelected={selectednoti && selectednoti._id === noti._id} onSelect={() => setNewSelectedNoti(noti)} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {renderNotificationContent()}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default Notifications;
