import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../style/Register.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ModalMessage from "../Overlay"; // Asegúrate de que la ruta sea correcta
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import styled from 'styled-components';

const PhoneInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledPhoneInput = styled(PhoneInput)`
  color: white;
  padding: 0;
  margin: 2% 0%;
  padding: 0;
  border-radius: 1vh;
  border-color: transparent;
`;

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    correo: "",
    password: "",
    Confirmpassword: "",
    nombre: "",
    city: "",
    telefono: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      telefono: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password === formData.Confirmpassword) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BACKENDIP + "usuario",
          formData
        );
        setMessage("Registro exitoso.");
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/");
        }, 3000);
      } catch (error) {
        console.error("Error en el registro:", error);
        setMessage(
          "Error en el registro. La contraseña debe de tener 8 caracteres alfanuméricos."
        );
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      }
    } else {
      setMessage("Las contraseñas no coinciden.");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const navhome = () => {
    navigate("/");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="honduautos-back">
        <form onSubmit={handleSubmit} className="login-info">
          <a href="/" className="login-l">
            <img
              src="images/honduautos-logo.png"
              alt="logo de honduautos"
              onClick={navhome}
              className="logo-login"
            />
          </a>
          <input
            type="text"
            name="correo"
            className="email-input-reg"
            placeholder="Correo Electrónico"
            value={formData.correo}
            onChange={handleInputChange}
          />
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className="password-input-reg"
              placeholder="Contraseña"
              value={formData.password}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="toggle-password-button"
              onClick={toggleShowPassword}
            >
              {showPassword ? (
                <FaEyeSlash className="eye-icon" />
              ) : (
                <FaEye className="eye-icon" />
              )}
            </button>
          </div>
          <div className="password-input-container">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="Confirmpassword"
              className="password-input-reg"
              placeholder="Confirme su Contraseña"
              value={formData.Confirmpassword}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="toggle-password-button"
              onClick={toggleShowConfirmPassword}
            >
              {showConfirmPassword ? (
                <FaEyeSlash className="eye-icon" />
              ) : (
                <FaEye className="eye-icon" />
              )}
            </button>
          </div>
            <PhoneInputContainer>
              <StyledPhoneInput
                defaultCountry="hn"
                value={formData.telefono}
                onChange={handlePhoneChange}
                className='phone-input'
              />
            </PhoneInputContainer>
          <input
            type="text"
            name="nombre"
            className="name-input"
            placeholder="Nombre"
            value={formData.nombre}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="city"
            className="city-input"
            placeholder="Ciudad"
            value={formData.city}
            onChange={handleInputChange}
          />
          <input
            type="submit"
            name="login"
            id="register-buton"
            value="Registrar"
          />
          <p className="member">¿Ya tienes cuenta?</p>
          <Link to="/login" className="sesion-link">
            Inicia sesión
          </Link>
        </form>
      </div>
      {showModal && <ModalMessage message={message} onClose={closeModal} />}
    </>
  );
}

export default Register;
