import React, { useState, useEffect, useRef } from "react";
import Compradores from "../Compradores";
import CarModal from "../UserCar";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import "../../style/AllUsers.css";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";


function AllUsers() {
  const [users, setUsers] = useState([]);
  const [usersTemp, setUsersTemp] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  
  const [nombres, setnombres] = useState([]);
  const [input, setInput] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fulluser, setfulluser] = useState([]);
  const [userId, setUserid] = useState("");
  const [registrado, setregistrado] = useState(true);

  const findallusernames = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "usuario");
    const data = res.data;
    const usernames = [];
    data.forEach((usuario) => {
      if (usuario.nombre) {
        usernames.push(usuario.nombre);
      }
    });
    setfulluser(data);
    setnombres(usernames);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
    detectInputType(value);
  };

  const fuse = new Fuse(
    nombres.map((nombre) => ({ nombre: nombre })),
    {
      threshold: 0.9,
      distance: 100,
      minMatchCharLength: 3,
      keys: ["nombre"],
    }
  );

  const detectInputType = (value) => {
    setSelectedName("");
    const valueTrim = value.trim();
    let matches = [];
    const suggestions = fuse.search(valueTrim);
    setSuggestions(suggestions.map((suggestion) => suggestion.item.nombre));
    if (
      nombres.some((nombre) => nombre.toLowerCase() === valueTrim.toLowerCase())
    ) {
      matches.push({ type: "nombre", value: valueTrim });
    }
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
  };

  const finduser = (username) => {
    const matchedUser = fulluser.find(
      (user) => user.nombre.toLowerCase() === username.toLowerCase()
    );
    setUserid(matchedUser._id);
    const foundObject = users.find(item => item._id === matchedUser._id);
    setUsers([foundObject])
  };

  const limpiar = () => {
    setUsers(usersTemp)
  }

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setSuggestions([]);
    finduser(suggestion);
  };

  const { authToken, logout } = useAuth();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKENDIP + "usuario"
        );
        setUsers(response.data);
        setUsersTemp(response.data)
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setUsers([]);
      }
      await setLoading(false);
    };

    const getUser = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
          { headers: { Authorization: "bearer " + authToken } }
        );
        setUsername(response.data.nombre);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
      }
    };

    const inicio = async () => {
      if (authToken !== null) {
        await getUser();
        await fetchAllUsers();
        await findallusernames();
      } else {
        navigate("/");
      }
    };

    inicio();
  }, [authToken, navigate]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
  };

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-a">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/banner-white.png"
                  alt=""
                  className="element-img"
                />
                <a href="/banner" className="element-text">
                  Banner
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/campana.png"
                  alt=""
                  className="element-img"
                />
                <a href="/notificationsadmin" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-all">
            <div ref={dropDownRef} className="whole-drop-a">
              <button
                onClick={toggleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img
                        src="icons/car.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/sum.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/notification.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/notificationsadmin" className="element-text-d">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-admin-title-all">Listado de Compradores</p>
            <div className="searchbar-container">
            <div
              className={`search-bar-mod-allusers ${
                suggestions.length > 0 ? "show-suggestions" : ""
              }`}
            >
              <input
                type="text"
                id="Search"
                value={input}
                onChange={handleChange}
                placeholder=" Nombre del comprador"
              />
              {suggestions.length > 0 && (
                <ul className="suggestions-list-mod">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="suggestion-item"
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button class="red-button" onClick={() => limpiar()}>Limpiar Filtro</button>
            </div>
            <div className="column-titles">
              <p className="column-title">Nombre</p>
              <p className="column-title">Correo</p>
              <p className="column-title">Carros comprados</p>
              <p className="column-title">Saldo</p>
            </div>
            <div
              className="overview-container"
              style={{
                overflow: "auto",
                maxHeight: "700px",
                alignItems: "flex-start",
              }}
            >
              {users.map((user) => (
                <div className="overview-item" key={user._id} onClick={() => handleUserClick(user)}>
                  <Compradores user={user} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedUser && (
        <CarModal  userId={selectedUser._id} showModal={!!selectedUser} onClose={handleCloseModal} />
      )}
      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default AllUsers;
