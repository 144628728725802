import React from "react";
import "../style/CarOverview.css";
import { useNavigate } from 'react-router-dom';

function CarOverview(carro) {
  let backgroundurl = carro.carro.imagenes[0];
  backgroundurl = backgroundurl.replace("http://", "https://");  
  const navigate = useNavigate();

  const clickoverview = () => {
    navigate("/info/" + carro.carro._id)
  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <div onClick={clickoverview} style={{ "--background-image": `url(${backgroundurl})` }}
        className="car-updated">
          <div className="new-car-container">
            <img src="images/destacado.png" alt="" className="new-car" />
          </div>
            <div className="info-part-h">
            {carro.carro.lote !== '0' && (<p className="lote" id="car-lote-h">Lote: {carro.carro.lote}</p>)}
              <p className="price-home-h-h">L.{formatNumberWithCommas(carro.carro.precio)}</p>
              <p className="car-model-h">
                {carro.carro.marca} {carro.carro.modelo} {carro.carro.anio}
              </p>
          </div>
      </div>
    </>
  );
}

export default CarOverview;
