import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import MyCar from "../MyCar";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import "../../style/AllCarsAdmin.css";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";

function AllCarsAdmin() {
  const [carros, setCarros] = useState([]);
  const [ventas, setventas] = useState([]);
  const [invVendidos ,setinvVendido] = useState(0)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { authToken, logout } = useAuth();
  const [selectedBrand, setSelectedBrand] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [fullmarca, setfullmarca] = useState([]);
  const [suggestingmodels, setSuggestingModels] = useState(false);
  const [suggestions, setsugestions] = useState([]);
  const [input, setInput] = useState("");
  const [searchType, setSearchType] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);
  const [username, setUsername] = useState('');
  const [invAct, setinvAct] = useState(0);
  const [invTot, setinvTot] = useState(0);
  const [usrLenght, setUsrLenght] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [fullmodelo, setfullmodelo] = useState([]);
  const [allmodelos, setAllModelos] = useState([]);



  const navigate = useNavigate();

  const getuser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      setUsername(response.data.nombre);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
    }
  };

  const findoffera = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "findspecial/findoferta");
    setCarros(res.data);
  };

  const findnormal = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKENDIP + "carro"
    );
    setCarros(response.data.reverse());
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    if (checked) {
      findoffera();
    } else {
      findnormal();
    }
  };

  const findvendidos = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "ventas/vendidos");
    setCarros(res.data);
  }

  const handleCheckboxChange2 = (event) => {
    const checked = event.target.checked;
    setIsChecked2(checked);

    if (checked) {
      const filteredArray = carros.filter(carro => carro.nuevoIngreso === true);
      setCarros(filteredArray);
    } else {
      findnormal();
    }
  };

  const handleCheckboxChange3 = (event) => {
    const checked = event.target.checked;
    setIsChecked3(checked);

    if (checked) {
      findvendidos();
    } else {
      findnormal();
    }
  };

  //todo esto es del searchbar
  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return the original string if it's empty or undefined
    if(str === "bmw"){
      return "BMW"
    }
    if(str === "gmc"){
      return "GMC"
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getMarcas = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    const carros = res.data;
    const nombres = carros.map((carro) => carro.Nombre);
    setMarcas(nombres);
    setfullmarca(res.data);
  };

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    const modelos = res.data;
    const nombres = modelos.map((modelos) => modelos.Nombre);
    const nombresConMarca = modelos.map(
      (modelo) => `${selectedBrand} ${modelo.Nombre}`
    );
    setModelos(nombres);
    setsugestions(nombresConMarca);
    setSuggestingModels(true);
  };

  const fuse = new Fuse(marcas, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["marca"],
  });

  const fusemodelos = new Fuse(allmodelos, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["modelo"],
  });

  const anioPattern = /^\d{4}$/;

  const findObjectByKeyValue = (array, key, value) => {
    return array.find((obj) => obj[key] === value);
  };

  const lotNumberPattern = /^\d{8}$/

  const detectInputType = (value) => {
    setSelectedBrand("");
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    const suggestions = fuse.search(parts[0]);
    const suggestionsmodelos = fusemodelos.search(parts[0])
    let mergedArray = suggestionsmodelos.concat(suggestions);
    setsugestions(mergedArray.map((suggestion) => suggestion.item));
    parts.forEach((part) => {
      if(part === "mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if(part === "santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (allmodelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
      if (lotNumberPattern.test(part)) {
        matches.push({ type: "lote", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    if (matches[0].type === "marca") {
      const algo = findObjectByKeyValue(fullmarca, "Nombre", matches[0].value);
      setSelectedBrand(matches[0].value);
      getmodelos(algo._id);
    }
    setSearchType([...new Set(matches)]);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
    detectInputType(value);
  };

  const fetchCarrosType = async () => {
    const combinedJson = searchType.reduce((accumulator, current) => {
      accumulator[current.type] = current.value;
      return accumulator;
    }, {});
    setLoading(true);
    setCarros([]);
    try {
      const filteredResTIPO = await axios.post(
        process.env.REACT_APP_BACKENDIP + "filter/Admin",
        combinedJson
      );
      setCarros(filteredResTIPO.data.reverse());
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]);
    }
    setLoading(false);
  };

  const navigateToResults = () => {
    fetchCarrosType();
  };

  const handleSearch = () => {
    navigateToResults();
  };

  const detectInputTypeSugestion = (value) => {
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    parts.forEach((part) => {
      if(part === "mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if(part === "santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (allmodelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
      if (lotNumberPattern.test(part)) {
        matches.push({ type: "lote", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    setSearchType([...new Set(matches)]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setsugestions([]);
    detectInputTypeSugestion(suggestion);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getModelosAll = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelo");
    const modelos = res.data;
    const nombres = modelos.map((modelo) => modelo.Nombre);
    setAllModelos(nombres)
    setfullmodelo(modelos);
  };

  useEffect(() => {
    const inicio = async () => {
      if (!authToken) {
        navigate("/");
      }
      getuser();
    };
    const fetchAllCarros = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKENDIP + "carro"
        );
        const responseUser = await axios.get(
          process.env.REACT_APP_BACKENDIP + "usuario"
        );
        const resInvTot = await axios.get(process.env.REACT_APP_BACKENDIP + "ventas/admin", {headers: {Authorization: `bearer ${authToken}`,},})
        const ventas = await axios.get(process.env.REACT_APP_BACKENDIP + "ventas/", {headers: {Authorization: `bearer ${authToken}`,},})
        setUsrLenght((responseUser.data).length);
        setCarros(response.data.reverse());
        setventas(ventas.data.reverse())
        const cartemp = response.data;
        const anunciosNoVendidos = cartemp.filter(carro => carro.vendido === false);
        setinvAct(anunciosNoVendidos.length);
        setinvVendido(ventas.data.length);
        setinvTot((resInvTot.data).length);
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setCarros([]); // Consider whether you want to reset carros on error
      }
      setLoading(false);
    };
    inicio();
    fetchAllCarros();
    getMarcas();
    getModelosAll();
  }, []);

  const handleCarDeleted = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "carro"
      );
      setCarros(response.data.reverse());
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCarAdded = () => {
    setSuccessMessage("Carro agregado exitosamente");
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000); // El mensaje desaparece después de 3 segundos
    handleCarDeleted(); // Actualizar la lista de carros
  };

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-a">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/banner-white.png"
                  alt=""
                  className="element-img"
                />
                <a href="/banner" className="element-text">
                  Banner
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/campana.png"
                  alt=""
                  className="element-img"
                />
                <a href="/notificationsadmin" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-all">
            <div
              ref={dropDownRef}
              className="whole-drop-a"
            >
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img-d" />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/sum.png" alt="" className="element-img-d" />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/notification.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/notificationsadmin" className="element-text-d">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-admin-title-a">Listado de Autos</p>
            <div className="status">
              <div className="user-status">
                <p className="status-title">Inventario Activo</p>
                <p className="status-property">{invAct}</p>
              </div>
              <div className="user-status">
                <p className="status-title">Inventario Vendido</p>
                <p className="status-property">{invVendidos}</p>
              </div>
              <div className="user-status">
                <p className="status-title">Inventario Total</p>
                <p className="status-property">{invTot}</p>
              </div>
              <div className="user-status">
                <p className="status-title">Usuarios Totales</p>
                <p className="status-property">{usrLenght}</p>
              </div>
            </div>
            <div className="search-bar-a">
              <div
                className={`search-bar-ac ${
                  suggestions.length > 0 ? "show-suggestions" : ""
                }`}
              >
                <input
                  type="text"
                  id="Search"
                  value={input}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown} // Detectar Enter
                  placeholder=" Marca, modelo, lote, etc."
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list-mod">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="filter-container">
                <label htmlFor="filtro1" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro1"
                    className="filter-checkbox"
                    checked={isChecked2}
                    onChange={handleCheckboxChange2}
                  />
                  Nuevos
                </label>
                <label htmlFor="filtro2" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro2"
                    className="filter-checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  En Oferta
                </label>
                <label htmlFor="filtro3" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro3"
                    checked={isChecked3}
                    onChange={handleCheckboxChange3}
                    className="filter-checkbox"
                  />
                  Vendido
                </label>
              </div>
              <button type="submit" id="search" onClick={handleSearch}>
                BUSCAR
              </button>
            </div>
            <div className="search-bar-a-movil">
              <div
                className={`search-bar-ac ${
                  suggestions.length > 0 ? "show-suggestions" : ""
                }`}
              >
                <input
                  type="text"
                  id="Search-m"
                  value={input}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown} // Detectar Enter
                  placeholder=" Marca, modelo, lote, etc."
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list-m">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item-m"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="filter-container">
                <label htmlFor="filtro1" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro1"
                    className="filter-checkbox"
                  />
                  Nuevos
                </label>
                <label htmlFor="filtro2" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro2"
                    className="filter-checkbox"
                  />
                  En Oferta
                </label>
                <label htmlFor="filtro3" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro3"
                    className="filter-checkbox"
                  />
                  Buen estado
                </label>
              </div>
              <button type="submit" id="search-m" onClick={handleSearch}>
                BUSCAR
              </button>
            </div>
            <div
              className="overview-container-admin"
              style={{
                overflow: "auto",
                maxHeight: "700px",
                alignItems: "flex-start",
              }}
            >
              {carros.map((carro) => (
                <div className="overview-item-boletin" key={carro._id}>
                  <MyCar
                    carro={carro}
                    onCarDeleted={handleCarDeleted}
                    onCarAdded={handleCarAdded}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default AllCarsAdmin;
