import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import CarBoletin from "../CarBoletin";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import "../../style/AllCarsAdmin.css";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import { notification } from "antd";

function AllCarsAdmin() {
  const [carros, setCarros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { authToken, logout } = useAuth();
  const [selectedBrand, setSelectedBrand] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [fullmarca, setfullmarca] = useState([]);
  const [suggestingmodels, setSuggestingModels] = useState(false);
  const [suggestions, setsugestions] = useState([]);
  const [input, setInput] = useState("");
  const [searchType, setSearchType] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);
  const [username, setUsername] = useState('')
  const [selectedCarIds, setSelectedCarIds] = useState([]);
  const [selectedCars, setSelectedCars] = useState([]);



  const navigate = useNavigate();

  const getuser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      setUsername(response.data.nombre);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
    }
  };

  const handleCarSelection = (car, isSelected) => {
    setSelectedCars((prevSelectedCars) => {
      if (isSelected) {
        // Agregar el carro al array de seleccionados
        return [...prevSelectedCars, car];
      } else {
        // Remover el carro del array de seleccionados
        return prevSelectedCars.filter((selectedCar) => selectedCar._id !== car._id);
      }
    });
  };
  
  
  //todo esto es del searchbar
  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return the original string if it's empty or undefined
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getMarcas = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    const carros = res.data;
    const nombres = carros.map((carro) => carro.Nombre);
    setMarcas(nombres);
    setfullmarca(res.data);
  };

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    const modelos = res.data;
    const nombres = modelos.map((modelos) => modelos.Nombre);
    const nombresConMarca = modelos.map(
      (modelo) => `${selectedBrand} ${modelo.Nombre}`
    );
    setModelos(nombres);
    setsugestions(nombresConMarca);
    setSuggestingModels(true);
  };

  const fuse = new Fuse(marcas, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["marca"],
  });

  const anioPattern = /^\d{4}$/;

  const findObjectByKeyValue = (array, key, value) => {
    return array.find((obj) => obj[key] === value);
  };

  const detectInputType = (value) => {
    setSelectedBrand("");
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    const suggestions = fuse.search(parts[0]);
    setsugestions(suggestions.map((suggestion) => suggestion.item));
    parts.forEach((part) => {
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    if (matches[0].type === "marca") {
      const algo = findObjectByKeyValue(fullmarca, "Nombre", matches[0].value);
      setSelectedBrand(matches[0].value);
      getmodelos(algo._id);
    }
    setSearchType([...new Set(matches)]);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
    detectInputType(value);
  };

  const fetchCarrosType = async () => {
    const combinedJson = searchType.reduce((accumulator, current) => {
      accumulator[current.type] = current.value;
      return accumulator;
    }, {});
    setLoading(true);
    setCarros([]);
    try {
      const filteredResTIPO = await axios.post(
        process.env.REACT_APP_BACKENDIP + "filter/",
        combinedJson
      );
      setCarros(filteredResTIPO.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]);
    }
    await setLoading(false);
  };

  const navigateToResults = () => {
    fetchCarrosType();
  };

  const handleSearch = () => {
    navigateToResults();
  };

  const detectInputTypeSugestion = (value) => {
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    parts.forEach((part) => {
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    setSearchType([...new Set(matches)]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setsugestions([]);
    detectInputTypeSugestion(suggestion);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    const inicio = async () => {
      if (!authToken) {
        navigate("/");
      } 
      getuser()
    }
    const fetchAllCarros = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKENDIP + "carro"
        );
        setCarros(response.data.reverse());
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setCarros([]); // Consider whether you want to reset carros on error
      }
      await setLoading(false);
    };
    inicio()
    fetchAllCarros();
    getMarcas();
  }, []);

  const handleCarDeleted = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "carro"
      );
      setCarros(response.data.reverse());
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]); // Consider whether you want to reset carros on error
    }
  };

  const handleCarAdded = () => {
    setSuccessMessage("Carro agregado exitosamente");
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000); // El mensaje desaparece después de 3 segundos
    handleCarDeleted(); // Actualizar la lista de carros
  };

  const enviarboletin = async () => {
    if (selectedCars.length === 0) {
      notification.warning({
        message: 'Advertencia',
        description: 'No hay carros seleccionados para el boletín.',
      });
      return;
    }
    const data = {
      cars: selectedCars
    }
    const res = await axios.post(process.env.REACT_APP_BACKENDIP + "correos/enviar/", data)
  };
  
  

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-a">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element">
                                <img src="icons/banner-white.png" alt="" className="element-img" />
                                <a href='/banner' className="element-text">Banner</a>
                            </div>
                            <div className="element">
                <img
                  src="icons/campana.png"
                  alt=""
                  className="element-img"
                />
                <a href="/notificationsadmin" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info">
          <div
              ref={dropDownRef}
              className="whole-drop-a"
            >
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img-d" />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/sum.png" alt="" className="element-img-d" />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/notification.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/notificationsadmin" className="element-text-d">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-admin-title-a">Enviar Boletín</p>
            <div className="search-bar-a">
              <div
                className={`search-bar-ac ${
                  suggestions.length > 0 ? "show-suggestions" : ""
                }`}
              >
                <input
                  type="text"
                  id="Search"
                  value={input}
                  onChange={handleChange}
                  placeholder=" Marca, modelo, lote, etc."
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="filter-container">
                <label htmlFor="filtro1" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro1"
                    className="filter-checkbox"
                  />
                  Nuevos
                </label>
                <label htmlFor="filtro2" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro2"
                    className="filter-checkbox"
                  />
                  En Oferta
                </label>
              </div>
              <button type="submit" id="search" onClick={handleSearch}>
                BUSCAR
              </button>
            </div>
            <div className="search-bar-a-movil">
              <div
                className={`search-bar-ac ${
                  suggestions.length > 0 ? "show-suggestions" : ""
                }`}
              >
                <input
                  type="text"
                  id="Search-m"
                  value={input}
                  onChange={handleChange}
                  placeholder=" Marca, modelo, lote, etc."
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list-m">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item-m"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="filter-container">
                <label htmlFor="filtro1" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro1"
                    className="filter-checkbox"
                  />
                  Nuevos
                </label>
                <label htmlFor="filtro2" className="filter-label">
                  <input
                    type="checkbox"
                    id="filtro2"
                    className="filter-checkbox"
                  />
                  En Oferta
                </label>
              </div>
              <button type="submit" id="search-m" onClick={handleSearch}>
                BUSCAR
              </button>
            </div>
            <div
              className="overview-container"
              style={{
                overflow: "auto",
                maxHeight: "700px",
                alignItems: "flex-start",
              }}
            >
              {carros.map((carro) => (
                <div className="overview-item-boletin" key={carro._id}>
                  <CarBoletin carro={carro} onSelectionChange={handleCarSelection} />
                </div>
              ))}
            </div>
            <button id="search-b" onClick={enviarboletin}>ENVIAR</button>
          </div>
        </div>
      </div>
      <div className="footer">
      <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default AllCarsAdmin;
