import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../style/Login.css";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { message } from 'antd'; // Importa los mensajes de antd
import 'antd/dist/antd.css'; // Importa el CSS de antd

function Login() {
  const [identifier, setidentifier] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setAuthToken } = useAuth();

  const handleLogin = async () => {
    setLoading(true);
    const data = {
      identifier: identifier,
      password: password,
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKENDIP + "usuario/login",
        data
      );
      const token = response.data.token;
      const timestamp = new Date();
      const tokenData = {
        token: token,
        timestamp: timestamp,
      };
      setAuthToken(token);
      localStorage.setItem("token", JSON.stringify(tokenData));
      message.success('Inicio de sesión exitoso');
      navigate("/");
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error('Error al iniciar sesión, por favor intente de nuevo');
    }
    setLoading(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navhome = () => {
      navigate("/")
  }

  return (
    <>
      <div className="honduautos-back">
        <div className="login-info">
         <a href="/" className="login-l">
         <img
            src="images/honduautos-logo.png"
            onClick={navhome}
            alt="logo de honduautos"
            className="logo-login"
          />
         </a>
          <div className="email-container">
            <input
              type="text"
              className="email-input"
              placeholder="Correo Electrónico/Usuario"
              value={identifier}
              onChange={(e) => setidentifier(e.target.value)}
            />
          </div>
          <div className="password-input-container-login">
            <input
              type={showPassword ? "text" : "password"}
              className="password-input"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            <button
              type="button"
              className="toggle-password-button"
              onClick={toggleShowPassword}
            >
              {showPassword ? (
                <FaEyeSlash className="eye-icon" />
              ) : (
                <FaEye className="eye-icon" />
              )}
            </button>
          </div>
          <div className="button-container">
            <input
              type="submit"
              name="login"
              id="login-buton"
              value="Iniciar Sesión"
              onClick={handleLogin}
              disabled={loading}
            />
          </div>
          <p className="member">¿Aún no eres miembro?</p>
          <Link to="/register" className="register-link">
            Regístrate aquí
          </Link>
        </div>
      </div>
    </>
  );
}

export default Login;
