import React, {useState} from 'react'
import "../style/Car.css"
import Modal from './ModalAbono';


function Car(carro) {
  const cardata = carro.carro.Carro
  const name = cardata.marca + ' ' + cardata.modelo + ' ' + cardata.anio
  let backgroundurl = cardata.imagenes[0];
  backgroundurl = backgroundurl.replace("http://", "https://")
  const imagen = backgroundurl
  const [showModal, setShowModal] = useState(false);
  

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleConfirm = () => {
    // Manejar la confirmación del modal (añadir comprador al carro)
    setShowModal(false);
  };


  return (
    <>
        <div className="main-container-car-user">
            <img src={imagen} alt="" className='car-img-u'/>
            <div className="info-car-user">
                <div className="car-user">
                <p className="car-title-user">{name}</p>
                <p className="car-lote">Lote: {cardata.lote}</p>
                </div>
                <div className="especificaciones-user">
                    <p className="millaje">Millaje: {cardata.millaje}</p>
                    <p className="motor">Motor: {cardata.motor}</p>
                    <p className="combustible"> Combustible: {cardata.combustible}</p>
                </div>
                <p className="precio">Precio: L. {cardata.precio}</p>
            </div>
            <button className='abonar-btn' onClick={toggleModal}>Abonar</button>
        </div>
        <Modal show={showModal} carro={cardata} onClose={toggleModal} onConfirm={handleConfirm} ventaId={carro.carro._id}/>
    </>
  )
}

export default Car