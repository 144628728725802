import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getNames } from 'country-list';
import { notification } from 'antd';
import Navbar from '../Navbar';
import Foooter from '../Foooter';
import Footer from '../FooterMovil';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext.js';
import '../../style/Credito.css';
import styled from 'styled-components';
import PDFModal from '../ModalCredit';
import 'antd/dist/antd.css'; // Importa el CSS de antd
import { ReloadOutlined } from '@ant-design/icons';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


// Estilos para el popup
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const PhoneInputContainer = styled.div`
  width: 100%;
  .phone-input {
    background-color: var(--bgColorRed);
    color: white;
    padding: 2%;
    border-radius: 1vh;
    border-color: transparent;
    width: 100%;
  }
`;

const Popup = ({ message, onClose }) => (
  <Overlay>
    <ModalContainer>
      <CloseButton onClick={onClose}><strong>x</strong></CloseButton>
      <p>{message}</p>
    </ModalContainer>
  </Overlay>
);

const ButtonWithAuthCheck = ({ onSubmit, formData, setFormData }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);

  let isSubmitting = false;

  const handleClick = () => {
    if (onSubmit()) {
      setShowPDFModal(true);
    } else {
      notification.error({
        message: 'Error',
        description: 'Por favor, completa todos los campos.',
        placement: 'top',
        style: {
          width: 400, // Ancho de la notificación
          fontSize: '16px', // Tamaño de la fuente
          padding: '20px', // Espacio interno
        },
      });
    }
  };

  const handleConfirm = async () => {
    setShowPDFModal(false);
    try {
      console.log(formData)
      await axios.post(process.env.REACT_APP_BACKENDIP + "solicitudes", formData);
      notification.success({
        message: 'Operación Exitosa',
        description: 'Formulario enviado exitosamente.',
        placement: 'top',
        style: {
          width: 400, // Ancho de la notificación
          fontSize: '16px', // Tamaño de la fuente
          padding: '20px', // Espacio interno
        },
      });
      // Limpiar formulario después del envío exitoso
      setFormData({
        nombre: '',
        apellido: '',
        direccion: '',
        fechaNacimiento: '',
        pais: '',
        compraDeseada: '',
        IDoRes: '',
        celular: '',
        correo: '',
        tipoVivienda: '',
        antiguedadLaboral: '',
        poseeVehiculo: false,
        ingresosMes: '',
        Status: 'Pendiente',
        tipo: "Credito",
        documentos: []
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error al enviar el formulario.',
        placement: 'top',
        style: {
          width: 400, // Ancho de la notificación
          fontSize: '16px', // Tamaño de la fuente
          padding: '20px', // Espacio interno
        },
      });
    }
  };

  return (
    <>
      <button onClick={handleClick} className='send-form'>
        SUBIR COMPROBANTES
      </button>
      {showPopup && (
        <Popup
          message="No puedes realizar esta acción si no estás logeado."
          onClose={() => setShowPopup(false)}
        />
      )}
      <PDFModal
        show={showPDFModal}
        onClose={() => setShowPDFModal(false)}
        onConfirm={handleConfirm}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  );
};

const viviendaOptions = [
  { value: 'Propia', label: 'Propia' },
  { value: 'Alquilada', label: 'Alquilada' },
];

const antiguedadOptions = [
  { value: 'Menos de 6 meses', label: 'Menos de 6 meses' },
  { value: 'De 1 a 2 años', label: 'De 1 a 2 años' },
  { value: 'De 2 a 3 años', label: 'De 2 a 3 años' },
  { value: 'De 3 a 4 años', label: 'De 3 a 4 años' },
  { value: 'De 4 a 5 años', label: 'De 4 a 5 años' },
  { value: '+ 5 años', label: '+ 5 años' },
];

function Credito() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedVivienda, setSelectedVivienda] = useState(null);
  const [selectedAntiguedad, setSelectedAntiguedad] = useState(null);
  const [amount, setAmount] = useState('');
  const [months, setMonths] = useState('');
  const [interest, setInterest] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [content, setContent] = useState('');
  const { authToken } = useAuth();
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    direccion: '',
    fechaNacimiento: '',
    pais: '',
    compraDeseada: '',
    IDoRes: '',
    celular: '',
    correo: '',
    tipoVivienda: '',
    antiguedadLaboral: '',
    poseeVehiculo: false,
    ingresosMes: '',
    Status: 'Pendiente',
    tipo: "Credito",
    documentos: []
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, celular: value });
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, poseeVehiculo: value === 'Si' });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFormData({ ...formData, pais: selectedOption.value });
  };

  const handleViviendaChange = (selectedOption) => {
    setSelectedVivienda(selectedOption);
    setFormData({ ...formData, tipoVivienda: selectedOption.value });
  };

  const handleAntiguedadChange = (selectedOption) => {
    setSelectedAntiguedad(selectedOption);
    setFormData({ ...formData, antiguedadLaboral: selectedOption.value });
  };

  const calculateMonthlyPayment = (months, amount, interest) => {
    const monthlyInterestRate = (interest / 100) / 12;
    const n = months;
    const P = amount;

    if (monthlyInterestRate === 0) {
      return P / n;
    }

    const monthlyPayment = (P * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, n)) /
                           (Math.pow(1 + monthlyInterestRate, n) - 1);

    return monthlyPayment;
  };

  const handleCalculate = () => {
    const payment = calculateMonthlyPayment(Number(months), Number(amount), Number(interest));
    setMonthlyPayment(payment.toFixed(2));
  };

  const handleReset = () => {
    setAmount('');
    setMonths('');
    setInterest('');
    setMonthlyPayment(null);
  };

  const fetchContent = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKENDIP + "content/content/Credito", {
        headers: { Authorization: `Bearer ${authToken}` }
      });
      setContent(response.data.body);
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  const submit = () => {
    if (!formData.nombre || !formData.apellido || !formData.direccion || !formData.fechaNacimiento || !selectedOption || !formData.compraDeseada || !formData.IDoRes || !formData.celular || !formData.correo || !selectedVivienda || !selectedAntiguedad || !formData.ingresosMes) {
      notification.error({
        message: 'Error',
        description: 'Favor, completa todos los campos.',
        placement: 'top',
        style: {
          width: 400, // Ancho de la notificación
          fontSize: '16px', // Tamaño de la fuente
          padding: '20px', // Espacio interno
        },
      });
      return false;
    }

    return true; // Indica que los datos están completos y el formulario puede ser enviado
  };

  useEffect(() => {
    fetchContent();
  }, [authToken]);

  const countries = getNames().map(country => ({ value: country, label: country }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: '1% 0',
      backgroundColor: '#EDECEC',
      borderColor: 'transparent',
      boxShadow: 'none',
      width: '100%',
      '&:hover': {
        borderColor: 'transparent',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#EDECEC',
      width: '100%'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#dcdcdc' : '#EDECEC',
      color: 'black',
      width: '100%',
      '&:hover': {
        backgroundColor: '#dcdcdc',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#888',
      fontSize: '12px',
    }),
  };

  const handleFormSubmit = async () => {
    if (!submit()) {
      return;
    }

    try {
      await axios.post(process.env.REACT_APP_BACKENDIP + "solicitudes", formData);
      notification.success({
        message: 'Operación Exitosa',
        description: 'Formulario enviado exitosamente.',
        placement: 'top',
        style: {
          width: 400,
          fontSize: '16px',
          padding: '20px',
        },
      });
      setFormData({
        nombre: '',
        apellido: '',
        direccion: '',
        fechaNacimiento: '',
        pais: '',
        compraDeseada: '',
        IDoRes: '',
        celular: '',
        correo: '',
        tipoVivienda: '',
        antiguedadLaboral: '',
        poseeVehiculo: false,
        ingresosMes: '',
        Status: 'Pendiente',
        tipo: "Credito",
        documentos: []
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error al enviar el formulario.',
        placement: 'top',
        style: {
          width: 400,
          fontSize: '16px',
          padding: '20px',
        },
      });
    }
  };

  return (
    <>
      <Navbar/>
      <div className='credit-container'>
        <p id='credit-title'>Solicitud de Crédito</p>

        <div className="content-body-c">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>

        <center>
          <div className='payment'>
            <center><p>CALCULA TU CUOTA (MENSUAL)</p></center>
            <div className='payment-input'>
              <input type="text" id='payment-input' value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Valor del Financiamiento (L0,00)'/>
              <input type="text" id='payment-input' value={months} onChange={(e) => setMonths(e.target.value)} placeholder='Plazo en meses'/>
              <input type="text" id='payment-input' value={interest} onChange={(e) => setInterest(e.target.value)} placeholder='Tasa % anual'/>
            </div>
            <input onClick={handleCalculate} type="submit" name='calcular' value="Calcular" id='sumbit-form'/>
            {monthlyPayment !== null && !isNaN(monthlyPayment) && (
              <div>
                <p>Cuota mensual: L. {monthlyPayment}</p>
                <button onClick={handleReset} className='reset-button'>
                <ReloadOutlined />
                </button>
              </div>
            )}
          </div>
        </center>

        <center><p className='title-c'>LLENA TU SOLICITUD DE CRÉDITO AQUÍ</p></center>
        <div className='center-grid'>
          <div className="columns-c">
            <input type="text" id='nombre' name="nombre" value={formData.nombre} placeholder=' Nombre' onChange={handleInputChange}/>
            <input type="text" id='apellido' name="apellido" value={formData.apellido} onChange={handleInputChange} placeholder=' Apellido'/>
            <input type="text" id='email' name="correo" value={formData.correo} onChange={handleInputChange} placeholder=' Email*'/>
            <input type="text" id='fecha_nac' name="fechaNacimiento" value={formData.fechaNacimiento} onChange={handleInputChange} placeholder=' Fecha de nacimiento'/>
            <input type="text" id='info_credit' name="compraDeseada" value={formData.compraDeseada} onChange={handleInputChange} placeholder=' ¿Qué deseas comprar con tu crédito?'/>
            <input type="text" id='ingresos' name="ingresosMes" value={formData.ingresosMes} onChange={handleInputChange} placeholder=' Ingresos mensuales en números (10,000)'/>
          </div>
          <div className="columns-c">
          <input type="text" id='id' name="IDoRes" value={formData.IDoRes} onChange={handleInputChange} placeholder=' # de Identidad o Residencia *'/>
          <div className="phone-i">
              <PhoneInputContainer>
                <PhoneInput
                  defaultCountry="hn"
                  value={formData.celular}
                  onChange={handlePhoneChange}
                  containerClass='phone-input'
                  className='input-class'
                />
              </PhoneInputContainer>
          </div>
          <div className='vehiculo'>
              <p className='little'>Posee vehículo</p>
              <input 
                type="checkbox" 
                className='checkbox' 
                value="Si" 
                checked={formData.poseeVehiculo === true}
                onChange={handleCheckboxChange}
              />
              <p>Si</p>
              <input 
                type="checkbox" 
                className='checkbox' 
                value="No" 
                checked={formData.poseeVehiculo === false}
                onChange={handleCheckboxChange}
              />
              <p>No</p>
            </div>
            <Select id='dropdown' 
              value={selectedOption}
              onChange={handleCountryChange}
              options={countries}
              placeholder=" Selecciona un país"
              styles={customStyles}
            />
            <Select id='laboral'
              value={selectedAntiguedad}
              onChange={handleAntiguedadChange}
              options={antiguedadOptions}
              placeholder=" Selecciona antigüedad laboral"
              styles={customStyles}
              isSearchable
            />
            
            <Select id='vivienda_type'
              value={selectedVivienda}
              onChange={handleViviendaChange}
              options={viviendaOptions}
              placeholder=" Selecciona tu tipo de vivienda"
              styles={customStyles}
            />
          </div>
        </div>
        <center><input type="text" name="direccion" id="direccion" value={formData.direccion} onChange={handleInputChange} placeholder=' Dirección'/></center>
        <div className="div-credit-btns">
        <ButtonWithAuthCheck onSubmit={submit} formData={formData} setFormData={setFormData} />
        <button className='send-form-2' onClick={handleFormSubmit}>SUBIR SOLICITUD</button>
        </div>
        <p className='last-p'>Informamos a nuestros clientes en general que Honduautos, tiene suscrito 
          un contrato de servicios con Equifax que es una Central de Riesgos 
          Privada o buró de crédito, a la cual consultamos y reportamos la 
          información relacionada con su crédito.</p>
      </div>
      <div className='web-footer'>
        <Foooter/>
      </div>
      <div className="movil-footer">
        <Footer/>
      </div>
    </>
  );
}

export default Credito;
