import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import Fuse from "fuse.js";
import { useAuth } from "../context/AuthContext";
import LoadingBar from 'react-top-loading-bar';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 1% 0;

  &:hover {
    background: #0056b3;
  }
`;

const AddButton = styled.button`
  background: #b81414;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 1% 0;

  &:hover {
    background: #861d1d;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 1% 0;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Modal = ({ show, onClose, onConfirm, carroid, carro }) => {
  const [nombres, setnombres] = useState([]);
  const [input, setInput] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [fulluser, setfulluser] = useState([]);
  const [userId, setUserid] = useState("");
  const [registrado, setregistrado] = useState(true);
  const [showUserForm, setShowUserForm] = useState(false);
  const { authToken } = useAuth();

  const ref = useRef(null);

  const backgroundurl = carro.imagenes[0];

  const findallusernames = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "usuario");
    const data = res.data;
    const usernames = [];
    data.forEach((usuario) => {
      if (usuario.nombre) {
        usernames.push(usuario.nombre);
      }
    });
    setfulluser(data);
    setnombres(usernames);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
    detectInputType(value);
  };

  const fuse = new Fuse(
    nombres.map((nombre) => ({ nombre: nombre })),
    {
      threshold: 0.9,
      distance: 100,
      minMatchCharLength: 3,
      keys: ["nombre"],
    }
  );

  const detectInputType = (value) => {
    setSelectedName("");
    const valueTrim = value.trim();
    let matches = [];
    const suggestions = fuse.search(valueTrim);
    setSuggestions(suggestions.map((suggestion) => suggestion.item.nombre));
    if (
      nombres.some((nombre) => nombre.toLowerCase() === valueTrim.toLowerCase())
    ) {
      matches.push({ type: "nombre", value: valueTrim });
    }
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
  };

  const finduser = (username) => {
    const matchedUser = fulluser.find(
      (user) => user.nombre.toLowerCase() === username.toLowerCase()
    );
    setUserid(matchedUser._id);
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setSuggestions([]);
    finduser(suggestion);
  };

  const [nombre , setnombre] = useState('')
  const [correo, setcorreo] = useState('')
  const [telefono, settelefono] = useState('')

  const comprar = async () => {
    ref.current.continuousStart();
    try {
      const data = {
        identifierUser: userId,
        registrado: registrado,
        nombre: nombre,
        correo: correo,
        telefono: telefono
      };
      if(correo === ''){
        data.correo = "sincorreo@honduautos.com"
      }
      if(telefono === ''){
        data.telefono = "sintelefono"
      }
      const res = await axios.post(
        process.env.REACT_APP_BACKENDIP + "ventas/compra/" + carroid,
        data,
        { headers: { Authorization: "bearer " + authToken } }
      );
      onConfirm();
      onClose(); // Close the modal after confirming
    } catch (error) {
      console.log(error);
    } finally {
      ref.current.complete();
    }
  };

  const handleAddButtonClick = () => {
    setShowUserForm(true);
    setregistrado(false);
  };

  useEffect(() => {
    if (show) {
      findallusernames();
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>X</CloseButton>
        <LoadingBar color="#800080" ref={ref} className="top-loading-bar" />
        <div className="car-models-mod">
          <div className="movil-marca">
            <p className="car-title-m">
              {carro.marca} {carro.modelo} {carro.anio}
            </p>
          </div>
          <div className="head">
            <p className="car-title-mod">{carro.marca} {carro.modelo} {carro.anio}</p>
          </div>
          <img src={backgroundurl} alt="" className="car-img-mod" />
        </div>
        <h2>Añadir Comprador</h2>
        <div
          className={`search-bar-mod ${
            suggestions.length > 0 ? "show-suggestions" : ""
          }`}
        >
          <input
            type="text"
            id="Search"
            value={input}
            onChange={handleChange}
            placeholder=" Nombre del comprador"
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list-mod">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="suggestion-item"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        {showUserForm && (
          <div className="inputs-modal-comprador">
            <Input 
            type="text" 
            placeholder="Nombre" 
            value={nombre} 
            onChange={(e) => setnombre(e.target.value)} 
            />
            <Input 
            type="email" 
            placeholder="Correo" 
            value={correo} 
            onChange={(e) => setcorreo(e.target.value)} 
            />
            <Input 
            type="tel" 
            placeholder="Teléfono" 
            value={telefono} 
            onChange={(e) => settelefono(e.target.value)} 
            />
          </div>
        )}
        <div className="buttons-modal-comprador">
          <AddButton onClick={handleAddButtonClick}>Usuario no registrado</AddButton>
          <ConfirmButton onClick={comprar}>Confirmar</ConfirmButton>
        </div>
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;
