import React, { useState, useEffect, useRef } from "react";
import MyCar from "../MyCar";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import "../../style/MyCars.css";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AllCars() {
  const [carros, setCarros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [username, setUsername] = useState("");
  const { authToken, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getuser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      setUsername(response.data.nombre);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]);
    }
  };

  const getown = async () => {
    try {
      const responseUser = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      const id = responseUser.data._id;
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "carro/own/" + id,
        { headers: { Authorization: "bearer " + authToken } }
      );
      setCarros(response.data.carros);
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]);
    }
  };

  useEffect(() => {
    const fetchAllCarros = async () => {
      setLoading(true);
      if (authToken !== null) {
        await getuser();
        await getown();
      } else {
        navigate("/");
      }

      await setLoading(false);
    };
    fetchAllCarros();
  }, [authToken, navigate]);

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="container-u">
        <div className="main-container-user-user">
          <div className="column-user">
           
            <p className="user-name">Hola, {username}</p>
            <div className="user-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/mycars" className="element-text">
                  Mis autos
                </a>
              </div>
              <div className="element">
                <img src="icons/campana.png" alt="" className="element-img" />
                <a href="/notifications" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcars" className="element-text">
                  Mis compras
                </a>
              </div>
              <div onClick={handleLogout} className="element-end">
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info">
            <div ref={dropDownRef} className="whole-drop-u">
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU USUARIO</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="user-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img" />
                      <a href="/mycars" className="element-text">
                        Mis autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/campana.png"
                        alt=""
                        className="element-img"
                      />
                      <a href="/notifications" className="element-text">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img" />
                      <a href="/allcars" className="element-text">
                        Mis compras
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Listado de Mis Autos</p>
            <div
              className="overview-container"
              style={{
                overflow: "auto",
                maxHeight: "450px",
                alignItems: "flex-start",
              }}
            >
              {carros.length > 0 ? (
                carros.map((carro) => (
                  <div className="overview-item-boletin" key={carro._id}>
                    <MyCar carro={carro} />
                  </div>
                ))
              ) : (
                <p className="no-cars">No tienes ningún carro agregado</p>
              )}
            </div>
            <button className="add-car-btn"> Añadir Auto</button>
          </div>
        </div>
        <div className="footer">
          <Foooter />
        </div>
        <div className="footer-movil">
          <FooterMovil />
        </div>
      </div>
    </>
  );
}

export default AllCars;
