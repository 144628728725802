import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import "../style/MenuStock.css";
import axios from "axios";

function MenuItem({ title, children, isFirst, isLast, isAlreadyOpen }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isAlreadyOpen);
  }, [isAlreadyOpen]);

  return (
    <>
      <div
        onClick={toggleMenu}
        className={`option-title ${isFirst ? "first" : ""} ${
          isLast ? "last" : ""
        }`}
      >
        <div className="title">{title}</div>
        <div className="arrows">
          {isOpen ? (
            <img src="icons/arrow-up-dark.png" id="arrow" alt="" />
          ) : (
            <img src="icons/arrow-down-dark.png" id="arrow" alt="" />
          )}
        </div>
      </div>
      {isOpen && <div>{children}</div>}
    </>
  );
}

function FilterBox({
  activeOption,
  toggleFilter,
  checkboxes,
  handleCheckboxChange,
  sliderValue,
  handleSliderChange1,
  handleSliderChange2,
  handleSliderChange3,
  isVisible,
  isModelMenuVisible,
  marcas,
  modelos,
  handleResetFilters,
  isAnyFilterActive,
}) {


  const marks = [
    { value: 1.0, label: "1.0L" },
    { value: 7.0, label: "7.0L" },
  ];

  const marksyear = [
    { value: 2009, label: "2009" },
    { value: 2024, label: "2024" },
  ];

  const marksprice = [
    { value: 0.0, label: "L. 0.0" },
    { value: 500000.0, label: "L. 500000.0" },
  ];

  const [showResetButton, setShowResetButton] = useState(false);
  const [isOpenAno, setIsOpenAno] = useState(false);
  const [isOpenMotor, setIsOpenMotor] = useState(false);
  const [isOpenPrecio, setIsOpenPrecio] = useState(false);

  useEffect(() => {
    setShowResetButton(isAnyFilterActive());
  }, [checkboxes, sliderValue]);

  if (!isVisible) {
    return null;
  }


  return (
    <div className="filterbox">
      <div className="filter-header">
        <p className="filter-title">Filtros</p>
        {showResetButton && (
          <button onClick={handleResetFilters} className="reset-filters-button">
            Limpiar filtros
          </button>
        )}
      </div>
      <div className="scroll">
        <MenuItem title="Condición" isFirst={true}>
          <div className="menu-option">
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="chocados"
                  checked={checkboxes.chocados}
                  onChange={handleCheckboxChange}
                />
                <p>Chocados</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="arreglados"
                  checked={checkboxes.arreglados}
                  onChange={handleCheckboxChange}
                />
                <p>Buen Estado</p>
              </div>
            </label>
          </div>
        </MenuItem>
        <MenuItem title="Marca">
          <div className="menu-option">
            {marcas.map((marca) => (
              <label className="drop-menu" key={marca}>
                <div className="span-filter">
                  <input
                    type="checkbox"
                    className="check"
                    name={marca}
                    checked={checkboxes.marca}
                    onChange={handleCheckboxChange}
                  />
                  <p>{marca}</p>
                </div>
              </label>
            ))}
          </div>
        </MenuItem>
        <MenuItem title="Ubicación" isFirst={true}>
          <div className="menu-option">
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="Usa"
                  checked={checkboxes.Usa}
                  onChange={handleCheckboxChange}
                />
                <p>USA</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="Puerto"
                  checked={checkboxes.Puerto}
                  onChange={handleCheckboxChange}
                />
                <p>Puerto Cortez</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="Tegucigalpa"
                  checked={checkboxes.Tegucigalpa}
                  onChange={handleCheckboxChange}
                />
                <p>Tegucigalpa</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="Transito"
                  checked={checkboxes.Transito}
                  onChange={handleCheckboxChange}
                />
                <p>Transito</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="SanLorenzo"
                  checked={checkboxes.SanLorenzo}
                  onChange={handleCheckboxChange}
                />
                <p>San Lorenzo</p>
              </div>
            </label>
          </div>
        </MenuItem>
        {isModelMenuVisible && (
        <MenuItem title="Modelos">
          <div className="menu-option">
            {modelos.map((modelo) => (
              <label className="drop-menu" key={modelo}>
                <div className="span-filter">
                  <input
                    type="checkbox" // Permitir múltiples selecciones
                    className="check"
                    name={modelo}
                    checked={checkboxes[modelo] || false}
                    onChange={handleCheckboxChange}
                  />
                  <p>{modelo}</p>
                </div>
              </label>
            ))}
          </div>
        </MenuItem>
      )}

        <MenuItem title="Año" isAlreadyOpen={isOpenAno}>
          <div className="menu-option">
            <Box sx={{ width: 150 }}>
              <Slider
                aria-label="Always visible"
                defaultValue={sliderValue}
                getAriaValueText={(value) => `${value}`}
                min={2009}
                step={1}
                max={2024}
                marks={marksyear}
                valueLabelDisplay="on"
                onChange={handleSliderChange1}
              />
            </Box>
          </div>
        </MenuItem>
        <MenuItem title="Tipo de Auto">
          <div className="menu-option">
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="turismo"
                  checked={checkboxes.turismo}
                  onChange={handleCheckboxChange}
                />
                <p>Turismo</p>
                <img
                  src="images/sedan.png"
                  alt=""
                  className="type-filter-sedan"
                />
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="camioneta"
                  checked={checkboxes.camioneta}
                  onChange={handleCheckboxChange}
                />
                <p>Camioneta</p>
                <img
                  src="images/camioneta.png"
                  alt=""
                  className="type-filter-camioneta"
                />
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="pickup"
                  checked={checkboxes.pickup}
                  onChange={handleCheckboxChange}
                />
                <p>Pick up</p>
                <img
                  src="images/pick-up.png"
                  alt=""
                  className="type-filter-pickup"
                />
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="camion"
                  checked={checkboxes.camion}
                  onChange={handleCheckboxChange}
                />
                <p>Camión</p>
                <img
                  src="images/truck.png"
                  alt=""
                  className="type-filter-camion"
                />
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="camioncito"
                  checked={checkboxes.camioncito}
                  onChange={handleCheckboxChange}
                />
                <p>Camioncito</p>
                <img
                  src="images/camioncito.png"
                  alt=""
                  className="type-filter-camioncito"
                />
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="cabezal"
                  checked={checkboxes.cabezal}
                  onChange={handleCheckboxChange}
                />
                <p>Cabezal</p>
                <img
                  src="images/cabezal.png"
                  alt=""
                  className="type-filter-cabezal"
                />
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="bus"
                  checked={checkboxes.bus}
                  onChange={handleCheckboxChange}
                />
                <p>Bus</p>
                <img src="images/bus.png" alt="" className="type-filter-bus" />
              </div>
            </label>
          </div>
        </MenuItem>
        <MenuItem title="Combustible">
          <div className="menu-option">
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="gasolina"
                  checked={checkboxes.gasolina}
                  onChange={handleCheckboxChange}
                />
                <p>Gasolina</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="diesel"
                  checked={checkboxes.diesel}
                  onChange={handleCheckboxChange}
                />
                <p>Diesel</p>
              </div>
            </label>
          </div>
        </MenuItem>
        <MenuItem title="Precio" isAlreadyOpen={isOpenPrecio}>
          <div className="menu-option">
            <Box sx={{ width: 150 }}>
              <Slider
                aria-label="Always visible"
                defaultValue={sliderValue}
                getAriaValueText={(value) => `${value}`}
                min={0.0}
                step={100}
                max={500000.0}
                marks={marksprice}
                valueLabelDisplay="on"
                onChange={handleSliderChange2}
              />
            </Box>
          </div>
        </MenuItem>
        <MenuItem title="Motor" isAlreadyOpen={isOpenMotor}>
          <div className="menu-option">
            <Box sx={{ width: 150 }}>
              <Slider
                aria-label="Always visible"
                defaultValue={sliderValue}
                getAriaValueText={(value) => `${value}`}
                min={1.0}
                step={0.1}
                max={7.0}
                marks={marks}
                valueLabelDisplay="on"
                onChange={handleSliderChange3}
              />
            </Box>
          </div>
        </MenuItem>
        <MenuItem title="Transmisión">
          <div className="menu-option">
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="automatica"
                  checked={checkboxes.automatica}
                  onChange={handleCheckboxChange}
                />
                <p>Automática</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="manual"
                  checked={checkboxes.manual}
                  onChange={handleCheckboxChange}
                />
                <p>Manual</p>
              </div>
            </label>
          </div>
        </MenuItem>
        <MenuItem title="Tracción" isLast={true}>
          <div className="menu-option">
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="delantera"
                  checked={checkboxes.delantera}
                  onChange={handleCheckboxChange}
                />
                <p>Delantera</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="trasera"
                  checked={checkboxes.trasera}
                  onChange={handleCheckboxChange}
                />
                <p>Trasera</p>
              </div>
            </label>
            <label className="drop-menu">
              <div className="span-filter">
                <input
                  type="checkbox"
                  className="check"
                  name="ruedas"
                  checked={checkboxes.ruedas}
                  onChange={handleCheckboxChange}
                />
                <p>4 ruedas</p>
              </div>
            </label>
          </div>
        </MenuItem>
      </div>
    </div>
  );
}

export default FilterBox;
