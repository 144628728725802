import React, { useEffect, useState, useRef, useContext } from "react";
import Navbar from "../Navbar.jsx";
import Footer from "../Foooter.jsx";
import DropdownMenu from "../Dropdown.jsx";
import MenuStock from "../MenuStock.jsx";
import { StockContext } from '../../context/StockContext';
import "../../style/Stock.css";
import axios from "axios";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate, useLocation } from "react-router-dom";
import Fuse from "fuse.js";
import FooterMovil from "../FooterMovil.jsx";

function Stock() {
  const [carros, setCarros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken, logout } = useAuth();
  const [suggestions, setsugestions] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [fullmodelo, setfullmodelo] = useState([]);
  const [allmodelos, setAllModelos] = useState([]);
  const [input, setInput] = useState("");
  const [searchType, setSearchType] = useState([]);
  const [marcaid, setmarcaid] = useState("");
  const [fullmarca, setfullmarca] = useState([]);
  const [suggestingmodels, setSuggestingModels] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [reloadCont, setReload] = useState(false);

  const containerRef = useRef(null);
  const navigate = useNavigate();

  const isFetchingMarcas = useRef(false);
  const isFetchingModelos = useRef(false);
  const isMounted = useRef(true);

  //const location = useLocation();
  //const activeOption = location.state?.activeOption || null;

  //Contexto
  const { openFilters, setOpenFilters } = useContext(StockContext);

  

  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return the original string if it's empty or undefined
    if(str === "bmw"){
      return "BMW"
    }
    if(str === "gmc"){
      return "GMC"
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([getMarcas(), getModelosAll()]);
    };

    fetchInitialData();

    return () => {
      isMounted.current = false; // Cleanup to prevent state updates if component unmounts
    };
  }, []);
  
  const getMarcas = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    const carros = res.data;
    const nombres = carros.map((carro) => carro.Nombre);
    setMarcas(nombres);
    setfullmarca(res.data);
  };

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    const modelos = res.data;
    const nombres = modelos.map((modelos) => modelos.Nombre);
    const nombresConMarca = modelos.map(
      (modelo) => `${selectedBrand} ${modelo.Nombre}`
    );
    setModelos(nombres);
    setsugestions(nombresConMarca);
    setSuggestingModels(true);
  };

  const getModelosAll = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelo");
    const modelos = res.data;
    const nombres = modelos.map((modelo) => modelo.Nombre);
    setAllModelos(nombres)
    setfullmodelo(modelos);
  };

  const fuse = new Fuse(marcas, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["marca"],
  });

  const fusemodelos = new Fuse(allmodelos, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["modelo"],
  });

  const anioPattern = /^\d{4}$/;


  const findObjectByKeyValue = (array, key, value) => {
    return array.find((obj) => obj[key] === value);
  };

  const detectInputType = (value) => {
    setSelectedBrand("");
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    const suggestions = fuse.search(parts[0]);
    const suggestionsmodelos = fusemodelos.search(parts[0])
    let mergedArray = suggestionsmodelos.concat(suggestions);
    setsugestions(mergedArray.map((suggestion) => suggestion.item));
    parts.forEach((part) => {
      if(part === "mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if(part === "santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (allmodelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
      if (lotNumberPattern.test(part)) {
        matches.push({ type: "lote", value: part });
      }
    });
    console.log(matches)
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    if (matches[0].type === "marca") {
      const algo = findObjectByKeyValue(fullmarca, "Nombre", matches[0].value);
      setSelectedBrand(matches[0].value);
      getmodelos(algo._id);
    }
    setSearchType([...new Set(matches)]);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
    detectInputType(value);
  };

  const navigateToResults = () => {
    setsugestions([])
    const queryParams = new URLSearchParams();
    searchType.forEach((info) => {
      queryParams.append(info.type, info.value);
    });
    setReload(!reloadCont);
    navigate(`/stock?${queryParams.toString()}`);
  };

  const handleSearch = () => {
    navigateToResults();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const lotNumberPattern = /^\d{8}$/

  const detectInputTypeSugestion = (value) => {
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    parts.forEach((part) => {
      if(part === "mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if(part === "santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (allmodelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
      if (lotNumberPattern.test(part)) {
        matches.push({ type: "lote", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    setSearchType([...new Set(matches)]);
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setsugestions([]);
    detectInputTypeSugestion(suggestion);
  };

  return (
    <>
      <Navbar />
      {/* search bar */}
      <div className="search-bar">
        <div
          className={`search-bar-ac ${
            suggestions.length > 0 ? "show-suggestions" : ""
          }`}
        >
          <input
            type="text"
            id="Search"
            value={input}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder=" Marca, modelo, lote, etc."
          />
          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="suggestion-item"
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <DropdownMenu />
        <button type="submit" id="search" onClick={handleSearch}>
          BUSCAR
        </button>
      </div>

      <div className="stock-view">
        {/* Stock Filter */}
        <div className="menu-stock">
          <MenuStock
            reload={reloadCont}
            activeOption={openFilters}
          />
        </div>
        {/* stock part */}
        <div className="stock"></div>
      </div>
      <div className="footer">
        <Footer />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default Stock;
