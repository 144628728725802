import React, {useEffect, useState, useRef} from "react";
import "../style/Footer.css";
import axios from 'axios';

function Foooter() {

  const [tel, settel] = useState("")
  const isFetching = useRef(false); // Use useRef to avoid re-renders when updating

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    if (isFetching.current) {
      // The function is already running; exit to prevent multiple executions
      return;
    }
    isFetching.current = true; // Set the flag to indicate the function is running
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKENDIP}content/content/Otros`
      );
      settel(res.data.body);
    } catch (error) {
      console.error('Error fetching content:', error);
    } finally {
      isFetching.current = false; // Reset the flag when the function completes
    }
  };

  return (
    <>
      <div className="footer-grid">
        <div className="image-footer">
          <a href="/">
          <img
            src="/images/honduautos-logo.png"
            alt="Logo de honduautos"
            id="logo-honduautos-2"
          />
          </a>
        </div>
        <div className="information-part">
          <a className="foolink" href="/aboutus">Sobre nosotros</a>
          <a className="foolink" href="/credit">Solicitud de crédito</a>
          <a className="foolink" href="/contact">Contáctenos</a>
          <a className="foolink" href="/avisos">Avisos</a>
        </div>
        <div className="line-div">
          <div className="line"></div>
        </div>
        <div className="input-part">
          <div className="input-section">
            <p className="input-text">
              ¡Sigue pendiente de nuestras nuevas ofertas!
            </p>
           <div className="subscribe">
           <input
              type="text"
              name="email"
              id="email-f"
              placeholder="Correo electrónico"
            />
            <button type="submit" className="button-submit">
              Suscribrise
            </button>
           </div>
            <br />
            <div className="checked-part">
              <input type="checkbox" className="check-box" />
              <p className="footer-text">
                Al hacer click sobre el cuadro aceptas recibir notificaciones
                sobre ofertas y anuncios de nuestra empresa HonduAutos
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-part">
        <center>
          <a href="/politicas" className="red-link">Políticas y Garantías</a>
        </center>
        <center>
          <p>© 2024 HonduAutos</p>
        </center>
        <div className="right-footer">
          <p>Llámenos al {tel} </p>
          <div className="foo-logos">
          <a href="https://www.instagram.com/honduautoscom?igsh=MTh1eHJpOHIydmVzOQ==" target="_blank" rel="noopener noreferrer">
            <img src="icons/insta-logo.png" alt="" className="foo-log" />
          </a>
          <a href='https://m.facebook.com/honduautos?mibextid=LQQJ4d' target="_blank" rel="noopener noreferrer">
          <img src="icons/facebook-logo.png" alt="" className="foo-log"/>
          </a>
          <a href='https://api.whatsapp.com/send?phone=%2B50499700699&context=ARAbAC7ZPDUcgue8K6fARO2i3gW6YABlKSHKfgGBoXVpiqOXnQYkDAu9g5OkOQaLIdNEWEdM1JHowPgda4qXXkNjW8LhDuEugv2y9uCZ_ohEajSUSeCjDe5TdbEYOywM2bDBpICqk8BSUE-FVdYU1fQYzA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwY2xjawFx0zBleHRuA2FlbQIxMQABHZYGf9cXQzSy0Yd-ksN0DL_8_Uvw641J5QZhGHTsdOUOoOTI_yp58WM0Bg_aem_WSUL9XfeCs3mx_tXA_EUFw' target="_blank" rel="noopener noreferrer">
          <img src="icons/wp-logo.png" alt="" className="foo-log"/>
          </a>
          <a href='https://www.tiktok.com/@honduautos.com?is_from_webapp=1&sender_device=pc' target="_blank" rel="noopener noreferrer">
          <img src="icons/tik-tok.png" alt="" className="foo-log"/>
          </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Foooter;
