import React, { useState, useEffect, useRef } from "react";
import Car from "../Car";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import "../../style/AllCars.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext.js";

function AllCars() {
  const [carros, setCarros] = useState([]);
  const [error, setError] = useState(null);
  const { authToken, logout } = useAuth();
  const [username, setUsername] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getcompras = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "ventas/miscompras",
      { headers: { Authorization: "bearer " + authToken } }
    );
    setCarros(res.data);
  };
  const getuser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      setUsername(response.data.nombre);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
    }
  };

  useEffect(() => {
    const inicio = async () => {
      if (!authToken) {
        navigate("/");
      }
      getuser();
      getcompras();
    };
    inicio();
  }, []);

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-user">
          <div className="column-user">
            <p className="user-name">Hola, {username}</p>
            <div className="user-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/mycars" className="element-text">
                  Mis autos
                </a>
              </div>
              <div className="element">
                <img src="icons/campana.png" alt="" className="element-img" />
                <a href="/notifications" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcars" className="element-text">
                  Mis compras
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info">
            <div ref={dropDownRef} className="whole-drop-u">
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU USUARIO</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="user-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img" />
                      <a href="/mycars" className="element-text">
                        Mis autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/campana.png"
                        alt=""
                        className="element-img"
                      />
                      <a href="/notifications" className="element-text">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img" />
                      <a href="/allcars" className="element-text">
                        Mis compras
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Listado de Autos Comprados</p>
            <div
              className="overview-container"
              style={{
                overflow: "auto",
                maxHeight: "450px",
                alignItems: "flex-start",
              }}
            >
              {carros.map((carro) => (
                <div className="overview-item-n" key={carro._id}>
                  <Car carro={carro} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default AllCars;
