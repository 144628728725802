import React, { createContext, useContext, useState, useEffect } from 'react';


const AuthContext = createContext();
const TOKEN_TTL = 12000 * 1000; //24 horas 86400

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(() => {
        const tokenData = localStorage.getItem('token');
        if (tokenData) {
            const { token, timestamp } = JSON.parse(tokenData);
            const now = new Date();
            // Check if the token is expired
            if (now.getTime() - timestamp > TOKEN_TTL) {
                localStorage.removeItem('token'); // Remove expired token
                return null;
            }
            return token;
        }
        return null;
    });

    // Optionally, synchronize the authToken state with localStorage
    useEffect(() => {
        if (authToken) {
            const item = {
                token: authToken,
                timestamp: new Date().getTime()
            };
            localStorage.setItem('token', JSON.stringify(item));
        } else {
            localStorage.removeItem('token');
        }
    }, [authToken]);

    const logout = () => {
        setAuthToken(null);
    };

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken,logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
