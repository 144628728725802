import React from 'react'
import '../style/FooterMovil.css'

function FooterMovil() {
  return (
    <>
        <div className='movil-container'>
            <div className='logo-footer'>
                <p className='red'>HONDU</p>
                <p className="white">AUTOS</p>
                <p className="red">.COM</p>
            </div>
            <div className="logos-footer">
            <a href="https://www.instagram.com/honduautoscom?igsh=MTh1eHJpOHIydmVzOQ==" target="_blank" rel="noopener noreferrer" className='log'>
                <img src="icons/insta-logo.png" alt="" className='log'/>
            </a>
            <a href='https://m.facebook.com/honduautos?mibextid=LQQJ4d' target="_blank" rel="noopener noreferrer" className='log'>
                <img src="icons/facebook-logo.png" alt="" className='log'/>
            </a>
            <a href='https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B50499700699%26context%3DARAbAC7ZPDUcgue8K6fARO2i3gW6YABlKSHKfgGBoXVpiqOXnQYkDAu9g5OkOQaLIdNEWEdM1JHowPgda4qXXkNjW8LhDuEugv2y9uCZ_ohEajSUSeCjDe5TdbEYOywM2bDBpICqk8BSUE-FVdYU1fQYzA%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwZXh0bgNhZW0CMTEAAR2WBn_XF0M0stGHfpLDdAy__P1L8OuNSeUGYRh07HTlDqDkyP8qefFjNAY_aem_WSUL9XfeCs3mx_tXA_EUFw&h=AT0WmXmJ0XYKK40nKwKIrBZDArkrcM2N5YcR6dZtIQbNR6m_L3yhFyD_o_sWGCgJ_SX-6gjV20R-nZYgePD_tqjbSYawLKvcEh4Mo9uNrlMjgvcB5z4R0rPRseBiAzA&s=1' target="_blank" rel="noopener noreferrer" className='log'>
                <img src="icons/wp-logo.png" alt="" className='log'/>
            </a>
            </div>
            <div className="links-m">
                <div className="column">
                    <a href="/aboutus">Sobre nosotros</a>
                    <a href="credit">Solicitud de crédito</a>
                </div>
                <div className="column">
                    <a href="contact">Contáctenos</a>
                    <a href="avisos">Avisos</a>
                    <a href="politicas">Políticas y garantías</a>
                </div>
            </div>
            <p className='contact'>Llámenos (504) 9813-4939</p>
            <p className='contact-2'>© 2024 HonduAutos</p>
        </div>
    </>
  )
}

export default FooterMovil
