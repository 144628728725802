import React from 'react'
import "../style/MyCarBoletin.css"


function CarBoletin({carro, onSelectionChange}) {
  const backgroundurl = carro.imagenes[0]

  const handleCheckboxChange = (event) => {
    const isSelected = event.target.checked;
    onSelectionChange(carro, isSelected);
  };
  

  return (
    <>
        <div className="main-container-car">
          <div className="car-models-m">
          <p className="car-title-m">{carro.marca} {carro.modelo} {carro.anio}</p>
          <img src={backgroundurl} alt="" className='car-img'/>
          </div>
            <div className="info-car">
                <div className="info-info">
                <p className="car-title">{carro.marca} {carro.modelo} {carro.anio}</p>
                <p className="car-lote">Lote: {carro.lote}</p>
                <div className="especificaciones">
                    <p className="millaje"><strong>Millaje: </strong> {carro.millaje}</p>
                    <p className="motor"><strong>Motor: </strong> {carro.motor}</p>
                    <p className="combustible"><strong>Combustible: </strong> {carro.combustible}</p>
                </div>
                <div className="precios">
                    <p className="precio">Precio: L. <br />{carro.precio}</p>
                    <p className="precio-oferta">Precio en Oferta: L. <br />{carro.precioOferta}</p>
                </div>
                </div>
            </div>
            <div className="edit-btns-checkbox">
              <input type='checkbox' id='filtro1' className='checkbox-b'   onChange={handleCheckboxChange}/>
            </div>
        </div>
    </>
  )
}

export default CarBoletin