import React, { createContext, useState } from 'react';

  const StockContext = createContext();

  const StockProvider = ({ children }) => {
  const [openFilters, setOpenFilters] = useState(null);

  return (
    <StockContext.Provider value={{ openFilters, setOpenFilters }}>
      {children}
    </StockContext.Provider>
  );
};

export { StockContext, StockProvider };