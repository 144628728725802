import React from 'react';
import "../style/Compradores.css";

function Compradores({ user, onClick }) {
  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="main-container-compradores" onClick={onClick}>
      <p className="comprador-name">{user.nombre}</p>
      <p className="comprador-correo">{user.correo}</p>
      <p className="comprador-carros">{user.carroscomprados ? user.carroscomprados.length : 0}</p>
      <p className="comprador-saldo">{formatNumberWithCommas(user.saldo || 0)}</p>
    </div>
  );
}

export default Compradores;