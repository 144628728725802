import React from 'react';
import Navbar from '../Navbar.jsx';
import Foooter from '../Foooter.jsx';
import Footer from '../FooterMovil.jsx'
import '../../style/Contact.css'

function Contact() {
  return (
    <>
    <Navbar />
    <div className='honduautos-back-contact'>
        <div className='input-gray'>
            <p className='contactenos'>CONTÁCTENOS</p>
            <p className='contact-info'>Si desea realizar alguna consulta o comentario, 
            favor llenar <br/> el formulario siguiente; 
            para nosotros será un placer <br/> atenderle.</p>
            <p className='contact-info-m'>Si desea realizar alguna consulta o comentario, 
            favor llenar el formulario siguiente; atenderle.</p>
            <input type="text" placeholder='Nombre Completo' className='contact-form'/>
            <input type="text" placeholder='Correo electrónico' className='contact-form'/>
            <input type="tel" placeholder='Teléfono' className='contact-form' name='teléfono'/>
            <input type="text" placeholder='Comentario' className='contact-form-comentario'/>
            <input type="submit" className='button-send'/>
        </div>
        <div className="contact-card">
          <p className='contact-title'>OTROS CONTÁCTOS</p>
          <div className="row-card">
          <div className='colum-card'>
          <div className="contact-card-card">
              <img src="icons/phone-icon.png" alt="" className='icon-contact-card' />
              <p>(504) 9813-4939</p>
            </div>
            <div className="contact-card-card">
              <img src="icons/phone-icon.png" alt="" className='icon-contact-card' />
              <p>(504) 3214-4898</p>
            </div>
            <div className="contact-card-card">
              <img src="icons/email-icon.png" alt="" className='icon-contact-card-email' />
              <p>eugenia_ramirez@yahoo.com</p>
            </div>
          </div>

          <div className='icons-column'>
              <a href="https://www.instagram.com/honduautoscom?igsh=MTh1eHJpOHIydmVzOQ==" target="_blank" rel="noopener noreferrer">
                <img src="icons/insta-icon-dark.png" alt="" className='contact-logo' />
              </a>
              <a href='https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B50499700699%26context%3DARAbAC7ZPDUcgue8K6fARO2i3gW6YABlKSHKfgGBoXVpiqOXnQYkDAu9g5OkOQaLIdNEWEdM1JHowPgda4qXXkNjW8LhDuEugv2y9uCZ_ohEajSUSeCjDe5TdbEYOywM2bDBpICqk8BSUE-FVdYU1fQYzA%26source%3DFB_Page%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwZXh0bgNhZW0CMTEAAR2WBn_XF0M0stGHfpLDdAy__P1L8OuNSeUGYRh07HTlDqDkyP8qefFjNAY_aem_WSUL9XfeCs3mx_tXA_EUFw&h=AT0WmXmJ0XYKK40nKwKIrBZDArkrcM2N5YcR6dZtIQbNR6m_L3yhFyD_o_sWGCgJ_SX-6gjV20R-nZYgePD_tqjbSYawLKvcEh4Mo9uNrlMjgvcB5z4R0rPRseBiAzA&s=1' target="_blank" rel="noopener noreferrer">
                <img src="icons/whatsapp-icon-dark.png" alt="" className='contact-logo'/>
              </a>
              <a href='https://m.facebook.com/honduautos?mibextid=LQQJ4d' target="_blank" rel="noopener noreferrer">
                <img src="icons/facebook-icon-dark.png" alt="" className='contact-logo'/>
              </a>
          </div>

          </div>  
        </div>
    </div>
    <div className='web-footer'>
            <Foooter/>
        </div>
        <div className="movil-footer">
            <Footer/>
        </div>
    </>
  )
}

export default Contact