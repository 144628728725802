import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { DownloadOutlined } from '@ant-design/icons';

const CreditoNotification = ({ noti }) => {
  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const showDocuments = (doc) => {
    setSelectedDoc(doc);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedDoc(null);
  };

  const handleDownload = () => {
    if (selectedDoc) {
      const link = document.createElement('a');
      link.href = selectedDoc;
      link.download = selectedDoc.split('/').pop();
      link.click();
    }
  };

  const contact = async () => {
    const phoneNumber = noti.celular;
    const cleanedNumber = phoneNumber.replace("+", "");
    const message = "Buen dia soy parte del equipor de Honduautos tu autolote de confianza";
    const url = 'https://wa.me/' + cleanedNumber + '?text=' + encodeURIComponent(message)
    await window.open(url, '_blank')
  }

  return (
    <div className="column-content-n">
      <p className="from">De: {noti.nombre} {noti.apellido}</p>
      <p className="to">Para: ADMINISTRACION</p>
      <p className="notification-content">
        {noti.nombre} {noti.apellido} con antigüedad laboral de {noti.AntiguedadLaborar} desea comprar un {noti.CompraDeseada}, con vivienda en {noti.dirrecccion} posee ingresos mensuales de {noti.ingresosMes}. Reside en {noti.pais}, {noti.poseeVehiculo ? 'Sí' : 'No'} posee vehículo y su tipo de vivienda es {noti.tipoVivienda}.
        <br />
        <br />
        Puede contactarlo a: {noti.celular}
      </p>
      {noti.documentos.length > 0 && (
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {noti.documentos.map((doc, index) => (
            <Button
              key={index}
              onClick={() => showDocuments(doc.url)}
              style={{
                margin: '10px 5px',
                backgroundColor: 'transparent',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src="/icons/pdf.png" alt="PDF" style={{ maxWidth: '24px', height: '24px', marginRight: '5px' }} />
              <span style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {doc.name}
              </span>
            </Button>
          ))}
        </div>
      )}
      <div className="buttons-notification">
        <button className="button-n" onClick={contact}>Contactarse con el solicitante</button>
      </div>
      <Modal
        title={
          <div>
            Documentos
            <Button onClick={handleDownload} style={{ float: 'right', marginRight: '3%', top: '0' }}>
              Descargar <DownloadOutlined />
            </Button>
          </div>
        }
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        {selectedDoc && (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <div
              style={{
                height: '750px',
              }}
            >
              <Viewer fileUrl={selectedDoc} />
            </div>
          </Worker>
        )}
      </Modal>
    </div>
  );
};

export default CreditoNotification;
