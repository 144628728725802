import React from 'react';
import "../style/Notification.css";

function Notification({ noti, onDelete, isSelected, onSelect }) {
    return (
        <div className={`main-container-notification ${isSelected ? 'selected' : ''}`} onClick={onSelect}>
            <div className="notificationinfo">
                <img src="icons/campana-dark.png" alt="" className='notification-img' />
                <div className="notification-info">
                    <p className="main-title-notification">Notificación de {noti.Tipo}</p>
                    <p className='notification-info-overview'> {noti.titulo}</p>
                </div>
            </div>
            <button className='delete-noti' onClick={(e) => { e.stopPropagation(); onDelete(noti._id); }}><strong>x</strong></button>
        </div>
    );
}

export default Notification;
