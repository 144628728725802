import React from "react";
import "../style/ModalDelete.css";

function ModalDelete({ show, onClose, onConfirm }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Confirmar Eliminación</h2>
        <p>¿Seguro que quieres eliminar esta imagen?</p>
        <button className="btn-confirm" onClick={onConfirm}>
          Confirmar
        </button>
        <button className="btn-cancel" onClick={onClose}>
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default ModalDelete;
