import React, { useEffect } from 'react';
import "../style/UploadWidget.css"

const UploadWidget = ({ onUploadSuccess }) => {
  useEffect(() => {
    window.cloudinary.setCloudName('dlpsbcfs4'); // Reemplaza 'your-cloud-name' con tu Cloud Name de Cloudinary
  }, []);

  const handleUpload = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: 'dlpsbcfs4', // Reemplaza 'your-cloud-name' con tu Cloud Name de Cloudinary
        uploadPreset: 'i2cyl86j', // Reemplaza 'your-upload-preset' con tu Upload Preset de Cloudinary
        sources: ['local', 'url', 'camera'],
        multiple: true
      },
      (error, result) => {
        if (result.event === 'success') {
          onUploadSuccess(result.info.secure_url); // Devuelve la URL segura de la imagen subida
        }
      }
    );
  };

  return (
    <button type='button' className='widget-upload-btn' onClick={handleUpload}>
      Subir Imagen
    </button>
  );
};

export default UploadWidget;
