import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import UploadWidget from "../UploadWidget";
import AddElement from "../AddElement";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import ModalDelete from "../ModalDelete";
import "../../style/AddCar.css";
import { useAuth } from "../../context/AuthContext.js";
import LoadingBar from 'react-top-loading-bar';
import { notification } from 'antd';

function AddCar() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [username, setUsername] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedBrandID, setSelectedBrandID] = useState("");
  const [selectedUBI, setSelectedUBI] = useState("");
  const [selectedgastype, setSelectedgastype] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedTrans, setSelectedTrans] = useState("");
  const [selectedTrac, setSelectedTrac] = useState("");
  const [Link, setLink] = useState("");
  const [Link2, setLink2] = useState("");
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const showErrorNotification = (message) => {
    notification.error({
      message: 'Error de validación',
      description: message,
      placement: 'topRight',
    });
  };

  const validateFields = () => {
    if (images.length === 0) {
      showErrorNotification("Hace falta foto.");
      return false;
    }
    if (!selectedBrand) {
      showErrorNotification("Hace falta seleccionar una marca.");
      return false;
    }
    if (!selectedModel) {
      showErrorNotification("Hace falta seleccionar un modelo.");
      return false;
    }
    if (!inputValues.anio) {
      showErrorNotification("Hace falta ingresar el año.");
      return false;
    }
    if (!inputValues.millaje) {
      showErrorNotification("Hace falta ingresar el millaje.");
      return false;
    }
    if (!inputValues.precio) {
      showErrorNotification("Hace falta ingresar el precio.");
      return false;
    }
    if (!selectedUBI) {
      showErrorNotification("Hace falta seleccionar la ubicación.");
      return false;
    }
    if (!selectedCondition) {
      showErrorNotification("Hace falta seleccionar la condición.");
      return false;
    }
    if (!inputValues.motor) {
      showErrorNotification("Hace falta ingresar el motor.");
      return false;
    }
    if (!selectedgastype) {
      showErrorNotification("Hace falta seleccionar el tipo de combustible.");
      return false;
    }
    if (!selectedTrans) {
      showErrorNotification("Hace falta seleccionar la transmisión.");
      return false;
    }
    if (!selectedTrac) {
      showErrorNotification("Hace falta seleccionar la tracción.");
      return false;
    }
    if (!selectedType) {
      showErrorNotification("Hace falta seleccionar el tipo de vehículo.");
      return false;
    }
    if (!inputValues.lote) {
      showErrorNotification("Hace falta ingresar el lote.");
      return false;
    }
    return true;
  };
  

  const { authToken, logout } = useAuth();
  const navigate = useNavigate();

  const togglePop = () => setShowPop(!showPop);
  const togglePopup = () => setShowPopup(!showPopup);

  const handleSelectMainImage = (url) => {
    setMainImage(url);
  };

  const handleDeleteImage = (url) => {
    setImageToDelete(url);
    setShowDeleteModal(true);
  };

  const confirmDeleteImage = () => {
    setImages((prevImages) =>
      prevImages.filter((image) => image !== imageToDelete)
    );
    if (mainImage === imageToDelete) {
      setMainImage(images[0] || null);
    }
    setShowDeleteModal(false);
    setImageToDelete(null);
  };

  const cancelDeleteImage = () => {
    setShowDeleteModal(false);
    setImageToDelete(null);
  };

  const getMarcas = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    setMarcas(res.data);
  };

  const getuser = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
      { headers: { Authorization: "bearer " + authToken } }
    );
    setUsername(response.data.nombre);
  };

  useEffect(() => {
    const inicio = async () => {
      await getMarcas();

      if (!authToken) {
        navigate("/");
      } else {
        await getuser();
      }
    };

    inicio();
  }, [authToken, navigate]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const gastypes = ["Gasolina", "Disel", "N/A"];
  const typecars = [
    "Camioncito",
    "Pick Up",
    "Sedan",
    "Camioneta",
    "Cabezal",
    "Bus",
    "Camion",
    "Motocicleta",
    "Varios"
  ];
  const transmisiones = ["Automatica", "Mecánica", "N/A"];
  const tracciones = ["Delantera", "Trasera", "4x4", "N/A"];
  const condiciones = ["Chocado", "Arreglado", "Buen Estado"];
  const Ubications = [
    "USA",
    "Puerto Cortez",
    "Tegucigalpa",
    "Transito",
    "San Lorenzo",
  ];

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    setModelos(res.data);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    const result = marcas.filter(
      (marca) => marca.Nombre === event.target.value
    );
    setSelectedBrandID(result[0]._id);
    getmodelos(result[0]._id);
  };

  const HandleBrandChangeUpdate = async (Brand) => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    const brands = res.data;
    const result = brands.filter((brand) => brand.Nombre === Brand);
    setSelectedBrandID(result[0]._id);
    getmodelos(result[0]._id);
  };

  const handleModelChange = (event) => setSelectedModel(event.target.value);
  const handleTransChange = (event) => setSelectedTrans(event.target.value);
  const handleTracChange = (event) => setSelectedTrac(event.target.value);
  const handleConditionChange = (event) =>
  setSelectedCondition(event.target.value);
  const handleTypeChange = (event) => setSelectedType(event.target.value);
  const handleUBIChange = (event) => setSelectedUBI(event.target.value);
  const handleGasChange = (event) => setSelectedgastype(event.target.value);
  const handleURIChange = (event) => setLink(event.target.value);
  const handleURIChange2 = (event) => setLink2(event.target.value);

  const handleUploadSuccess = (url) => {
    setImages((prevImages) => [...prevImages, url]);
    setImageUrls((prevImages) => [...prevImages, url]);
    if (images.length === 0) {
      setMainImage(url);
    }
  };

  const [inputValues, setInputValues] = useState({
    marca: "",
    modelo : "",
    millaje: "",
    precio: "",
    motor: "",
    anio: "",
    comentario: "",
    enlace: "",
    listado: "",
    precioOferta: "",
    lote: "",
  });

  const [marcainput, setMarca] = useState("");
  const [modeloinput, setModelo] = useState("");

  const handlemarcaChange = (event) => setMarca(event.target.value);
  const handlemodeloChange = (event) => setModelo(event.target.value);

  const [checkboxes, setCheckboxes] = useState({
    iaai: false,
    copart: false,
    grua: false,
    costosintro: false,
    enciende: false,
    camina: false,
    costosvias: false,
    costosins: false,
    placas: false,
    destacado: false,
    inscripcion: false,
  });

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({ ...prevCheckboxes, [name]: checked }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validar los campos antes de proceder

  if(selectedType !== 'Varios'){
    if (!validateFields()) {
      return;
    }
  }
  

    const filteredArray = imageUrls.filter(
      (item) =>
        item !== null &&
        item !== undefined &&
        item !== "" &&
        !(Array.isArray(item) && item.length === 0)
    );

    
    const carData = {
      ...inputValues,
      motor: inputValues.motor || "0",
      lote: inputValues.lote || "0",
      imagenes: filteredArray,
      condicion: selectedCondition,
      marca: selectedBrand || inputValues.marca,
      modelo: selectedModel || inputValues.modelo || '-',
      tipo: selectedType,
      traccion: selectedTrac,
      transmission: selectedTrans,
      ubicacion: selectedUBI,
      combustible: selectedgastype,
      millaje: parseInt(inputValues.millaje) || 0,
      anio: parseInt(inputValues.anio),
      precio: parseInt(inputValues.precio),
      camina: checkboxes.camina,
      Introduccion: checkboxes.costosintro,
      enciende: checkboxes.enciende,
      viasPublicas: checkboxes.costosvias,
      gruaIncluida: checkboxes.grua,
      costosPlacas: checkboxes.placas,
      destacado: checkboxes.destacado,
      gastosDeInscripcion: checkboxes.costosins,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKENDIP}carro/`, carData, {headers: {Authorization: `bearer ${authToken}`,},});
      navigate("/allcarsa");
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const handleMarcaAdd = async () => {
    const datamarca = { Nombre: marcainput, Imagen: images[0] };
    await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca",
      datamarca
    );
    setImageUrls([]);
    setImages([]);
    setMarca("");
    setSelectedBrand("");
    togglePopup();
    getMarcas();
  };

  const handleModeloAdd = async () => {
    const datamodelo = { Nombre: modeloinput, Marca: selectedBrandID };
    await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelo",
      datamodelo
    );
    togglePop();
    getmodelos(selectedBrandID);
    setSelectedModel("");
  };

  function convertToCC(input) {
    const match = input.match(/(\d+(\.\d+)?)L/);
    if (match) {
        const liters = parseFloat(match[1]);
        const cc = liters * 1000;
        return cc;
    } else {
        throw new Error("Input string does not match the expected format");
    }
  }


  const handleWebScrape = async () => {
    if (checkboxes.iaai) {
      setLoading(true);
      ref.current.continuousStart(); // Start loading bar
      try {
        const cardata = await axios.post(
           process.env.REACT_APP_BACKENDIP + "webscraping/iaai",
          {
            identifier: Link,
            identifier2: Link2,
          }
        );
        setImages(cardata.data.imagenes)
        setImageUrls(cardata.data.imagenes);
        setSelectedBrand(capitalizeFirstLetter(cardata.data.marca));
        await HandleBrandChangeUpdate(capitalizeFirstLetter(cardata.data.marca));
        setInputValues({
          anio: cardata.data.anio,
          lote: cardata.data.lote,
          enlace: cardata.data.Link360
        });
      } catch (error) {
        console.log(error)
        notification.error({
          message: 'Error al realizar web scraping en IAAI',
          description: 'Ocurrió un error al intentar realizar el web scraping en IAAI. Por favor, inténtelo de nuevo más tarde.',
        });
      } finally {
        setLoading(false);
        ref.current.complete(); // Complete loading bar
      }
    }
    if (checkboxes.copart) {
      try {
        setLoading(true);
        ref.current.continuousStart();
        const data = {
          identifier: Link
        }
        const cardata = await axios.post(process.env.REACT_APP_BACKENDIP + "webscraping/copartInit",data);
        const odometroValue = (cardata.data.datos.odometro).split(' ')
        const CCvalue = convertToCC(cardata.data.datos.motor)
        setImages(cardata.data.imagenes)
        setInputValues({
          millaje: odometroValue[0],
          motor: CCvalue.toString(),
          anio: cardata.data.datos.anio,
          lote: cardata.data.datos.lotnum,
        });
        setImageUrls(cardata.data.imagenes);
        setSelectedBrand(capitalizeFirstLetter(cardata.data.datos.marca));
        await HandleBrandChangeUpdate(capitalizeFirstLetter(cardata.data.datos.marca));
      } catch (error) {
        console.log(error);
        notification.error({
          message: 'Error al realizar web scraping en Copart',
          description: 'Ocurrió un error al intentar realizar el web scraping en Copart. Por favor, inténtelo de nuevo más tarde.',
        });
      } finally {
        setLoading(false);
        ref.current.complete(); // Complete load
      }
    }
  };
  

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-add">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/banner-white.png"
                  alt=""
                  className="element-img"
                />
                <a href="/banner" className="element-text">
                  Banner
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/campana.png"
                  alt=""
                  className="element-img"
                />
                <a href="/notificationsadmin" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-all">
          <div
              ref={dropDownRef}
              className="whole-drop-a"
            >
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img-d" />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/sum.png" alt="" className="element-img-d" />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/notification.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/notificationsadmin" className="element-text-d">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Agregar Auto</p>
            <form onSubmit={handleSubmit} className="form-container-add">
              <div className="lote">
                <div className="link-display">
                  {checkboxes.iaai ? (
                    <div className="twolinksbox">
                      <input
                        type="text"
                        name="Link"
                        value={Link}
                        onChange={handleURIChange}
                        id="Link"
                        className="sell-input"
                        placeholder="Link"
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      name="Link"
                      value={Link}
                      onChange={handleURIChange}
                      id="Link"
                      className="sell-input"
                      placeholder="Link"
                    />
                  )}
                  {checkboxes.iaai ? (
                    <div className="twolinksbox">
                      <input
                        type="text"
                        name="Link2"
                        value={Link2}
                        onChange={handleURIChange2}
                        id="Link2"
                        className="sell-input-2"
                        placeholder="Link2"
                      />
                    </div>
                  ) : null}
                </div>
                <button
                  type="button"
                  className="fire"
                  onClick={handleWebScrape}
                >
                  <img
                    src="images/fire-icon.png"
                    alt=""
                    className="fire-button"
                  />
                </button>
                <div className="span-filter">
                  <input
                    type="checkbox"
                    className="check-add"
                    name="iaai"
                    checked={checkboxes.iaai}
                    onChange={handleCheckboxChange}
                  />
                  IAAI
                </div>
                <div className="span-filter">
                  <input
                    type="checkbox"
                    className="check-add"
                    name="copart"
                    checked={checkboxes.copart}
                    onChange={handleCheckboxChange}
                  />
                  Copart
                </div>
              </div>
              <div className="links">
              <LoadingBar color="#800080" ref={ref} className="top-loading-bar" />                
              <div className="widget">
                  <UploadWidget onUploadSuccess={handleUploadSuccess} />
                </div>
                <div className="images-container-cloudinary">
                  {images.length > 0 ? (
                    <>
                      <div className="main-image-container">
                        <img
                          src={mainImage || images[0]}
                          alt="Main Uploaded"
                          className="main-uploaded-image"
                        />
                      </div>
                      <div className="thumbnail-container">
                        {images.map((url, index) => (
                          <div
                            key={index}
                            className="thumbnail-image-container"
                          >
                            <img
                              src={url}
                              alt={`Uploaded ${index}`}
                              className="thumbnail-uploaded-image"
                              onClick={() => handleSelectMainImage(url)}
                              style={{
                                cursor: "pointer",
                                border:
                                  mainImage === url ? "2px solid blue" : "none",
                              }}
                            />
                            <button
                              onClick={() => handleDeleteImage(url)}
                              type="button"
                              className="delete-button"
                            >
                              ‎
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p>No images uploaded</p>
                  )}
                </div>
              </div>
              <div className="grid-sell">
                <div className="columns">
                  <div className="add-elements">
                    { !(selectedType == 'Varios') ? (
                      <select
                      name="marca"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                      id="marca"
                    >
                      <option value="">Marca</option>
                      {marcas.map((marca) => (
                        <option key={marca._id} value={marca.Nombre}>
                          {marca.Nombre}
                        </option>
                      ))}
                    </select>
                    ) : (
                      <input
                        name="marca"
                        type="text"
                        placeholder="Marca"
                        value={inputValues.marca}
                        onChange={handleInputChange}
                      />
                    )
                    }
                    {!(selectedType == 'Varios') && (<button
                      type="button"
                      className="add-element-btn"
                      onClick={togglePopup}
                    >
                      <img
                        src="icons/add.png"
                        alt=""
                        className="add-element-img"
                      />
                    </button>)}
                    <AddElement show={showPopup} handleClose={togglePopup}>
                      <div className="elements-popup">
                        <p className="popup-title">Agregar Nueva Marca</p>
                        <input
                          type="text"
                          name="marcainput"
                          id="marcainput"
                          onChange={handlemarcaChange}
                          className="popup-input"
                          placeholder="Escribir la nueva marca"
                        />
                        <UploadWidget onUploadSuccess={handleUploadSuccess} />
                        <button
                          onClick={handleMarcaAdd}
                          type="button"
                          className="popup-btn"
                        >
                          AGREGAR MARCA
                        </button>
                      </div>
                    </AddElement>
                  </div>
                  <input
                    name="millaje"
                    type="text"
                    placeholder="Millaje"
                    value={inputValues.millaje}
                    onChange={handleInputChange}
                  />
                  <select
                    name="condicion"
                    value={selectedCondition}
                    onChange={handleConditionChange}
                    id="condicion"
                  >
                    <option value="">Condicion</option>
                    {condiciones.map((condicion) => (
                      <option key={condicion} value={condicion}>
                        {condicion}
                      </option>
                    ))}
                  </select>
                  <select
                    name="transmision"
                    value={selectedTrans}
                    onChange={handleTransChange}
                    id="transmision"
                  >
                    <option value="">Transmisión</option>
                    {transmisiones.map((trans) => (
                      <option key={trans} value={trans}>
                        {trans}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columns">
                  <div className="add-elements">
                    {!(selectedType == 'Varios') ? (<select
                      name="modelo"
                      value={selectedModel}
                      onChange={handleModelChange}
                      id="modelo"
                    >
                      <option value="">Modelo</option>
                      {modelos.map((modelo) => (
                        <option key={modelo._id} value={modelo.Nombre}>
                          {modelo.Nombre}
                        </option>
                      ))}
                    </select>) : (
                      <input
                        name="modelo"
                        type="text"
                        placeholder="Modelo"
                        value={inputValues.modelo}
                        onChange={handleInputChange}
                      />
                    )}
                    {!(selectedType == 'Varios') && (<button
                      type="button"
                      className="add-element-btn"
                      onClick={togglePop}
                    >
                      <img
                        src="icons/add.png"
                        alt=""
                        className="add-element-img"
                      />
                    </button>)}
                    <AddElement show={showPop} handleClose={togglePop}>
                      <div className="elements-popup">
                        <p className="popup-title">Agregar Nuevo Modelo</p>
                        <input
                          type="text"
                          name="modeloinput"
                          id="modeloinput"
                          onChange={handlemodeloChange}
                          className="popup-input"
                          placeholder="Escribir el nuevo modelo"
                        />
                        <button
                          onClick={handleModeloAdd}
                          type="button"
                          className="popup-btn"
                        >
                          AGREGAR MODELO
                        </button>
                      </div>
                    </AddElement>
                  </div>
                  <input
                    name="precio"
                    type="text"
                    placeholder="Precio"
                    value={inputValues.precio}
                    onChange={handleInputChange}
                  />
                  <input
                    name="motor"
                    type="text"
                    placeholder="Motor"
                    value={inputValues.motor}
                    onChange={handleInputChange}
                  />
                  <select
                    name="traccion"
                    value={selectedTrac}
                    onChange={handleTracChange}
                    id="traccion"
                  >
                    <option value="">Tracción</option>
                    {tracciones.map((trac) => (
                      <option key={trac} value={trac}>
                        {trac}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columns">
                  <input
                    name="anio"
                    type="text"
                    placeholder="Año"
                    value={inputValues.anio}
                    onChange={handleInputChange}
                  />
                  <select
                    name="ubicacion"
                    value={selectedUBI}
                    onChange={handleUBIChange}
                    id="ubicacion"
                  >
                    <option value="">ubicacion</option>
                    {Ubications.map((ubication) => (
                      <option key={ubication} value={ubication}>
                        {ubication}
                      </option>
                    ))}
                  </select>
                  <select
                    name="combustible"
                    value={selectedgastype}
                    onChange={handleGasChange}
                    id="combustible"
                  >
                    <option value="">Combustible</option>
                    {gastypes.map((gastype) => (
                      <option key={gastype} value={gastype}>
                        {gastype}
                      </option>
                    ))}
                  </select>
                  <select
                    name="tipo"
                    value={selectedType}
                    onChange={handleTypeChange}
                    id="tipo"
                  >
                    <option value="">Tipo</option>
                    {typecars.map((tipo) => (
                      <option key={tipo} value={tipo}>
                        {tipo}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columns">
                  <input
                    name="lote"
                    type="text"
                    placeholder="lote"
                    value={inputValues.lote}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="grid-sell-last">
                <div className="columns-2">
                  <input
                    name="comentario"
                    type="text"
                    placeholder="Comentario"
                    value={inputValues.comentario}
                    onChange={handleInputChange}
                  />
                  <input
                    name="enlace"
                    type="text"
                    placeholder="Agregue enlace 306"
                    value={inputValues.enlace}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="columns-2">
                  <input
                    name="listado"
                    type="text"
                    placeholder="Listado"
                    value={inputValues.listado}
                    onChange={handleInputChange}
                  />
                  <input
                    name="precioOferta"
                    type="text"
                    placeholder="Precio Oferta"
                    value={inputValues.precioOferta}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="grid-sell-checks">
                <div className="columnsdown">
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="grua"
                      checked={checkboxes.grua}
                      onChange={handleCheckboxChange}
                    />
                    Grua incluida
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="costosintro"
                      checked={checkboxes.costosintro}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Introduccion
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="costosins"
                      checked={checkboxes.costosins}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Inscripcion
                  </div>
                </div>
                <div className="columnsdown">
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="costosvias"
                      checked={checkboxes.costosvias}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Vias Publicas
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="destacado"
                      checked={checkboxes.destacado}
                      onChange={handleCheckboxChange}
                    />
                    Destacado
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="enciende"
                      checked={checkboxes.enciende}
                      onChange={handleCheckboxChange}
                    />
                    Enciende
                  </div>
                </div>
                <div className="columnsdown">
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="placas"
                      checked={checkboxes.placas}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Placas
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="camina"
                      checked={checkboxes.camina}
                      onChange={handleCheckboxChange}
                    />
                    Camina
                  </div>
                </div>
              </div>

              <center>
                <button type="submit" className="add-car">
                  AGREGAR AUTO
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
      <div className="footer">
      <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
      <ModalDelete
        show={showDeleteModal}
        onClose={cancelDeleteImage}
        onConfirm={confirmDeleteImage}
      />
    </>
  );
}

export default AddCar;
