import React, { useState, useEffect, useRef } from "react";
import "../style/MenuStock.css";
import CarOverview from "./carOverviewNotSpecial";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
import FilterBox from "./Filterbox.jsx";
import { Spin } from "antd"; // Importando el componente de loading de Ant Design

function MenuStock({ reload ,activeOption }) {

   // Estado inicial de los checkboxes
   const initialCheckboxes = {
    chocados: false,
    arreglados: false,
    bus: false,
    cabezal: false,
    camioncito: false,
    camion: false,
    pickup: false,
    camioneta: false,
    turismo: false,
    gasolina: false,
    diesel: false,
    automatica: false,
    manual: false,
    delantera: false,
    trasera: false,
    ruedas: false,
    Usa: false,
    Tegucigalpa: false,
    SanLorenzo: false,
    Transito: false,
    Puerto: false,
  };

  const [Filtered, setFilter] = useState(false);
  const [marcas, setmarcas] = useState([]); 
  const [fullmarca, setfullmarca] = useState([]);
  const [modelos, setmodelos] = useState([]); 

  const getMarcas = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    const names = res.data.map((carro) => carro.Nombre);
  
    setmarcas(names);  // Set fetched brand names separately
    setfullmarca(res.data);  // Set full brand data separately
  
    // Do not update checkboxes during initialization
  };

  useEffect(() => {
    // After `marcas` is fetched, initialize the checkbox state
    if (marcas.length > 0) {
      const updatedCheckboxes = { ...initialCheckboxes };
  
      // Populate checkboxes for brands without triggering filter logic
      marcas.forEach((marca) => {
        updatedCheckboxes[marca] = false;
      });
  
      // Update the checkbox state
      setCheckboxes(updatedCheckboxes);
      
      // Mark initialization as complete
      setIsInitializing(false);
    }
  }, [marcas]);  // This runs only when `marcas` is updated
  
  

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    const modelos = res.data;
    const nombres = modelos.map((modelos) => modelos.Nombre);
    setmodelos(nombres);

    // Update checkboxes state to include the models
    setCheckboxes((prevCheckboxes) => {
      const newCheckboxes = { ...prevCheckboxes };
      nombres.forEach((modelo) => {
        if (!(modelo in newCheckboxes)) {
          newCheckboxes[modelo] = false;
        }
      });
      return newCheckboxes;
    });
  };

  const [showFilters, setShowFilters] = useState(window.innerWidth > 1024);
  const [checkboxes, setCheckboxes] = useState({});

  const [sliderValue, setSliderValue] = useState(1.0);
  const [carprice, setcarprice] = useState(null);
  const [caryr, setcaryr] = useState(null);
  const [carmotor, setcarmotor] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null); // Nuevo estado para el filtro activo

  const [isModelMenuVisible, setIsModelMenuVisible] = useState(false);

  const findObjectByKeyValue = (array, key, value) => {
    return array.find((obj) => obj[key] === value);
  };

  const [checkboxchange, setcheckboxchange] = useState(false);


  const handleCheckboxChange = (event) => {
    setisfiltered(true);
    setcheckboxchange(!checkboxchange)
    const { name, checked } = event.target;

    if (marcas.includes(name)) {
      // Es una marca
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [name]: checked,
      }));

      if (checked) {
        setIsModelMenuVisible(true);
        const algo = findObjectByKeyValue(fullmarca, "Nombre", name);
        getmodelos(algo._id);

        // Reinicia los modelos cuando se selecciona una nueva marca
        setCheckboxes((prevCheckboxes) => {
          const newCheckboxes = { ...prevCheckboxes };
          modelos.forEach((modelo) => {
            newCheckboxes[modelo] = false;
          });
          return newCheckboxes;
        });
      } else {
        setIsModelMenuVisible(false);
        setmodelos([]);

        // Reinicia los modelos cuando se deselecciona la marca
        setCheckboxes((prevCheckboxes) => {
          const newCheckboxes = { ...prevCheckboxes };
          modelos.forEach((modelo) => {
            delete newCheckboxes[modelo];
          });
          return newCheckboxes;
        });
      }
    } else if (modelos.includes(name)) {
      // Es un modelo
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [name]: checked,
      }));
    } else {
      // Es otro filtro
      setCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [name]: checked,
      }));
    }
  };

  // Función para resetear filtros
  const handleResetFilters = () => {
    setCheckboxes({ ...initialCheckboxes });
    setcarprice(null);
    setcarmotor(null);
    setcaryr(null);
    setIsModelMenuVisible(false);
    setmodelos([]);
  };

  const isAnyFilterActive = () => {
    const anyCheckboxChecked = Object.keys(checkboxes).some(
      (key) => checkboxes[key]
    );
    const anySliderValue = sliderValue !== 1.0 || carprice || caryr || carmotor;
    return anyCheckboxChecked || anySliderValue;
  };

  const getSelectedModels = () => {
    return modelos.filter((modelo) => checkboxes[modelo]);
  };

  const handleModeloChange = (event) => {
    const { value } = event.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      modelo: value, // Guarda el modelo seleccionado
    }));
    setisfiltered(true);
  };

  const handleSliderChange1 = (event, newValue) => {
    setisfiltered(true);
    setSliderValue(newValue);
    setcaryr(newValue);
  };

  const handleSliderChange2 = (event, newValue) => {
    setisfiltered(true);
    setSliderValue(newValue);
    setcarprice(newValue);
  };

  const handleSliderChange3 = (event, newValue) => {
    setisfiltered(true);
    setSliderValue(newValue);
    setcarmotor(newValue);
  };

  const toggleFilter = (filterName) => {
    setActiveFilter(activeFilter === filterName ? null : filterName); // Activa o desactiva el filtro
  };

  const location = useLocation();

  const getSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (let [key, value] of searchParams) {
      if (key === "anio") {
        params[key] = parseInt(value, 10);
        if (isNaN(params[key])) {
          params[key] = null;
        }
      } else {
        params[key] = value;
      }
    }
    return params;
  };

  const searchParams = getSearchParams();

  const findFirstTrue = (checkboxes) => {
    for (const key in checkboxes) {
      if (checkboxes[key]) {
        return key;
      }
    }
    return null;
  };

  function removeNulls(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        removeNulls(obj[key]); // Recurse into nested objects
      } else if (obj[key] === null) {
        delete obj[key]; // Delete keys where the value is null
      }
    });
    return obj;
  }

  const filterquery = () => {
    setFilter(true);

    const selectedModels = getSelectedModels();

    const brandCheckboxValues = {};
    marcas.forEach((marca) => {
      brandCheckboxValues[marca] = checkboxes[marca];
    });

    console.log("PuertoCortes: " +checkboxes.Puerto)

    const query = {
      marca: findFirstTrue(brandCheckboxValues),
      modelo: selectedModels.length > 0 ? selectedModels : null,
      condicion: findFirstTrue({
        Chocado: checkboxes.chocados,
        "Buen estado": checkboxes.arreglados,
      }),
      combustible: findFirstTrue({
        Gasolina: checkboxes.gasolina,
        Diesel: checkboxes.diesel,
      }),
      ubicacion: findFirstTrue({
        USA: checkboxes.Usa,
        Tegucigalpa: checkboxes.Tegucigalpa,
        "San Lorenzo": checkboxes.SanLorenzo,
        "Puerto Cortez": checkboxes.Puerto,
        Transito: checkboxes.Transito,
      }),
      transmission: findFirstTrue({
        "Automática": checkboxes.automatica,
        "Mecánica": checkboxes.manual,
      }),
      traccion: findFirstTrue({
        Delantera: checkboxes.delantera,
        Trasera: checkboxes.trasera,
        "4x4": checkboxes.ruedas,
      }),
      tipo: findFirstTrue({
        Sedan: checkboxes.turismo,
        Camioneta: checkboxes.camioneta,
        "Pick Up": checkboxes.pickup,
        "Camión": checkboxes.camion,
        Camioncito: checkboxes.camioncito,
        Cabezal: checkboxes.cabezal,
        Bus: checkboxes.bus,
      }),
      precio: carprice,
      motor: (carmotor * 1000).toString(),
      anio: caryr,
    };

    const cleanedQuery = removeNulls(query);

    const fetchCarrosFilter = async () => {
      setisfiltered(true);
      setLoading(true);
      setCarros([]);

      try {
        const filteredRes = await axios.post(
          process.env.REACT_APP_BACKENDIP + "filter/",
          cleanedQuery
        );
        setCarros(filteredRes.data.reverse());
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setCarros([]);
      }
      setLoading(false);
    };
    fetchCarrosFilter();
  };

  const removeFilters = () => {
    const fetchAllCarros = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKENDIP + "carro"
        );
        setCarros(response.data.reverse());
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
        setCarros([]);
      }
      setLoading(false);
    };
    fetchAllCarros();
    setcarprice(null);
    setcarmotor(null);
    setcaryr(null);
    setFilter(false);
  };

  const [carros, setCarros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otherloading, setotherloading] = useState(false);
  const [error, setError] = useState(null);
  const [firstrender, setfirstrender] = useState(true);
  const { authToken, logout } = useAuth();
  const carboxRef = useRef(null);

  const [isfiltered, setisfiltered] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [off, setoff] = useState(0);

  const fetchItems = async () => {
    if (!hasMore || otherloading) {
    }
    setotherloading(true);

    setoff((prevOff) => prevOff + 30);

    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + `findspecial/paginated/${off}`
      );
      setCarros((prevItems) => [...prevItems, ...response.data.carros]);
      setHasMore(response.data.hasMore);
    } catch (error) {
      setError("Failed to fetch data");
      console.error("Failed to fetch items", error);
    } finally {
      setotherloading(false);
    }
  };

  const fetchItemsInit = async () => {
    if (!hasMore || otherloading) {
      return;
    }

    setLoading(true);

    setoff((prevOff) => prevOff + 30);

    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + `findspecial/paginated/${off}`
      );
      setCarros((prevItems) => [...prevItems, ...response.data.carros]);
      setHasMore(response.data.hasMore);
    } catch (error) {
      setError("Failed to fetch data");
      console.error("Failed to fetch items", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCarrosType = async () => {
    if (loading || otherloading) {
      return;
    }

    setLoading(true);
    try {
      setCarros([]);
      setisfiltered(true);
      const filteredResTIPO = await axios.post(
        process.env.REACT_APP_BACKENDIP + "filter/",
        searchParams
      );
      setCarros((filteredResTIPO.data).reverse());
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]);
    } finally {
      setLoading(false);
    }
  };

  const hasSearchParams = Object.keys(searchParams).length > 0;

  const hasMounted = useRef(false);

  const [isInitializing, setIsInitializing] = useState(true);  

  useEffect(() => {
    getMarcas();  // Fetch brand names on mount
  }, []);  // Empty dependency array ensures it runs only once
  
  useEffect(() => {
    if (!hasMounted.current) {
      if (!hasSearchParams) {
        fetchItemsInit();
      }
      if (hasSearchParams) { 
        fetchCarrosType();
      }

      hasMounted.current = true;
    }
  }, [reload]);

  useEffect(() => {
      if (hasSearchParams) { 
        fetchCarrosType();
      }
  }, [reload]);

  const handleScroll = () => {
    if (carboxRef.current) {
      const scrollTop = carboxRef.current.scrollTop;
      const scrollHeight = carboxRef.current.scrollHeight;
      const clientHeight = carboxRef.current.clientHeight;
  
      const threshold = 150;
  
      if (scrollTop + clientHeight >= scrollHeight - threshold && hasMore) {
        if (!isfiltered) {
          setotherloading(true);
        }
      }
    }
  };

  useEffect(() => {
    if (otherloading && !isfiltered) {
      fetchItems();
    }
  }, [otherloading, isfiltered]);

  useEffect(() => {
    const carbox = carboxRef.current;
    if (carbox) {
      carbox.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (carbox) {
        carbox.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore]);

  useEffect(() => {
    if (isfiltered && !isInitializing) {
      filterquery();
    }
  }, [checkboxchange,sliderValue]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <div className="stock-container">
        <div className="column-s">
          <button className="btn-toggle-filters" onClick={toggleFilters}>
            {showFilters ? "Ocultar filtros" : "Mostrar filtros"}
          </button>
          <FilterBox
            toggleFilter={toggleFilter}
            activeOption={activeOption}
            checkboxes={checkboxes}
            handleCheckboxChange={handleCheckboxChange}
            handleModeloChange={handleModeloChange}
            sliderValue={sliderValue}
            handleSliderChange1={handleSliderChange1}
            handleSliderChange2={handleSliderChange2}
            handleSliderChange3={handleSliderChange3}
            isVisible={showFilters}
            filterquery={filterquery}
            activeFilter={activeFilter} // Pasa el filtro activo
            isModelMenuVisible={isModelMenuVisible}
            marcas={marcas}
            modelos={modelos}
            handleResetFilters={handleResetFilters}
            isAnyFilterActive={isAnyFilterActive}
          />
        </div>
        <div className="carbox" ref={carboxRef}>
          {loading ? (
            <div className="loading-overlay-stock">
              <Spin size="large" />
            </div>
          ) : carros.length > 0 ? (
            carros.map((carro) => <CarOverview key={carro._id} carro={carro} />)
          ) : (
            <div className="no-cars-message">
              No hay vehículos disponibles con los filtros seleccionados.
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MenuStock;
