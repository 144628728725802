import React, {useEffect} from "react";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import ImageUpload from "../ImageUpload";
import UploadWidget from "../UploadWidget";
import FooterMovil from "../FooterMovil";
import "../../style/Sell.css";
import { useAuth } from '../../context/AuthContext'; 
import { useNavigate } from 'react-router-dom';

function Sell() {
  const { authToken} = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if(authToken !== null){
      console.log(authToken)
    }else{
      console.log("nuay token")
    }
  },[])

  const handleClick = () => {
    if (!authToken) {
      navigate('/login');
    } else {
      console.log('Botón accionado'); 
    }
  };

  return (
    <>
      <Navbar />
      <div className="honduautos-backk">
        <center>
          <p className="sell-title">Vende tu Carro</p>
        </center>
        <div className="form-container-sell">
          <div className="lote">
            <input type="text" className="sell-input" placeholder="Lote" />
            <button className="fire ">
              <img src="images/fire-icon.png" alt="" className="fire-button" />
            </button>
          </div>
          <div className="widget">
            <UploadWidget />
          </div>
          <div className="grid-sell">
            <div className="columns">
              <input type="text" placeholder="Marca" />
              <input type="text" placeholder="Millaje" />
              <input type="text" placeholder="Condición" />
              <input type="text" placeholder="Transmisión" />
            </div>
            <div className="columns">
              <input type="text" placeholder="Modelo" />
              <input type="text" placeholder="Precio" />
              <input type="text" placeholder="Motor" />
              <input type="text" placeholder="Tracción" />
            </div>
            <div className="columns">
              <input type="text" placeholder="Año" />
              <input type="text" placeholder="Ubicación" />
              <input type="text" placeholder="Combustible" />
              <input type="text" placeholder="Tipo" />
            </div>
          </div>
          <div className="grid-sell-movil">
              <input type="text" placeholder="Marca" />
              <input type="text" placeholder="Millaje" />
              <input type="text" placeholder="Condición" />
              <input type="text" placeholder="Transmisión" />
              <input type="text" placeholder="Modelo" />
              <input type="text" placeholder="Precio" />
              <input type="text" placeholder="Motor" />
              <input type="text" placeholder="Tracción" />
              <input type="text" placeholder="Año" />
              <input type="text" placeholder="Ubicación" />
              <input type="text" placeholder="Combustible" />
              <input type="text" placeholder="Tipo" />
          </div>
          <div className="grid-sell-last">
            <div className="columns-2">
              <input type="text" placeholder="Comentario" />
              <input type="text" placeholder="Agregue enlace 306" />
            </div>
            <div className="columns-2">
              <input type="text" placeholder="Listado" />
              <input type="text" placeholder="Precio Oferta" />
            </div>
          </div>

          <center><button type="submit" className="add-car" onClick={handleClick}>AGREGAR AUTO</button></center>

        </div>
      </div>
      <div className="footer">
      <Foooter />
      </div>
      <div className="footer-movil">
      <FooterMovil/>
      </div>
    </>
  );
}

export default Sell;
