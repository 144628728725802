import React, { useEffect, useState,useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import UploadWidget from "../UploadWidget.jsx";
import Navbar from "../Navbar.jsx";
import Foooter from "../Foooter.jsx";
import FooterMovil from "../FooterMovil.jsx";
import "../../style/AddCar.css";
import { useAuth } from "../../context/AuthContext.js";
import ModalDelete from "../ModalDelete";

function EditCar() {
  const location = useLocation();
  const carroToEdit = location.state?.carro || null;

  const [username, setUsername] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedBrandID, setSelectedBrandID] = useState("");
  const [selectedUBI, setSelectedUBI] = useState("");
  const [selectedgastype, setSelectedgastype] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedTrans, setSelectedTrans] = useState("");
  const [selectedTrac, setSelectedTrac] = useState("");
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);
  

  const { authToken, logout } = useAuth();
  const navigate = useNavigate();

  const handleSelectMainImage = (url) => {
    setMainImage(url);
  };

  const handleDeleteImage = (url) => {
    setImageToDelete(url);
    setShowDeleteModal(true);
  };

  const confirmDeleteImage = () => {
    setImages((prevImages) =>
      prevImages.filter((image) => image !== imageToDelete)
    );
    if (mainImage === imageToDelete) {
      setMainImage(images[0] || null);
    }
    setShowDeleteModal(false);
    setImageToDelete(null);
    };

    const cancelDeleteImage = () => {
      setShowDeleteModal(false);
      setImageToDelete(null);
    };

  const ifedit = async () => {
    setInputValues({
      millaje: carroToEdit.millaje,
      precio: carroToEdit.precio,
      motor: carroToEdit.motor,
      anio: carroToEdit.anio,
      comentario: carroToEdit.comentario,
      enlace: carroToEdit.enlace,
      listado: carroToEdit.listado,
      precioOferta: carroToEdit.precioOferta,
      lote: carroToEdit.lote,
      enlace: carroToEdit.link360

    });
    setImages(carroToEdit.imagenes);
    setSelectedBrand(carroToEdit.marca);
    await HandleBrandChangeUpdate(carroToEdit.marca);
    setSelectedModel(carroToEdit.modelo);
    setSelectedUBI(carroToEdit.ubicacion);
    setSelectedgastype(carroToEdit.combustible);
    setSelectedType(carroToEdit.tipo);
    setSelectedCondition(carroToEdit.condicion);
    setSelectedTrans(carroToEdit.transmission);
    setSelectedTrac(carroToEdit.traccion);
    setCheckboxes({
      costosintro: carroToEdit.Introduccion,
      camina: carroToEdit.camina,
      placas: carroToEdit.costosPlacas,
      destacado: carroToEdit.destacado,
      enciende: carroToEdit.enciende,
      costosvias: carroToEdit.viasPublicas,
      grua: carroToEdit.gruaIncluida,
      costosins: carroToEdit.gastosDeInscripcion,
    });
  };

  const getMarcas = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    setMarcas(res.data);
  };

  const getuser = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
      { headers: { Authorization: "bearer " + authToken } }
    );
    setUsername(response.data.nombre);
  };

  useEffect(() => {
    const inicio = async () => {
      await getMarcas();

      if (!authToken) {
        navigate("/");
      } else {
        await getuser();
      }

      if (carroToEdit) {
        ifedit();
      }
    };

    inicio();
  }, [authToken, carroToEdit, navigate]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const gastypes = ["Gasolina", "Disel"];
  const typecars = [
    "Camioncito",
    "Pick Up",
    "Sedan",
    "Camioneta",
    "Cabezal",
    "Bus",
    "Camion",
  ];
  const transmisiones = ["Automatica", "Manual"];
  const tracciones = ["Delantera", "Trasera", "4x4"];
  const condiciones = ["Chocado", "Arreglado", "En Buen Estado"];
  const Ubications = [
    "USA",
    "Puerto Cortez",
    "Teguscigalpa",
    "Transito",
    "San Lorenzo",
  ];

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    setModelos(res.data);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    const result = marcas.filter(
      (marca) => marca.Nombre === event.target.value
    );
    setSelectedBrandID(result[0]._id);
    getmodelos(result[0]._id);
  };

  const HandleBrandChangeUpdate = async (Brand) => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/marca"
    );
    const brands = res.data;
    const result = brands.filter((brand) => brand.Nombre === Brand);
    setSelectedBrandID(result[0]._id);
    getmodelos(result[0]._id);
  };

  const handleModelChange = (event) => setSelectedModel(event.target.value);
  const handleTransChange = (event) => setSelectedTrans(event.target.value);
  const handleTracChange = (event) => setSelectedTrac(event.target.value);
  const handleConditionChange = (event) => setSelectedCondition(event.target.value);
  const handleTypeChange = (event) => setSelectedType(event.target.value);
  const handleUBIChange = (event) => setSelectedUBI(event.target.value);
  const handleGasChange = (event) => setSelectedgastype(event.target.value);

  const handleUploadSuccess = (url) => {
    setImages((prevImages) => [...prevImages, url]);
    if (images.length === 0) {
      setMainImage(url);
    }
  };

  const [inputValues, setInputValues] = useState({
    millaje: "",
    precio: "",
    motor: "",
    anio: "",
    comentario: "",
    enlace: "",
    listado: "",
    precioOferta: "",
    lote: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    iaai: false,
    copart: false,
    grua: false,
    costosintro: false,
    enciende: false,
    camina: false,
    costosvias: false,
    costosins: false,
    placas: false,
    destacado: false,
    inscripcion: false,
  });

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({ ...prevCheckboxes, [name]: checked }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const filteredArray = images.filter(
      (item) =>
        item !== null && item !== undefined && item !== "" && !(Array.isArray(item) && item.length === 0)
    );
    const carData = {
      ...inputValues,
      imagenes: filteredArray,
      condicion: selectedCondition,
      marca: selectedBrand,
      modelo: selectedModel,
      tipo: selectedType,
      traccion: selectedTrac,
      transmission: selectedTrans,
      ubicacion: selectedUBI,
      combustible: selectedgastype,
      millaje: parseInt(inputValues.millaje),
      anio: parseInt(inputValues.anio),
      precio: parseInt(inputValues.precio),
      camina: checkboxes.camina,
      Introduccion: checkboxes.costosintro,
      enciende: checkboxes.enciende,
      viasPublicas: checkboxes.costosvias,
      gruaIncluida: checkboxes.grua,
      costosPlacas: checkboxes.placas,
      destacado: checkboxes.destacado,
      gastosDeInscripcion: checkboxes.costosins,
    };

    try {
      console.log(carData)
      await axios.post(
        `${process.env.REACT_APP_BACKENDIP}carro/${carroToEdit._id}`,
        carData,
        {
          headers: {
            Authorization: `bearer ${authToken}`,
          },
        }
      );
      navigate("/allcarsa");
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-add">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-all">
          <div
              ref={dropDownRef}
              className="whole-drop-a"
            >
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img-d" />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/sum.png" alt="" className="element-img-d" />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Editar Auto</p>
            <form onSubmit={handleSubmit} className="form-container-add">
              <div className="links">
                <div className="widget">
                  <UploadWidget onUploadSuccess={handleUploadSuccess} />
                </div>
                <div className="images-container-cloudinary">
                  {images.length > 0 ? (
                    <>
                      <div className="main-image-container">
                        <img
                          src={mainImage || images[0]}
                          alt="Main Uploaded"
                          className="main-uploaded-image"
                        />
                      </div>
                      <div className="thumbnail-container">
                        {images.map((url, index) => (
                          <div key={index} className="thumbnail-image-container">
                            <img
                              src={url}
                              alt={`Uploaded ${index}`}
                              className="thumbnail-uploaded-image"
                              onClick={() => handleSelectMainImage(url)}
                              style={{
                                cursor: "pointer",
                                border: mainImage === url ? "2px solid blue" : "none",
                              }}
                            />
                            <button
                              type="button"
                              onClick={() => handleDeleteImage(url)}
                              className="delete-button"
                            >
                              ‎
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p>No images uploaded</p>
                  )}
                </div>
              </div>
              <div className="grid-sell">
                <div className="columns">
                  <select
                    name="marca"
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    id="marca"
                  >
                    <option value="">Marca</option>
                    {marcas.map((marca) => (
                      <option key={marca._id} value={marca.Nombre}>
                        {marca.Nombre}
                      </option>
                    ))}
                  </select>
                  <input
                    name="millaje"
                    type="text"
                    placeholder="Millaje"
                    value={inputValues.millaje}
                    onChange={handleInputChange}
                  />
                  <select
                    name="condicion"
                    value={selectedCondition}
                    onChange={handleConditionChange}
                    id="condicion"
                  >
                    <option value="">Condicion</option>
                    {condiciones.map((condicion) => (
                      <option key={condicion} value={condicion}>
                        {condicion}
                      </option>
                    ))}
                  </select>
                  <select
                    name="transmision"
                    value={selectedTrans}
                    onChange={handleTransChange}
                    id="transmision"
                  >
                    <option value="">Transmisión</option>
                    {transmisiones.map((trans) => (
                      <option key={trans} value={trans}>
                        {trans}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columns">
                  <select
                    name="modelo"
                    value={selectedModel}
                    onChange={handleModelChange}
                    id="modelo"
                  >
                    <option value="">Modelo</option>
                    {modelos.map((modelo) => (
                      <option key={modelo._id} value={modelo.Nombre}>
                        {modelo.Nombre}
                      </option>
                    ))}
                  </select>
                  <input
                    name="precio"
                    type="text"
                    placeholder="Precio"
                    value={inputValues.precio}
                    onChange={handleInputChange}
                  />
                  <input
                    name="motor"
                    type="text"
                    placeholder="Motor"
                    value={inputValues.motor}
                    onChange={handleInputChange}
                  />
                  <select
                    name="traccion"
                    value={selectedTrac}
                    onChange={handleTracChange}
                    id="traccion"
                  >
                    <option value="">Tracción</option>
                    {tracciones.map((trac) => (
                      <option key={trac} value={trac}>
                        {trac}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columns">
                  <input
                    name="anio"
                    type="text"
                    placeholder="Año"
                    value={inputValues.anio}
                    onChange={handleInputChange}
                  />
                  <select
                    name="ubicacion"
                    value={selectedUBI}
                    onChange={handleUBIChange}
                    id="ubicacion"
                  >
                    <option value="">ubicacion</option>
                    {Ubications.map((ubication) => (
                      <option key={ubication} value={ubication}>
                        {ubication}
                      </option>
                    ))}
                  </select>
                  <select
                    name="combustible"
                    value={selectedgastype}
                    onChange={handleGasChange}
                    id="combustible"
                  >
                    <option value="">Combustible</option>
                    {gastypes.map((gastype) => (
                      <option key={gastype} value={gastype}>
                        {gastype}
                      </option>
                    ))}
                  </select>
                  <select name="tipo" value={selectedType} onChange={handleTypeChange} id="tipo">
                    <option value="">Tipo</option>
                    {typecars.map((tipo) => (
                      <option key={tipo} value={tipo}>
                        {tipo}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="columns">
                  <input name="lote" type="text" placeholder="lote" value={inputValues.lote} onChange={handleInputChange} />
                </div>
              </div>
              <div className="grid-sell-last">
                <div className="columns-2">
                  <input
                    name="comentario"
                    type="text"
                    placeholder="Comentario"
                    value={inputValues.comentario}
                    onChange={handleInputChange}
                  />
                  <input
                    name="enlace"
                    type="text"
                    placeholder="Agregue enlace 306"
                    value={inputValues.enlace}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="columns-2">
                  <input
                    name="listado"
                    type="text"
                    placeholder="Listado"
                    value={inputValues.listado}
                    onChange={handleInputChange}
                  />
                  <input
                    name="precioOferta"
                    type="text"
                    placeholder="Precio Oferta"
                    value={inputValues.precioOferta}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="grid-sell-checks">
                <div className="columnsdown">
                  <div className="element-checks">
                    <input type="checkbox" className="check" name="grua" checked={checkboxes.grua} onChange={handleCheckboxChange} />
                    Grua incluida
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="costosintro"
                      checked={checkboxes.costosintro}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Introduccion
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="costosins"
                      checked={checkboxes.costosins}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Inscripcion
                  </div>
                </div>
                <div className="columnsdown">
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="costosvias"
                      checked={checkboxes.costosvias}
                      onChange={handleCheckboxChange}
                    />
                    Costos de Vias Publicas
                  </div>
                  <div className="element-checks">
                    <input
                      type="checkbox"
                      className="check"
                      name="destacado"
                      checked={checkboxes.destacado}
                      onChange={handleCheckboxChange}
                    />
                    Destacado
                  </div>
                  <div className="element-checks">
                    <input type="checkbox" className="check" name="enciende" checked={checkboxes.enciende} onChange={handleCheckboxChange} />
                    Enciende
                  </div>
                </div>
                <div className="columnsdown">
                  <div className="element-checks">
                    <input type="checkbox" className="check" name="placas" checked={checkboxes.placas} onChange={handleCheckboxChange} />
                    Costos de Placas
                  </div>
                  <div className="element-checks">
                    <input type="checkbox" className="check" name="camina" checked={checkboxes.camina} onChange={handleCheckboxChange} />
                    Camina
                  </div>
                </div>
              </div>

              <center>
                <button type="submit" className="add-car">
                  ACTUALIZAR AUTO
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
      <div className="footer">
      <Foooter />
      </div>
      <div className="footer-movil">
      <FooterMovil />
      </div>
      <ModalDelete show={showDeleteModal} onClose={cancelDeleteImage} onConfirm={confirmDeleteImage} />
    </>
  );
}

export default EditCar;