import React from 'react';

const CompraNotification = ({ noti }) => {
  console.log(noti)

  const contact = async () => {
    const numero = noti.numero
    const numericPhoneNumber = numero.replace(/\D/g, '');
    const convertedPhoneNumber = '504' + numericPhoneNumber;
    const message = `buen dia le saludo de parte del equipor de honduautos` ;
    const url = 'https://wa.me/' + convertedPhoneNumber + '?text=' + encodeURIComponent(message)
    await window.open(url, '_blank')
  }
  return (
    <div className="column-content-n">
      <p className="from">De: {noti.Mensajero.nombre}</p>
      <p className="to">Para: ADMINISTRACION</p>
      <p className="notification-content">{noti.body}</p>
      <div className="buttons-notification">
        <button className="button-n" onClick={contact}>Contactarse con el solicitante</button>
      </div>
    </div>
  );
};

export default CompraNotification;
