import React, { useState } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Popup360 = ({ link360 }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const toggleModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  if (!link360) {
    return null;
  }

  return (
    <>
      <div className="btn-360-">
        <button className="view360" onClick={toggleModal}>
          <p className="view-word">Vista</p>
          <img src="/icons/360.png" alt="" className="img360" />
        </button>
      </div>
      {isPopupOpen && (
        <Overlay>
          <ModalContainer>
            <CloseButton onClick={toggleModal}>X</CloseButton>
            <iframe
              src={link360}
              title="360 View"
              width="100%"
              height="500px"
              style={{ border: 'none' }}
            ></iframe>
          </ModalContainer>
        </Overlay>
      )}
    </>
  );
};

export default Popup360;
