import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../style/HomeDropdown.css";
import axios from "axios";
import { StockContext } from "../context/StockContext";

const HomeDropdown = ({ scrollToSection }) => {
  const [activeOption, setActiveOption] = useState(null);
  const [menuOption, setMenuOption] = useState(null);
  const [topFiveMarcas, setTopFiveMarcas] = useState([]);
  const navigate = useNavigate();
  const [hoveredOption, setHoveredOption] = useState(null);
  const { setOpenFilters, openFilters } = useContext(StockContext);

  // Fetch the top 5 logos (brands) on mount
  useEffect(() => {
    const fetchTopFiveMarcas = async () => {
      const res = await axios.get(
        process.env.REACT_APP_BACKENDIP + "marcasymodelos/marcaCantidad"
      );
      const topFive = res.data.slice(0, 10); // Get only the top 5 brands
      setTopFiveMarcas(topFive);
    };
    fetchTopFiveMarcas();
  }, []);

  const handleMenuClick = (optionMenu) => {
    if (menuOption === optionMenu) {
      setMenuOption(optionMenu); // Cierra el dropdown si ya está activo
    } else {
      setMenuOption(optionMenu); // Abre el dropdown correspondiente
    }
  };

  const navto = (url) => {
    navigate(url);
  };

  const handleOptionClick = (option) => {
    setActiveOption(activeOption === option ? null : option);

    // Redirigir a la página de stock y activar el filtro en MenuStock si es "año", "motor", o "precio"
    /*if (option === 'ano' || option === 'motor' || option === 'precio') {
      navigate('/stock');
      setOpenFilters(option);
    }*/

    switch (option) {
      case "ano":
        setOpenFilters("ano");
        break;
      case "motor":
        setOpenFilters("motor");
        break;
      case "precio":
        setOpenFilters("precio");
        break;
      default:
        break;
    }
    navigate("/stock");
  };

  const handleSubOptionClick = (optionType, value) => {
    // Redirigir a la página de stock con el filtro aplicado en la URL
    const queryParams = new URLSearchParams();
    queryParams.append(optionType, value);
    navigate(`/stock?${queryParams.toString()}`);
  };

  return (
    <div
      className="dropdown-container"
      onMouseLeave={() => setMenuOption(null)}
    >
      {/* Botón para Condición */}
      <div className="dropdown-item">
        <button
          className={`btn-home-style ${
            menuOption === "marca" || hoveredOption === "marca" ? "active" : ""
          }`}
          onMouseEnter={() => handleMenuClick("marca")}
          id="fisrt-btn"
          onClick={() => scrollToSection("to-logo", 0.2)}
        >
          Marca
        </button>
        {menuOption === "marca" && (
          <div
            className="dropdown-menu-horizontal"
            onMouseLeave={() => {
              setHoveredOption(null); // Quitar el hover al salir
              setMenuOption(null);
            }}
          >
            <ul className="list-item-hd">
              {topFiveMarcas.map((marca) => (
                <li
                  key={marca._id}
                  onClick={() => handleSubOptionClick("marca", marca.Nombre)}
                >
                  {marca.Nombre} ({marca.cantidad})
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="dropdown-item">
        <button
          className={`btn-home-style ${
            menuOption === "condicion" || hoveredOption === "condicion"
              ? "active"
              : ""
          }`}
          onMouseEnter={() => handleMenuClick("condicion")}
        >
          Condición
        </button>
        {menuOption === "condicion" && (
          <div
            className="dropdown-menu-horizontal"
            onMouseLeave={() => {
              setHoveredOption(null);
              setMenuOption(null);
            }}
          >
            <ul className="list-item-hd">
              <li onClick={() => handleSubOptionClick("condicion", "Chocado")}>
                Chocados
              </li>
              <li
                onClick={() => handleSubOptionClick("condicion", "Buen estado")}
              >
                Buen Estado
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Botón para Año */}
      <div className="dropdown-item">
        <button
          className={`btn-home-style ${
            menuOption === "ano" || hoveredOption === "ano" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("ano")}
          onMouseEnter={() => setMenuOption("ano")}
        >
          Año
        </button>
        {menuOption === "ano" && (
  <div
    className="dropdown-menu-horizontal scrollable"
    onMouseLeave={() => {
      setHoveredOption(null);
      setMenuOption(null);
    }}
  >
    <ul className="list-item-hd">
      {Array.from({ length: 2024 - 1980 + 1 }, (_, i) => 2024 - i).map((year) => (
        <li key={year} onClick={() => handleSubOptionClick("anio", year)}>
          {year}
        </li>
      ))}
    </ul>
  </div>
)}

      </div>

      {/* Botón para Tipo de Auto */}
      <div className="dropdown-item">
        <button
          className={`btn-home-style ${
            menuOption === "tipo_auto" || hoveredOption === "tipo_auto"
              ? "active"
              : ""
          }`}
          onMouseEnter={() => handleMenuClick("tipo_auto")}
        >
          Tipo de Auto
        </button>
        {menuOption === "tipo_auto" && (
          <div
            className="dropdown-menu-horizontal"
            onMouseLeave={() => {
              setHoveredOption(null);
              setMenuOption(null);
            }}
          >
            <ul className="list-item-hd">
              <li onClick={() => handleSubOptionClick("tipo", "Sedan")}>
                Turismo
              </li>
              <li onClick={() => handleSubOptionClick("tipo", "Camioneta")}>
                Camioneta
              </li>
              <li onClick={() => handleSubOptionClick("tipo", "Pick Up")}>
                Pick Up
              </li>
              <li onClick={() => handleSubOptionClick("tipo", "Camioncito")}>
                Camioncito
              </li>
              <li onClick={() => handleSubOptionClick("tipo", "Camión")}>
                Camión
              </li>
              <li onClick={() => handleSubOptionClick("tipo", "Cabezal")}>
                Cabezal
              </li>
              <li onClick={() => handleSubOptionClick("tipo", "Bus")}>Bus</li>
            </ul>
          </div>
        )}
      </div>

      {/* Botón para Precio */}
      <div className="dropdown-item">
        <button
          className={`btn-home-style ${
            menuOption === "precio" || hoveredOption === "precio"
              ? "active"
              : ""
          }`}
          onClick={() => handleOptionClick("precio")}
          onMouseEnter={() => setMenuOption("precio")}
        >
          Precio
        </button>
        {menuOption === "precio" && (
          <div className="dropdown-menu-horizontal">
            <ul
              className="list-item-hd"
              onMouseLeave={() => {
                setHoveredOption(null);
                setMenuOption(null);
              }}
            >
              {/* 5 rangos de precios comunes */}
              <li onClick={() => handleSubOptionClick("precio", "100000")}>
                L. 100,000
              </li>
              <li onClick={() => handleSubOptionClick("precio", "200000")}>
                L. 200,000
              </li>
              <li onClick={() => handleSubOptionClick("precio", "300000")}>
                L. 300,000
              </li>
              <li onClick={() => handleSubOptionClick("precio", "400000")}>
                L. 400,000
              </li>
              <li onClick={() => handleSubOptionClick("precio", "500000")}>
                L. 500,000
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Botón para Motor */}
      <div className="dropdown-item">
        <button
          className={`btn-home-style ${
            menuOption === "motor" || hoveredOption === "motor" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("motor")}
          onMouseEnter={() => setMenuOption("motor")}
        >
          Motor
        </button>
        {menuOption === "motor" && (
          <div className="dropdown-menu-horizontal">
            <ul
              className="list-item-hd"
              onMouseLeave={() => {
                setHoveredOption(null);
                setMenuOption(null);
              }}
            >
              {/* 5 rangos de motores comunes */}
              <li onClick={() => handleSubOptionClick("motor", "1500")}>
                1,500 cc
              </li>
              <li onClick={() => handleSubOptionClick("motor", "2500")}>
                2,500 cc
              </li>
              <li onClick={() => handleSubOptionClick("motor", "3000")}>
                3,000 cc
              </li>
              <li onClick={() => handleSubOptionClick("motor", "4000")}>
                4,000 cc
              </li>
              <li onClick={() => handleSubOptionClick("motor", "5000")}>
                5,000 cc
              </li>
            </ul>
          </div>
        )}
      </div>

      <span>
        <button
          className="btn-home-style"
          onClick={() => navto("/stock")}
          onMouseEnter={() => setMenuOption(null)}
        >
          Busqueda avanzada
        </button>
      </span>
    </div>
  );
};

export default HomeDropdown;
