import React from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const VideoDisplay = () => {
  return (
    <VideoContainer>
      <iframe width="100%" height="315" 
        src="https://www.youtube.com/embed/9-IiSMi_W_I?si=jUnaxqQD9ATPoWnw&autoplay=1&mute=1" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" 
        allowfullscreen>
      </iframe>
    </VideoContainer>
  );
};

export default VideoDisplay;
