import React, { useState } from 'react';
import { useAuth } from "../context/AuthContext.js";
import Popup from './Popup'; // Asegúrate de que la ruta sea correcta

const ButtonWithAuthCheck = ({ solicitarCompra }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleClick = () => {
      solicitarCompra();
      setShowSuccessPopup(true);
  };

  return (
    <>
      <button onClick={handleClick} className="request-information">
        Solicitar información
      </button>
      {showPopup && (
        <Popup
          message="No puedes realizar esta acción si no has iniciado sesión."
          onClose={() => setShowPopup(false)}
          duration={3000}
        />
      )}
      {showSuccessPopup && (
        <Popup
          message="Solicitud realizada, le informaremos pronto."
          onClose={() => setShowSuccessPopup(false)}
          duration={3000}
        />
      )}
    </>
  );
};

export default ButtonWithAuthCheck;
