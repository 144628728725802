import React from "react";
import "../style/CarOverviewSaldo.css";

function CarOverviewWithSaldo({ carro, saldo, onClick }) {
  let backgroundurl = carro.imagenes[0];
  backgroundurl = backgroundurl.replace("http://", "https://")

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <div onClick={onClick} style={{ "--background-image": `url(${backgroundurl})` }}
        className="car-updated">
            <div className="info-part-s">
            {carro.lote !== '0' && (<p className="lote-s" id="car-lote-h">Lote: {carro.lote}</p>)}
            <p className="saldo">Saldo: L.{formatNumberWithCommas(saldo)}</p>
              <p className="price-home-s">L.{formatNumberWithCommas(carro.precio)}</p>
              <p className="car-model-s">
                {carro.marca} {carro.modelo} {carro.anio}
              </p>
          </div>
      </div>
    </>
  );
}

export default CarOverviewWithSaldo;
