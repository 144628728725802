import React, { useState } from 'react';
import "../style/MyCar.css";
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';
import DeleteModal from './DeleteModal';
import AbonoModal from './AbonoCarAllcars';

function MyCar({ carro, onCarDeleted }) {
  const backgroundurl = carro.imagenes[0];
  const { authToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showModalAbono, setShowModalAbono] = useState(false);
  const [selectedModal, setSelectedModal] = useState(false)
  const [message, setMessage] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [ventaid, setvendaid] = useState()
  const [saldo, setsaldo] = useState()
  const navigate = useNavigate();

  const handleDelete = async () => {
    setMessage(`¿Estás seguro de que deseas eliminar el auto ${carro.marca} ${carro.modelo}?`);
    setShowMessageModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(process.env.REACT_APP_BACKENDIP + "carro/" + carro._id, { headers: { Authorization: "bearer " + authToken } });
      onCarDeleted();
      setMessage('Auto eliminado exitosamente.');
    } catch (error) {
      console.error('Error deleting car:', error);
      setMessage('No se pudo eliminar el auto.');
    }
    setShowMessageModal(true);
  };

  const findventa = async () => {
    const data = {
        carroID: carro._id
    }
    const res = await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/comprabycar", data)
    setvendaid(res.data[0]._id)
    setsaldo(res.data[0].Saldo)
    setSelectedModal(true)
  }

  const toggleModal = () => {
    if(carro.vendido == false){
      setShowModal(!showModal);
    } else {
      findventa();
      setShowModalAbono(!showModalAbono)
    }
  };

  const handleConfirm = () => {
    setShowMessageModal(false);
    confirmDelete();
  };

  const closeModal = () => {
    setShowMessageModal(false);
  };

  const navclientes = () => {
    onCarDeleted();
  };

  const handleEdit = () => {
    navigate('/editcar', { state: { carro } });
  };

  return (
    <>
      <div className="main-container-car">
       <div className="car-models-m">
       <div className="movil-marca">
        <p className="car-title-m">{carro.marca} {carro.modelo} {carro.anio}</p>
        </div>
        <img src={backgroundurl} alt="" className='car-img' />
       </div>
        <div className="info-car">
          <div className="info-info">
          <div className="head">
            <p className="car-title">{carro.marca} {carro.modelo} {carro.anio}</p>
          </div>
          <p className="car-lote">Lote: {carro.lote}</p>
          <div className="especificaciones">
            <p className="millaje">Millaje: {carro.millaje}</p>
            <p className="motor">Motor: {carro.motor}</p>
            <p className="combustible">Combustible: {carro.combustible}</p>
          </div>
          <div className="precios">
            <p className="precio">Precio: L. {carro.precio}</p>
            <p className="precio-oferta">Precio en Oferta: L. {carro.precioOferta}</p>
          </div>
          </div>
          <div className="edit-btns">
          <button className="icon" onClick={handleDelete}><img src="icons/cancel.png" alt="" className='icon-img' id='cancel'/></button>
          <button className="icon" onClick={handleEdit}><img src="icons/edit.png" alt="" className='icon-img' id='edit'/></button>
          <button className="icon" onClick={toggleModal}><img src="icons/dollar.png" alt="" className='icon-img' id='dollar'/></button>
        </div>
        </div>
      </div>
      <Modal show={showModal} carro={carro} onClose={toggleModal} onConfirm={navclientes} carroid={carro._id}/>
      {selectedModal && <AbonoModal show={showModalAbono} carro={carro} saldo={saldo} vendaid={ventaid} onClose={toggleModal} onConfirm={toggleModal}/>}
      {showMessageModal && <DeleteModal message={message} onClose={closeModal} onConfirm={handleConfirm} />}
    </>
  );
}

export default MyCar;
