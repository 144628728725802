import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import Navbar from "../Navbar";
import axios from "axios";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import "react-quill/dist/quill.snow.css";
import "../../style/EditContent.css";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

function EditContent() {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [username, setUsername] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const { authToken, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getuser = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
      { headers: { Authorization: "bearer " + authToken } }
    );
    setUsername(response.data.nombre);
  };

  useEffect(() => {
    const inicio = async () => {
      if (!authToken) {
        navigate("/");
      } else {
        await getuser();
      }
    };
    inicio();
  }, [authToken, navigate]);

  useEffect(() => {
    if (type) {
      fetchContent(type);
    }
  }, [type]);

  const fetchContent = async (type) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "content/content/" + type,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setTitle(response.data.type);
      setContent(response.data.body);
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  const saveContent = async () => {
    try {
      const data = { title, body: content };
      const res = await axios.post(
        process.env.REACT_APP_BACKENDIP + "content/content/" + type,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      message.success("Contenido actualizado correctamente");
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const toogleDropdown = () => setIsOpen(!isOpen);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ["clean"], // remove formatting button
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "align",
  ];

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-admin-ec">
          <div className="column-admin">
            <p className="admin-name">Hola, {username}</p>
            <div className="admin-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcarsa" className="element-text">
                  Autos
                </a>
              </div>
              <div className="element">
                <img src="icons/sum.png" alt="" className="element-img" />
                <a href="/addcar" className="element-text">
                  Agregar autos
                </a>
              </div>
              <div className="element">
                <img src="icons/user.png" alt="" className="element-img" />
                <a href="/allusers" className="element-text">
                  Compradores
                </a>
              </div>
              <div className="element">
                <img src="icons/email.png" alt="" className="element-img" />
                <a href="/boletin" className="element-text">
                  Boletines
                </a>
              </div>
              <div className="element">
                <img src="icons/content.png" alt="" className="element-img" />
                <a href="/editcontent" className="element-text">
                  Contenido
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/banner-white.png"
                  alt=""
                  className="element-img"
                />
                <a href="/banner" className="element-text">
                  Banner
                </a>
              </div>
              <div className="element">
                <img
                  src="icons/campana.png"
                  alt=""
                  className="element-img"
                />
                <a href="/notificationsadmin" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info-ec">
            <div ref={dropDownRef} className="whole-drop-a">
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU ADMINISTRADOR</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="admin-menu-drop">
                    <div className="element-d">
                      <img
                        src="icons/car.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allcarsa" className="element-text-d">
                        Autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/sum.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/addcar" className="element-text-d">
                        Agregar autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/user.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/allusers" className="element-text-d">
                        Compradores
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/email.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/boletin" className="element-text-d">
                        Boletines
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/content.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/editcontent" className="element-text-d">
                        Contenido
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/banner-white.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/banner" className="element-text-d">
                        Banner
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/notification.png"
                        alt=""
                        className="element-img-d"
                      />
                      <a href="/notificationsadmin" className="element-text-d">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Editar Contenido</p>
            <div className="options-edit">
              <div className="element-edit" onClick={() => setType("Nosotros")}>
                <img src="icons/aboutus.png" alt="" className="edit-icon-a" />
                <p className="title-option">Sobre Nosotros</p>
              </div>
              <div className="element-edit" onClick={() => setType("Politicas")}>
                <img src="icons/politics.png" alt="" className="edit-icon-p" />
                <p className="title-option">Políticas</p>
              </div>
              <div className="element-edit" onClick={() => setType("Avisos")}>
                <img src="icons/avisos.png" alt="" className="edit-icon-av" />
                <p className="title-option">Avisos</p>
              </div>
              <div className="element-edit" onClick={() => setType("Credito")}>
                <img src="icons/credit.png" alt="" className="edit-icon-c" />
                <p className="title-option">Solicitud de crédito</p>
              </div>
              <div className="element-edit" onClick={() => setType("Otros")}>
                <img src="icons/others.png" alt="" className="edit-icon-c" />
                <p className="title-option">Otros</p>
              </div>
            </div>
            {type === "Otros" ? (
              <>
                <input
                  type="email"
                  className="title-input"
                  placeholder="Correo Electrónico"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  type="text"
                  className="title-input"
                  placeholder="Teléfono"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </>
            ) : (
              <>
                <input
                  type="text"
                  className="title-input"
                  placeholder="Título"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  modules={modules}
                  formats={formats}
                  style={{ width: "90%", height: "500px" }}
                />
              </>
            )}
            <button className="save-btn-e" onClick={saveContent}>
              GUARDAR
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default EditContent;
