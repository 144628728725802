import React, { useState } from "react";
import "../style/ImageUpload.css";

function ImageUpload() {
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const fileListView = files.map((file, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 10px",
      }}
    >
      <img
        src={file.preview}
        alt={`preview-${index}`}
        style={{ width: "100px", height: "100px", objectFit: "contain" }}
      />
      <button onClick={() => handleRemoveFile(file)}>Eliminar</button>
    </div>
  ));

  return (
    <div>
      <input
        type="file"
        id="imageInput"
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
        accept="image/*"
      />
      <label
        htmlFor="imageInput"
        style={{
          display: "flex",
          alignItems:"center",
          justifyContent:"flex-start",
          cursor: "pointer",
          padding: "20px 65px 20px 5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          backgroundColor: "#f0f0f0",
          width: "100%",
        }}
      >
        <p className="p-upload">Subir Imágenes</p>
      </label>
      {files.length > 0 && (
        <div
          style={{
            display: "flex",
            overflowX: "hidden",
            padding: "10px",
            border: "1px solid #ccc",
            marginTop: "2%",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          {fileListView}
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
