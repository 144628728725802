import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar.jsx";
import Footer from "../Foooter.jsx";
import FooterMovil from "../FooterMovil.jsx";
import CarOverview from "../CarOverview.jsx";
import CarOverviewNotSpecialHome from "../carOverviewNotSpecial.js";
import CarOverviewOferta from "../CarOverviewOferta.jsx";
import CarOverviewDestacado from "../CarOverviewDestacado.js";
import axios from "axios";
import "../../style/Home.css";
import Fuse from "fuse.js";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Dropdown.jsx";
import ImageCarousel from "../ImageCarousel.jsx";
import VideoContainer from "../VideoContainer.jsx";
import LogoGallery from "../LogoGalley.jsx";
import HomeDropdown from "../HomeDropdown.jsx";


const Home = () => {
  const [carros, setCarros] = useState([]);
  const [viewRecentCarros, setviewRecentCarros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken, logout } = useAuth();
  const [isofferta, setisofferta] = useState(false);
  const [isDestacado, setisDestacado] = useState(true);
  const [suggestions, setsugestions] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [input, setInput] = useState("");
  const [searchType, setSearchType] = useState([]);
  const [marcaid, setmarcaid] = useState("");
  const [fullmarca, setfullmarca] = useState([]);
  const [suggestingmodels, setSuggestingModels] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [banners, setbanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullmodelo, setfullmodelo] = useState([]);
  const [allmodelos, setAllModelos] = useState([]);


  const [selectedButton, setSelectedButton] = useState("Destacados");

  const containerRef = useRef(null);
  const navigate = useNavigate();

  const scrollRef = useRef(null);
  const scrollRef2 = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -500, behavior: "smooth" });
    }
  };

  const scrollLeft2 = () => {
    if (scrollRef2.current) {
      scrollRef2.current.scrollBy({ left: -500, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 500, behavior: "smooth" });
    }
  };

  const scrollRight2 = () => {
    if (scrollRef2.current) {
      scrollRef2.current.scrollBy({ left: 500, behavior: "smooth" });
    }
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return the original string if it's empty or undefined
    if(str === "bmw"){
      return "BMW"
    }
    if(str === "gmc"){
      return "GMC"
    }
    if(str === "ram"){
      return "RAM"
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const findofferta = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "findspecial/findoferta"
    );
    const carros = res.data
    setisDestacado(false);
    setisofferta(true);
    setCarros(carros.reverse());
  };

  const findNew = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "findspecial/nuevoingreso"
    );
    setisDestacado(false);
    setisofferta(false);
    setCarros(res.data.reverse());
  };

  const findViewRecent = async () => {
    const res = await axios.get(
      process.env.REACT_APP_BACKENDIP + "findspecial/recentbyuser",
      { headers: { Authorization: `bearer ` + authToken } }
    );
    const data = res.data;
    setviewRecentCarros(data.carrosVistosReciente);
  };
  
  const isFetchingMarcas = useRef(false);
  const isMountedMarcas = useRef(true);

  useEffect(() => {
    getMarcas();
    return () => {
      isMountedMarcas.current = false;
    };
  }, []);

  const getMarcas = async () => {
    if (isFetchingMarcas.current) {
      return;
    }
    isFetchingMarcas.current = true; // Set the flag to indicate the function is running
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKENDIP}marcasymodelos/marcaCantidad`
      );
      const carros = res.data;
      const nombres = carros.map((carro) => carro.Nombre);
        setMarcas(nombres);
        setfullmarca(res.data);
    } catch (error) {
      if (isMountedMarcas.current) {
        console.error('Error fetching marcas:', error);
      }
    } finally {
      isFetchingMarcas.current = false; // Reset the flag when the function completes
    }
  };

  const getmodelos = async (marcaID) => {
    const data = { marca: marcaID };
    const res = await axios.post(
      process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelobymarca",
      data
    );
    const modelos = res.data;
    const nombres = modelos.map((modelos) => modelos.Nombre);
    const nombresConMarca = modelos.map(
      (modelo) => `${selectedBrand} ${modelo.Nombre}`
    );
    setModelos(nombres);
    setsugestions(nombresConMarca);
    setSuggestingModels(true);
  };

  const fuse = new Fuse(marcas, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["marca"],
  });

  const fusemodelos = new Fuse(allmodelos, {
    threshold: 0.6, // Adjust this value to be more or less strict
    keys: ["modelo"],
  });

  const anioPattern = /^\d{4}$/;
  const lotNumberPattern = /^\d{8}$/

  const findObjectByKeyValue = (array, key, value) => {
    return array.find((obj) => obj[key] === value);
  };

  const detectInputType = (value) => {
    setSelectedBrand("");
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    const suggestions = fuse.search(parts[0]);
    const suggestionsmodelos = fusemodelos.search(parts[0])
    let mergedArray = suggestionsmodelos.concat(suggestions);
    setsugestions(mergedArray.map((suggestion) => suggestion.item));
    parts.forEach((part) => {
      if(part === "mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if(part === "santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (allmodelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (
        modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())
      ) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
      if (lotNumberPattern.test(part)) {
        matches.push({ type: "lote", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    if (matches[0].type === "marca") {
      const algo = findObjectByKeyValue(fullmarca, "Nombre", matches[0].value);
      setSelectedBrand(matches[0].value);
      getmodelos(algo._id);
    }
    setSearchType([...new Set(matches)]);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInput(value);
    detectInputType(value);
  };

  const navigateToResults = () => {
    const queryParams = new URLSearchParams();
    searchType.forEach((info) => {
      queryParams.append(info.type, info.value);
    });
    navigate(`/stock?${queryParams.toString()}`);
  };

  const handleSearch = () => {
    navigateToResults();
  };

  const getBanners = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "anuncios/");
    const imagedata = res.data;
    const imageUrls = imagedata.map((item) => item.Imagen);
    setbanners(imageUrls);
  };

  const getModelosAll = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "marcasymodelos/modelo");
    const modelos = res.data;
    const nombres = modelos.map((modelo) => modelo.Nombre);
    setAllModelos(nombres)
    setfullmodelo(modelos);
  };

  const fetchAllCarros = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "findspecial/destacar"
      );
      setCarros(response.data.reverse());
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setCarros([]); // Consider whether you want to reset carros on error
    }
    await setLoading(false);
  };

  const isMounted = useRef(true);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchAllCarros(), getBanners(), getMarcas(), getModelosAll()]);
      if (authToken) {
        findViewRecent();
      }
    };
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [authToken]);

  const detectInputTypeSugestion = (value) => {
    const valueTrim = value.trim();
    let parts = valueTrim.split(" ");
    let matches = [];
    parts.forEach((part) => {
      if(part === "mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if(part === "santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Santa"){
        matches.push({ type: "modelo", value: "Santa Fe" });
      }
      if(part === "Mercedes"){
        matches.push({ type: "marca", value: "Mercedes Benz" });
      }
      if (marcas.some((marca) => marca.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "marca", value: capitalizeFirstLetter(part) });
      }
      if (allmodelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (modelos.some((modelo) => modelo.toLowerCase() === part.toLowerCase())) {
        matches.push({ type: "modelo", value: capitalizeFirstLetter(part) });
      }
      if (anioPattern.test(part)) {
        matches.push({ type: "anio", value: part });
      }
    });
    if (matches.length === 0) {
      matches.push({ type: "unknown", value: value }); // Fallback if no matches found
    }
    setSearchType([...new Set(matches)]);
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setsugestions([]);
    detectInputTypeSugestion(suggestion);
  };

  const handleButtonClick = (button, originalFunction) => {
    setSelectedButton(button);
    originalFunction(); // Llama a la función original
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const navto = (url) => {
    navigate(url);
  };

  const scrollToSection = (id, offset = 0) => {
    const section = document.getElementById(id);
    if (section) {
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - window.innerHeight * offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Navbar />
      <center>
      <div className="div-home">
        <span>
          <HomeDropdown scrollToSection={scrollToSection}/>
        </span>
      </div>
    </center>
      <center>
        <div className="search-bar-home">
          <div className="search-presentation">
            <p className="search-ttl">
              EL PRIMER Y MEJOR SITIO DE AUTOS IMPORTADOS EN HONDURAS
            </p>
            <form className="search-actions" onSubmit={handleSearch}>
              <div
                className={`search-bar-ac ${
                  suggestions.length > 0 ? "show-suggestions" : ""
                }`}
              >
                <input
                  type="text"
                  id="Search"
                  value={input}
                  onChange={handleChange}
                  placeholder=" Marca, modelo, lote, etc."
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <Dropdown />
              <button type="button" id="search" onClick={handleSearch}>
                BUSCAR
              </button>
            </form>
          </div>
        </div>
      </center>
      {/* <center>
        <div className="filter-buttons">
          <button className="home-btn" onClick={() => scrollToSection('to-logo', 0.2)}>Comprar por marca</button>
          <button className="home-btn"><a href="" className="ref-home">Buen estado</a></button>
          <button className="home-btn"><a href="/stock" className="ref-home">Busqueda avanzada</a></button>
        </div>
      </center> */}
      {authToken && (
        <div className="offers">
          <p className="search-title">VISTOS RECIENTEMENTE</p>
          <button className="arrow-h left-arrow" onClick={scrollLeft2}>
            <img
              src="icons/arrow-left.png"
              alt="flecha izquierda"
              className="arrow-img"
            />
          </button>
          <div className="scroll-type" ref={scrollRef2}>
            {viewRecentCarros.map((carro) => (
              <CarOverviewNotSpecialHome key={carro._id} carro={carro} />
            ))}
          </div>
          <button className="arrow-h right-arrow" onClick={scrollRight2}>
            <img
              src="icons/arrow-right.png"
              alt="flecha derecha"
              className="arrow-img"
            />
          </button>
        </div>
      )}
      <center>
        <div className="search-by-type">
          <p className="search-title">BUSCAR POR TIPO DE CARRO</p>
          <button className="arrow-h left-arrow" onClick={scrollLeft}>
            <img
              src="icons/arrow-left.png"
              alt="flecha izquierda"
              className="arrow-img"
            />
          </button>
          <div className="scroll-type" ref={scrollRef}>
            <a href="/stock?tipo=Sedan" className="car-type">
              <img src="images/sedan.png" alt="" className="car" />
              <p className="car-name-type">TURISMO</p>
            </a>
            <a href="/stock?tipo=Camioneta" className="car-type">
              <img
                src="images/camioneta.png"
                alt=""
                className="car"
                id="camioneta"
              />
              <p className="car-name-type">CAMIONETA</p>
            </a>
            <a href="/stock?tipo=Pick Up" className="car-type">
              <img
                src="images/pick-up.png"
                alt=""
                className="car"
                id="pick-up"
              />
              <p className="car-name-type">PICK-UP</p>
            </a>
            <a href="/stock?tipo=Camioncito" className="car-type">
              <img
                src="images/camioncito.png"
                alt=""
                className="car"
                id="camioncito"
              />
              <p className="car-name-type">CAMIONCITO</p>
            </a>
            <a href="/stock?tipo=Camion" className="car-type">
              <img src="images/truck.png" alt="" className="car" id="camion" />
              <p className="car-name-type">CAMIÓN</p>
            </a>
            <a href="/stock?tipo=Moto" className="car-type">
              <img src="images/moto.png" alt="" className="car" id="Moto" />
              <p className="car-name-type">MOTOCICLETA</p>
            </a>
            <a href="/stock?tipo=Varios" className="car-type">
              <img src="images/varios.png" alt="" className="car" id="Varios" />
              <p className="car-name-type">VARIOS</p>
            </a>
            <a href="/stock?tipo=Cabezal" className="car-type">
              <img
                src="images/cabezal.png"
                alt=""
                className="car"
                id="cabezal"
              />
              <p className="car-name-type">CABEZAL</p>
            </a>
            <a href="/stock?tipo=Bus" className="car-type">
              <img src="images/bus.png" alt="" className="car" id="bus" />
              <p className="car-name-type">BUS</p>
            </a>
          </div>
          <button className="arrow-h right-arrow" onClick={scrollRight}>
            <img
              src="icons/arrow-right.png"
              alt="flecha derecha"
              className="arrow-img"
            />
          </button>
        </div>
      </center>
      <div className="offers">
        <div className="buttons">
          <button
            id="recent-cars"
            className={`btnCar ${
              selectedButton === "Recien subidos" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("Recien subidos", findNew)}
          >
            Recién subidos
          </button>
          <button
            id="offer-cars"
            className={`btnCar ${selectedButton === "Ofertas" ? "active" : ""}`}
            onClick={() => handleButtonClick("Ofertas", findofferta)}
          >
            Ver ofertas
          </button>
        </div>

        <button className="arrow-h left-arrow" onClick={scrollLeft2}>
          <img
            src="icons/arrow-left.png"
            alt="flecha izquierda"
            className="arrow-img"
          />
        </button>
        <div className="scroll-type" ref={scrollRef2}>
          {carros.map((carro) =>
            isDestacado ? (
              <CarOverviewDestacado key={carro._id} carro={carro} />
            ) : isofferta ? (
              <CarOverviewOferta key={carro._id} carro={carro} />
            ) : (
              <CarOverview key={carro._id} carro={carro} />
            )
          )}
        </div>
        <button className="arrow-h right-arrow" onClick={scrollRight2}>
          <img
            src="icons/arrow-right.png"
            alt="flecha derecha"
            className="arrow-img"
          />
        </button>
      </div>
      <div className="ads-logos" id="to-logo">
        <div className="container">
          <div className="ads-container-1">
            <div className="ad-15">
              <VideoContainer />
            </div>
            <div className="ad-2"></div>
          </div>
          <LogoGallery />
          <div className="ads-container">
            <div className="ad-1">
              <VideoContainer />
            </div>
            <div className="ad-2">
              <ImageCarousel images={banners}></ImageCarousel>
            </div>
            <div className="ad-3">
              <ImageCarousel images={banners}></ImageCarousel>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
};

export default Home;
