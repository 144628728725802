import React, { useState, useEffect, useRef } from "react";
import Notification from "../Notification";
import Navbar from "../Navbar";
import Foooter from "../Foooter";
import FooterMovil from "../FooterMovil";
import { useAuth } from "../../context/AuthContext.js";
import { useNavigate } from "react-router-dom";
import "../../style/Notifications.css";
import axios from 'axios';

function Notifications() {
  const [notis, setNotis] = useState([]);
  const [loading, setLoading] = useState(true);
  const { authToken, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [selectednoti, setselectednoti] = useState({
    Mensajero: {
      nombre: "HONDUAUTOS"
    },
    Receptor: {
      nombre: "USUARIO"
    },
    body: "NOTIFICACION"
  });

  const getuser = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKENDIP + "usuario/getbyToken",
        { headers: { Authorization: "bearer " + authToken } }
      );
      setUsername(response.data.nombre);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
    }
  };

  const deleteNotification = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_BACKENDIP + "notificaciones/borrar/" + id, {
        headers: { Authorization: "bearer " + authToken }
      });
      setNotis(notis.filter(noti => noti._id !== id));
      if (selectednoti._id === id && notis.length > 0) {
        setselectednoti(notis[0]);
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getnotis = async () => {
    const res = await axios.get(process.env.REACT_APP_BACKENDIP + "notificaciones/misnotis", { headers: { Authorization: "bearer " + authToken } });
    const notidata = res.data;
    setNotis(notidata);
    if (notidata.length !== 0) {
      setselectednoti(res.data[0]);
    }
  };

  useEffect(() => {
    const fetchAllCarros = async () => {
      setLoading(true);
      if (authToken !== null) {
        await getuser();
        await getnotis();
      } else {
        navigate("/");
      }

      await setLoading(false);
    };
    fetchAllCarros();
  }, []);

  const setnewSelectedNoti = (noti) => {
    setselectednoti(noti);
  };

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <Navbar />
      <div className="containers">
        <div className="main-container-user">
          <div className="column-user">
            <p className="user-name">Hola, {username}</p>
            <div className="user-menu">
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/mycars" className="element-text">
                  Mis autos
                </a>
              </div>
              <div className="element">
                <img src="icons/campana.png" alt="" className="element-img" />
                <a href="/notifications" className="element-text">
                  Notificaciones
                </a>
              </div>
              <div className="element">
                <img src="icons/car.png" alt="" className="element-img" />
                <a href="/allcars" className="element-text">
                  Mis compras
                </a>
              </div>
              <div className="element-end" onClick={handleLogout}>
                <img src="icons/salir.png" alt="" className="element-img" />
                <p className="element-text">Salir</p>
              </div>
            </div>
          </div>
          <div className="column-info">
            <div ref={dropDownRef} className="whole-drop-u">
              <button
                onClick={toogleDropdown}
                id="drop-movil"
                className={isOpen ? "click" : ""}
              >
                <p className="d-text">MENU USUARIO</p>
                {isOpen ? (
                  <img
                    src="/icons/arrow-up-dark.png"
                    id="arrows"
                    alt="arrow-up"
                  />
                ) : (
                  <img
                    src="/icons/arrow-down-dark.png"
                    id="arrows"
                    alt="arrow-down"
                  />
                )}
              </button>
              {isOpen && (
                <div className="drop">
                  <div className="user-menu-drop">
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img" />
                      <a href="/mycars" className="element-text">
                        Mis autos
                      </a>
                    </div>
                    <div className="element-d">
                      <img
                        src="icons/campana.png"
                        alt=""
                        className="element-img"
                      />
                      <a href="/notifications" className="element-text">
                        Notificaciones
                      </a>
                    </div>
                    <div className="element-d">
                      <img src="icons/car.png" alt="" className="element-img" />
                      <a href="/allcars" className="element-text">
                        Mis compras
                      </a>
                    </div>
                    <div className="element-end-d" onClick={handleLogout}>
                      <img
                        src="icons/salir.png"
                        alt=""
                        className="element-img-d"
                      />
                      <p className="element-text-d">Salir</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="main-user-title">Notificaciones</p>
            {notis.length > 0 ? (
              <div className="notifications-grid">
                <div className="column-overview">
                  <div
                    className="overview-container"
                    style={{
                      overflow: "auto",
                      maxHeight: "450px",
                      alignItems: "flex-start",
                    }}
                  >
                    {notis.map((noti) => (
                      <div className="overview-item-n" key={noti._id} onClick={() => setnewSelectedNoti(noti)}>
                        <Notification noti={noti} onDelete={deleteNotification} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="column-content">
                  <p className="from">De: {selectednoti.Mensajero.nombre}</p>
                  <p className="to">Para: {selectednoti.Receptor.nombre}</p>
                  <p className="notification-content">
                    {selectednoti.body}
                  </p>
                </div>
              </div>
            ) : (
              <p className="no-notifications">NO TIENES NINGUNA NOTIFICACIÓN</p>
            )}
          </div>
        </div>
      </div>

      <div className="footer">
        <Foooter />
      </div>
      <div className="footer-movil">
        <FooterMovil />
      </div>
    </>
  );
}

export default Notifications;
