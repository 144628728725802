import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../style/LogoGalley.css';

const Logos = () => {
  const [logos, setLogos] = useState([]);

  const isFetchingMarcas = useRef(false);
  const isMountedMarcas = useRef(true);

  useEffect(() => {
    getMarcas()
    return () => {
      isMountedMarcas.current = false;
    };
  }, []);

  const getMarcas = async () => {
    if (isFetchingMarcas.current) {
      // The function is already running; exit to prevent multiple executions
      return;
    }
    isFetchingMarcas.current = true; // Set the flag to indicate the function is running
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKENDIP}marcasymodelos/marcaCantidad`
      );
        console.log("pene")
        setLogos(res.data);
    } catch (error) {
      if (isMountedMarcas.current) {
        console.error('Error fetching marcas:', error);
      }
    } finally {
      isFetchingMarcas.current = false; // Reset the flag when the function completes
    }
  };

  const largeLogos = ['Dodge', 'Mercedes Benz', 'Hyundai', 'GMC'];

  const getLogoClass = (nombre) => {
    return largeLogos.includes(nombre) ? 'large-logo-container' : 'square-logo-container';
  };

  const distributeLogos = (logos) => {
    const rows = [];
    let currentRow = [];
    let rowCapacity = 4;

    logos.forEach((logo) => {
      const logoClass = getLogoClass(logo.Nombre);
      const logoSize = logoClass === 'large-logo-container' ? 2 : 1;

      if (rowCapacity - logoSize >= 0) {
        currentRow.push(logo);
        rowCapacity -= logoSize;
      } else {
        rows.push(currentRow);
        currentRow = [logo];
        rowCapacity = 4 - logoSize;
      }
    });

    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    return rows;
  };

  const logoRows = distributeLogos(logos);

  return (
    <div className="logos-container">
      {logoRows.map((row, rowIndex) => (
        <div key={rowIndex} className="logo-row">
          {row.map((logo, index) => (
            <a key={index} href={`/stock?marca=${logo.Nombre}`} className="logo-reference">
              <div className={getLogoClass(logo.Nombre)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={logo.Imagen} alt={logo.Nombre} className={getLogoClass(logo.Nombre) === 'large-logo-container' ? 'large-logo' : 'square-logo'} />
                <p className='logo-count'>({logo.cantidad})</p>
              </div>
            </a>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Logos;
