import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { notification } from 'antd';
import { useAuth } from "../context/AuthContext";
import AbonoUpload from "./AbonoUpload";
import axios from "axios";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &:hover {
    background: #0056b3;
  }
`;

const DeleteButton = styled.button`
  background: #ff0000;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background: #cc0000;
  }
`;

const PDFModal = ({ show, onClose, onConfirm, formData, setFormData }) => {
  const [images, setImages] = useState([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const { authToken } = useAuth();

  const handleUploadSuccess = (file) => {
    setImages((prevImages) => [...prevImages, file]);
    setFormData({ ...formData, documentos: [...formData.documentos, file] });
  };

  const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    setFormData({ ...formData, documentos: newImages });
  };

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const comprar = async () => {
    if (images.length === 0) {
      notification.error({
        message: 'Error',
        description: 'Por favor, sube al menos un comprobante.',
        placement: 'top',
        style: {
          width: 400,
          fontSize: '16px',
          padding: '20px',
        },
      });
      
      return;
    }
    if (!termsChecked) {
      notification.error({
        message: 'Error',
        description: 'Debes aceptar los términos y condiciones.',
        placement: 'top',
        style: {
          width: 400,
          fontSize: '16px',
          padding: '20px',
        },
      });
      
      return;
    }
    onConfirm();
  };

  useEffect(() => {
    if (show) {
      setImages(formData.pdfs || []);
      setTermsChecked(false);
    }
  }, [show, formData.pdfs]);

  if (!show) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>X</CloseButton>
        <h2>COMPROBANTES</h2>
        <p>Sube todos tus comprobantes para la solicitud de credito</p>
        <div className="widget-pdf">
          <AbonoUpload onUploadSuccess={handleUploadSuccess} />
        </div>
        {images.map((file, index) => (
          <div key={index} className="pdf-container-cloudinary">
            <a
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-show"
            >
              <img src="icons/pdf.png" alt="" className="pdf-img" />
              {file.name}
            </a>
            <DeleteButton onClick={() => handleDeleteImage(index)}>
              Eliminar
            </DeleteButton>
          </div>
        ))}
        <ConfirmButton onClick={comprar}>
          Confirmar
        </ConfirmButton>
        <div className='terms-modal'>
          <input type="checkbox" id='checkbox-terms' checked={termsChecked} onChange={handleTermsChange}/> 
          <p>Acepto que la información proporcionada en esta solicitud es verdadera y actual. 
          Estoy consciente que mi Informacion será validada en el buró de crédito por 
          Honduautos, para lo cual otorgo autorización. Acepto recibir información por 
          cualquier medio de comunicación que la empresa lo estime.</p>
        </div>
      </ModalContainer>
    </Overlay>
  );
};

export default PDFModal;