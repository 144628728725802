import React, { useEffect } from 'react';
import "../style/UploadWidget.css";

const AbonoUpload = ({ onUploadSuccess }) => {
  useEffect(() => {
    window.cloudinary.setCloudName('your-cloud-name'); // Reemplaza 'your-cloud-name' con tu Cloud Name de Cloudinary
  }, []);

  const handleUpload = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: 'dlpsbcfs4', // Reemplaza 'your-cloud-name' con tu Cloud Name de Cloudinary
        uploadPreset: 'i2cyl86j', // Reemplaza 'your-upload-preset' con tu Upload Preset de Cloudinary
        sources: ['local', 'url', 'camera'],
        multiple: true,
        resourceType: 'raw', // Permite subir archivos de cualquier tipo, incluyendo PDFs
        allowedFormats: ['pdf'], // Limita la carga solo a archivos PDF
      },
      (error, result) => {
        if (result.event === 'success') {
          onUploadSuccess({ url: result.info.secure_url, name: result.info.original_filename + '.pdf' }); // Devuelve la URL segura del PDF subido y el nombre del archivo
        }
      }
    );
  };

  return (
    <button type='button' className='widget-upload-btn' onClick={handleUpload}>
      Subir PDF
    </button>
  );
};

export default AbonoUpload;