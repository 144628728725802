import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import "../style/DropdownMovil.css";

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const { authToken } = useAuth();
  const [Isadmin, SetIsadmin] = useState(false);

  useEffect(() => {
    if (authToken !== null) {
      const req = axios
        .get(process.env.REACT_APP_BACKENDIP + "usuario/getbyToken", {
          headers: {
            Authorization: "bearer " + authToken,
          },
        })
        .then((res) => {
          const roles = res.data.role;
          const containsSys = roles.includes("sysadmin");
          SetIsadmin(containsSys);
        });
    }
  }, [authToken]); // Add authToken as a dependency to re-run when it changes

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toogleDropdown = () => setIsOpen(!isOpen);

  return (
    <>
      <div
        ref={dropDownRef}
        style={{ position: "relative" }}
        className="whole-drop"
      >
        <button
          onClick={toogleDropdown}
          id="drop-menu-movil"
          className={isOpen ? "clicked" : ""}
        >
          <p className="drop-text">Más</p>
          {isOpen ? (
            <img src="/icons/arrow-up-dark.png" id="arro" />
          ) : (
            <img src="/icons/arrow-down-dark.png" id="arro" />
          )}
        </button>
        {isOpen && (
          <div
            style={{ position: "absolute", top: "100%", left: 0, zIndex: 0 }}
            className="menu-drop-m"
          >
            {authToken ? (
              <>
                <div className="list-ref-m">
                  <a href="/mycars" className="a-color-m">
                    Perfil
                  </a>
                </div>
                <div className="line-m">
                  <hr />
                </div>
                {Isadmin && (
                  <>
                    <div className="list-ref-m" id="second">
                      <a href="/allcarsa" className="a-color-m">
                        <img src="icons/store.png" alt="" className="icon-nav" />
                        Administrar
                      </a>
                    </div>
                    <div className="line-m">
                      <hr />
                    </div>
                  </>
                )}
                <div className="list-ref-m" id="second">
                  <a href="/politicas" className="a-color-m">
                    <center>Políticas y garantías</center>
                  </a>
                </div>
                <div className="line-m">
                  <hr />
                </div>
                <div className="list-ref-m" id="second">
                  <a href="/sell" className="a-color-m">
                    <center>Vende tu auto</center>
                  </a>
                </div>
                <div className="line-m">
                  <hr />
                </div>
                <div className="list-ref-m" id="second">
                  <a href="/contact" className="a-color-m">
                    <center>Contáctenos</center>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="list-ref-m">
                  <a href="/politicas" className="a-color-m">
                    <center>Políticas y garantías</center>
                  </a>
                </div>
                <div className="line-m">
                  <hr />
                </div>
                <div className="list-ref-m" id="second">
                  <a href="/sell" className="a-color-m">
                    <center>Vende tu auto</center>
                  </a>
                </div>
                <div className="line-m">
                  <hr />
                </div>
                <div className="list-ref-m" id="second">
                  <a href="/login" className="a-color-m">
                    <center>Inicia sesión</center>
                  </a>
                </div>
                <div className="line-m">
                  <hr />
                </div>
                <div className="list-ref-m" id="second">
                  <a href="/contact" className="a-color-m">
                    <center>Contáctenos</center>
                  </a>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Dropdown;
