import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CarOverviewWithSaldo from './CarOverviewWithSaldo';
import AbonoModal from './AbonoCar';
import '../style/CarModal.css';

const CarModal = ({ userId, showModal, onClose }) => {
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null); // Estado para el carro seleccionado

  useEffect(() => {
    if (showModal) {
      const fetchCars = async () => {
        try {
          const response = await axios.post(process.env.REACT_APP_BACKENDIP + "ventas/comprasbyuser", { usuarioID: userId });
          setCars(response.data);
        } catch (error) {
          console.error("Error fetching cars:", error);
        }
      };
      fetchCars();
    }
  }, [showModal, userId]);

  const handleCarClick = (car) => {
    setSelectedCar(car);
  };

  const handleCloseAbonoModal = () => {
    setSelectedCar(null);
    onClose();
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Carros Comprados</h2>
        <div className="cars-grid">
          {cars.map(car => (
            <CarOverviewWithSaldo key={car._id} carro={car.Carro} saldo={car.Saldo} onClick={() => handleCarClick(car)} />
          ))}
        </div>
      </div>
      {selectedCar && (
        <AbonoModal 
          show={!!selectedCar} 
          carro={selectedCar.Carro} 
          onClose={handleCloseAbonoModal} 
        />
      )}
    </div>
  );
};

export default CarModal;
